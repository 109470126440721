import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Collapse,
  Box,
  Paper,
  Typography,
  TablePagination,
  Button,
} from "@mui/material";
import { MenuItem, List, ListItemText } from '@mui/material';
import Select from 'react-select'
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import Popover from '@mui/material/Popover';
import { styled } from "@mui/material/styles";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import CustomPagination from "./CustomPagination.web";
import AddCartConfirmationPopUp from "./popups/addToCartConfirmation.web";

interface TableColumn {
  id: string;
  label: string;
}

interface TableProps {
  tabName: string;
  subTabName:string;
  data: Array<any>;
  selectedIds:any[];
  onCheckboxChange:any;
  getSingleProductId:any
}
function formatStatus(status: string): string {
  if (status === "in_draft") {
    return "Draft";
  }
  return status?.charAt(0).toUpperCase() + status?.slice(1);
}

const columnConfig: Record<string, TableColumn[]> = {
  "Buying Requests": [
    { id: "modelNumber", label: "Model Number" },
    { id: "image", label: "Image" },
    { id: "listedDate", label: "Listed Date" },
    { id: "category", label: "Category" },
    { id: "brand", label: "Brand" },
    { id: "productName", label: "Product Name" },
    { id: "listedPrice", label: "Listed Price" },
    { id: "use", label: "Use" },
    { id: "requests", label: "Requests" },
  ],
  Products: [
    { id: "modelNumber", label: "Model No." },
    { id: "image", label: "Image" },
    { id: "listedDate", label: "Listed Date" },
    { id: "category", label: "Category" },
    { id: "brand", label: "Brand" },
    { id: "productName", label: "Product Name" },
    { id: "listedPrice", label: "Listed Price" },
    { id: "sold", label: "Sold" },
    { id: "offers", label: "Offers" },
    { id: "orders", label: "Orders" },
    { id: "status", label: "Status" },
  ],
  Orders: [
    { id: "orderId", label: "Order ID" },
    { id: "date", label: "Date" },
    { id: "productImage", label: "Product Image" },
    { id: "productName", label: "Product Name" },
    { id: "customer", label: "Customer" },
    { id: "location", label: "Location" },
    { id: "listedPrice", label: "Listed Price" },
    { id: "actions", label: "Actions" },
  ],
  Offers: [
    { id: "orderId", label: "Order ID" },
    { id: "date", label: "Date" },
    { id: "productImage", label: "Product Image" },
    { id: "productName", label: "Product Name" },
    { id: "customer", label: "Customer" },
    { id: "location", label: "Location" },
    { id: "listedPrice", label: "Listed Price" },
    { id: "offerPrice", label: "Offer Price" },
    { id: "actions", label: "Actions" },
  ],
  "Wish Requests": [
    { id: "requestId", label: "Request ID" },
    { id: "productImage", label: "Product Image" },
    { id: "productName", label: "Product Name" },
    { id: "category", label: "Category" },
    { id: "requestDate", label: "Request Date" },
    { id: "assignDate", label: "Assign Date" },
    { id: "customer", label: "Customer" },
    { id: "location", label: "Location" },
    { id: "actions", label: "Actions" },
  ],
};
const DashboardTable: React.FC<TableProps> = ({ getSingleProductId,tabName,subTabName, data,selectedIds,onCheckboxChange }) => {
  const [expandedRow, setExpandedRow] = useState<number | null>(null);
  const [rowsPerPage, setRowsPerPage] = useState(2);
  const[ProductId,setProductId]=useState<any>(null)
  const [page, setPage] = useState(0);
  const [acceptOrderModal,setacceptOrderModal]=useState(false)
  const [rejectModal,setrejectModal]=useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const handleClose = () => {
  
    setAnchorEl(null);
  };

  const columns = columnConfig[tabName] || [];

  const handleClickProductDotsClick = (event: React.MouseEvent<HTMLButtonElement>,id:any) => {
    setAnchorEl(event.currentTarget);
    setProductId(id)
  };
  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
  };
 const handleAcceptClick=(id:any)=>{
    setacceptOrderModal(true)
  }
  const handleCloseAcceptClick=()=>{
    setacceptOrderModal(false)
  }
  const handleRejectClick=(id:any)=>{
    setrejectModal(true)
  }
  const handleCloseRejectClick=()=>{
    setrejectModal(false)
  }
  const buyingRequestColumns=[
    {id:"id",label:"ID"},
    {id:"date",label:"Date"},
    {id:"customer",label:"Customer"},
    {id:"location",label:"Location"},
    {id:"listed_price",label:"Listed Price"},
    {id:"offer_price",label:"Offer Price"},
  ]

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRowClick = (index: number) => {
    setExpandedRow(expandedRow === index ? null : index);
  };

  const paginatedData = data.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );
  const CustomCheckbox = styled("input")(({ theme }) => ({
    width: "16px",
    height: "16px",
    appearance: "none",
    outline: "none",
    cursor: "pointer",
    backgroundColor: "transparent",
    border: "1.54px solid rgba(83, 80, 63, 1)",
    position: "relative",
    borderRadius:"2px",
  
    "&:checked": {
      backgroundColor: "transparent",
    },
  
    "&:checked::after": {
      content: '"✔"',
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      color: "rgba(83, 80, 63, 1)", // Checkmark color
      fontSize: "12px",
    },
  }));
  
  const ReactSelect = styled(Select)({
    width:"138px",
    background:"rgba(237, 230, 220, 1)",
    borderRadius: "8px",
    outline: 'none',
    height:'30px',
    scrollbarGutter:'stable',
   
    '& .custom-select__menu-list': {
      scrollbarWidth: '5px',
      scrollbarColor: '#53503F transparent',
      direction: 'ltr',
      borderRadius: '8px',
      overflow: 'visible',
    },
    '& .custom-select__menu-list::-webkit-scrollbar': {
      scrollbarWidth: '5px',
      scrollbarColor: '#53503F transparent',
      direction: 'ltr',
    },
    '& .custom-select__menu-list::-webkit-scrollbar-thumb': {
      scrollbarColor: '#53503F transparent',
      scrollbarWidth: '5px',
      direction: 'ltr',
      borderRadius: '8px',
    },
    '& .custom-select__menu-list:hover': {
      scrollbarWidth: '5px',
      direction: 'ltr',
      scrollbarColor: '#53503F transparent',
      borderRadius:'8px'
    },
    "& .custom-select__menu-list span": {
      display: 'inline-block',
      paddingLeft: '8px',
    },
  
    "& .css-1uccc91-singleValue": {
      color: "#53503F",
      fontWeight: 600,
      fontSize: '16px',
      fontFamily: "'Montserrat', sans-serif",
    },
    "& .css-1okebmr-indicatorSeparator": {
      display: 'none'
    },
    "& .css-yk16xz-control": {
      border: 'none',
      borderColor: 'none',
      height:'100%',
    },
    "& .css-yk16xz-control:hover": {
      border: 'none',
      borderColor: 'none'
    },
    "& .css-g1d714-ValueContainer": {
      height: '30px',
      border: 'none'
    },
    "& .css-1pahdxg-control": {
      borderColor: 'transparent !important',
      outline: 'none !important',
      boxShadow: 'none !important',
      border: 'none !important',
      height:'30px',
      minHeight:'30px'
    },
    "& .custom-select__control": {
      minHeight: '30px',
      height: '30px',
    },
    "& .custom-select__value-container": {
      height: '30px',
    },
    "& .css-1wa3eu0-placeholder": {
      color: '#BABABA',
      fontWeight: 400,
      opacity: 1,
      fontFamily: "'Montserrat', sans-serif",
      fontSize: '16px',
    },
    "& .css-1hb7zxy-IndicatorsContainer": {
      width: '24px',
      display: 'flex',
      justifyContent: "center"
    },
    "& .css-tlfecz-indicatorContainer": {
      padding: '0'
    },
    "&.react-select__control":{
      height:"30px"
    },
    "& .css-6q0nyr-Svg": {
      color: '#53503F',
      cursor:'pointer'
    },
    "& .css-26l3qy-menu": {
      fontWeight: 400,
      color: '#3C3E49',
      fontFamily: "'Montserrat', sans-serif",
      fontSize: '12px',
      zIndex: 1000
    },
    "& .css-9gakcf-option": {
      backgroundColor: '#53503F',
      fontWeight: 600,
      color: '#FFFFFF',
      fontFamily: "'Montserrat', sans-serif",
      fontSize: '12px',
    },
    "& span": {
      display: 'inline-block',
    },
  
    "@media (max-width: 400px)": {
      minWidth: 'unset'
    }
  });
  const styles = {
    nestedtablecellsText: {
      fontFamily: "'Montserrat', sans-serif",
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "22px",
      textAlign: "left",
      color:"rgba(83, 80, 63, 1)"
    },
    sellwithus: {
      fontSize: "16px",
      fontFamily:
        "'Big-Caslon-Medium','Adobe Caslon Pro','LibreCaslonText-Regular',serif !important",
      height: "30px",
      padding: "10px 16px",
      gap: "4px",
      borderRadius: "4px",
      border: "1px solid black",
      cursor: "pointer",
    },
  };
  const ResponsiveButton = styled(Button)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    fontFamily:
      "'Big-Caslon-Medium','Adobe Caslon Pro','LibreCaslonText-Regular',serif !important",
    // padding: "10px 20px",
    borderRadius: "8px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      padding: "8px 16px",
    },
    ".MuiButtonBase-root": {
      fontFamily:
        "'Big-Caslon-Medium','Adobe Caslon Pro','LibreCaslonText-Regular',serif !important",
    },
  }));
  return (
    <div style={{ width: "100%", marginTop: "30px" }}>
      <TableContainer
        component={Paper}
        sx={{
          "&.MuiTableContainer-root": {
            margin: 0,
            padding: 0,
            width: "100%",
            overflowX: "hidden",
            borderRadius: "8px",
          },
        }}
      >
        <Table sx={{ tableLayout: "auto",fontFamily: "'Montserrat', sans-serif",overflowX:"hidden" }}>
          <TableHead sx={{ background: "rgba(255, 255, 255, 1)" }}>
            <TableRow style={{ height: "60px" }}>
              {tabName=="Products"&&(
                <TableCell></TableCell>
              )}
                  {columns.map((column) => (
                   ( subTabName === "Accepted Orders" && column.id=="actions") ? (
                      <TableCell key={column.id}></TableCell>
                    ) : (
                      <TableCell
                        style={{
                          fontFamily: "Montserrat",
                          fontSize: "14px",
                          fontWeight: 600,
                          lineHeight: "22px",
                          textAlign: "left",
                          color: "rgba(83, 80, 63, 1)",
                        }}
                        key={column.id}
                      >
                        {column.label}
                      </TableCell>
                    )
                  ))}

              {(tabName === "Buying Requests"||subTabName=="Accepted Requests"||subTabName=="Rejected Requests"||subTabName=="Pending Requests"||subTabName=="Pending Offers"|| subTabName=="Pending Orders" || subTabName==="Accepted Orders") && <TableCell />}
              {(tabName === "Buying Requests"||tabName=="Products") && <TableCell />}
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedData.map((row, index) => (
              <React.Fragment key={index}>
                <TableRow
                  sx={{
                    background:
                      index % 2 == 0
                        ? "rgba(251, 250, 248, 1)"
                        : "rgba(255, 255, 255, 1)",
                  }}
                >
                  {tabName=="Products"&&(
                    <TableCell>
                      <CustomCheckbox
                  type="checkbox"
                  checked={selectedIds?.includes(row.id)} 
                  onChange={() => onCheckboxChange(row.id)}
                />
                    </TableCell>
                  )}
                  {columns.map((column) => (
                    <TableCell style={{ textAlign: "left",fontSize:"16px",lineHeight:"22px",fontWeight:400 }} key={column.id}>
                      {column.id === "image" ? (
                        <img
                          src={row[column.id]}
                          alt={row.productName}
                          style={{ width: "60px", height: "60px" }}
                        />
                      ):(column.id=="status" && tabName=="Products")?(<span 
                        style={{
                          background:"rgba(239, 238, 233, 1)",
                          borderRadius:"16px",
                          padding:"3px 8px",
                          color:"rgba(83, 80, 63, 1)"

                      }}>
                      {formatStatus(row[column.id] )}
                      </span>) : (column.id === "actions" && (subTabName=="Pending Requests"||subTabName=="Pending Orders"|| subTabName==="Pending Offers") )? (
                        <div style={{ display: "flex", gap: "15px" }}>
                      <ResponsiveButton
                        style={{
                          ...styles.sellwithus,
                          backgroundColor: "rgba(83, 80, 63, 1)",
                          fontWeight: 600,
                          color: "rgba(237, 230, 220, 1)",
                          fontSize: "12px",
                        }}
                        onClick={()=>handleAcceptClick(row.id)}
                      >
                      <CheckOutlinedIcon style={{fontSize:"14px",color:"rgba(239, 238, 233, 1)"}}/>  Accept
                      </ResponsiveButton>
                      <ResponsiveButton
                        style={{
                          ...styles.sellwithus,
                          fontSize: "12px",
                          backgroundColor: "#EDE6DC",
                          fontWeight: 600,
                          color: "#53503F",
                        }}
                        onClick={()=>handleRejectClick(row.id)}
                      >
                        <CloseOutlinedIcon style={{fontSize:"14px",color:"rgba(83, 80, 63, 1)"}}/>
                        Reject
                      </ResponsiveButton>
                    </div>
                      ): ((subTabName=="Rejected Orders"||subTabName=="Rejected Offers"|| subTabName=="Accepted Offers"||subTabName=="Rejcted Offers") && column.id === "actions" )?
                      <VisibilityOutlinedIcon
                        style={{
                          cursor: "pointer",
                          color: "rgba(83, 80, 63, 1)",
                        }}
                      />
                    :(subTabName=="Accepted Requests" && column.id === "actions")? <ReactSelect 
                    data-test-id="daySelectID"
                     value={{value:"Found",label:"Found"}}
                     onChange={()=>{}}
                    styles={{
                      singleValue: (provided: any) => ({
                        ...provided,
                        display: 'flex',
                        alignItems: 'center',
                        height: '100%',
                        width:"110px"
                      }),
                      control: (provided: any) => ({
                        ...provided,
                        display: 'flex',
                        alignItems: 'center',
                        height: '30px',
                        minHeight:"30px",
                        background:"rgba(237, 230, 220, 1)",
                      }),
                      placeholder: (provided: any) => ({
                        ...provided,
                        display: 'flex',
                        alignItems: 'center',
                        height: '100%',
                      }),
                    }}
                      classNamePrefix="react-select" 
                      components={{ IndicatorSeparator:null }} 
                      options={[{value:"Found",label:"Found"},{value:"Assigned",label:"Assigned"}]} 
                    />: (
                        row[column.id]
                      )}
                    </TableCell>
                  ))}
                  {tabName=="Products" &&(<TableCell align="left">
                        <MoreVertOutlinedIcon onClick={(e:any)=>handleClickProductDotsClick(e,row.id)} style={{cursor:"pointer",color:"rgba(83, 80, 63, 1)"}}/>
                  </TableCell>)}
                  {tabName === "Buying Requests" && (
                    <TableCell>
                      <IconButton
                        size="small"
                        onClick={() => handleRowClick(index)}
                      >
                        {expandedRow === index ? (
                          <KeyboardArrowUp
                            style={{
                              borderRadius: "50%",
                              background: "rgba(239, 238, 233, 1)",
                              backgroundColor: "rgba(239, 238, 233, 1)",
                            }}
                          />
                        ) : (
                          <KeyboardArrowDown
                            style={{
                              borderRadius: "50%",
                              backgroundColor: "rgba(239, 238, 233, 1)",
                            }}
                          />
                        )}
                      </IconButton>
                    </TableCell>
                  )}
                  {(tabName === "Buying Requests"||subTabName=="Accepted Requests"|| (subTabName=="Rejected Requests"||subTabName=="Pending Requests"||subTabName=="Pending Orders"|| subTabName=="Accepted Orders"||subTabName=="Pending Offers")) && (
                    <TableCell align="left">
                      <VisibilityOutlinedIcon
                        style={{
                          cursor: "pointer",
                          color: "rgba(83, 80, 63, 1)",
                        }}
                      />
                    </TableCell>
                  )}
                </TableRow>
                {tabName === "Buying Requests" && expandedRow === index && (
  <TableRow sx={{ height: "20px" }}>
    <TableCell
      sx={{ padding: "0px" }}
      colSpan={columns.length + (tabName === "Buying Requests" ? 2 : 0)}
    >
      <Collapse
        in={expandedRow === index}
        timeout="auto"
        unmountOnExit
      >
        <Box sx={{ justifyContent: "center",fontFamily: "'Montserrat', sans-serif", }}>
          <Table size="medium" sx={{ width: "100%",fontFamily: "'Montserrat', sans-serif", }}>
            <TableHead style={{fontFamily: "'Montserrat', sans-serif",}}>
              <TableRow>
                <TableCell></TableCell>
                <TableCell></TableCell>
                {buyingRequestColumns?.map((ele) => (
                  <TableCell
                    key={ele.id}
                    style={{...styles.nestedtablecellsText as React.CSSProperties,fontWeight:600,fontSize:"14px",lineHeight:"22px",color:"rgba(83, 80, 63, 1)"}}
                  >
                    {ele.label}
                  </TableCell>
                ))}
                <TableCell style={{fontWeight:600,fontSize:"14px",lineHeight:"22px",color:"rgba(83, 80, 63, 1)"}}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {row.requestList?.map((request:any, idx:any) => (
                <TableRow key={request.id} sx={{ background: "rgba(239, 238, 233, 1)"}}>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell style={styles.nestedtablecellsText as React.CSSProperties}>
                    {request.id}
                  </TableCell>
                  <TableCell style={styles.nestedtablecellsText as React.CSSProperties}>
                    {request.date}
                  </TableCell>
                  <TableCell style={styles.nestedtablecellsText as React.CSSProperties}>
                    {request.customer}
                  </TableCell>
                  <TableCell style={styles.nestedtablecellsText as React.CSSProperties}>
                    {request.location}
                  </TableCell>
                  <TableCell style={{...styles.nestedtablecellsText as React.CSSProperties,fontWeight:600}}>
                    {request.listed_price}
                  </TableCell>
                  <TableCell style={{...styles.nestedtablecellsText as React.CSSProperties,fontWeight:600}}>
                    {request.offer_price}
                  </TableCell>
                  <TableCell style={styles.nestedtablecellsText as React.CSSProperties}>
                    <div style={{ display: "flex", gap: "15px" }}>
                      <ResponsiveButton
                        style={{
                          ...styles.sellwithus,
                          backgroundColor: "rgba(83, 80, 63, 1)",
                          fontWeight: 600,
                          color: "rgba(237, 230, 220, 1)",
                          fontSize: "12px",
                        }}
                        onClick={()=>handleAcceptClick(request.id)}
                      >
                      <CheckOutlinedIcon style={{fontSize:"14px",color:"rgba(239, 238, 233, 1)"}}/>  Accept
                      </ResponsiveButton>
                      <ResponsiveButton
                        style={{
                          ...styles.sellwithus,
                          fontSize: "12px",
                          backgroundColor: "#EDE6DC",
                          fontWeight: 600,
                          color: "#53503F",
                        }}
                        onClick={()=>handleRejectClick(request.id)}
                      >
                        <CloseOutlinedIcon style={{fontSize:"14px",color:"rgba(83, 80, 63, 1)"}}/>
                        Reject
                      </ResponsiveButton>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell sx={{background: "rgba(239, 238, 233, 1)" }} align="center" colSpan={9}>
                 <span style={{color:"rgba(83, 80, 63, 1)",cursor:"pointer",fontFamily: "'Montserrat', sans-serif",fontWeight:600,fontSize:"16",lineHeight:"22px"}}>
                  View More {">"}
                 </span>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Box>
      </Collapse>
    </TableCell>
  </TableRow>
)}

              </React.Fragment>
            ))}
          </TableBody>
        </Table>
        {/* Pagination Controls */}
        <CustomPagination
          data={data}
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </TableContainer>
      {acceptOrderModal && <AddCartConfirmationPopUp question={"Are you sure you want to accept the Order?"} handleYes={()=>{}} handleClose={handleCloseAcceptClick} open={acceptOrderModal} />}
      {rejectModal && <AddCartConfirmationPopUp question={"Are you sure you want to reject the Order?"} handleYes={()=>{}} handleClose={handleCloseRejectClick} open={rejectModal} />}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        // transformOrigin={{
        //   vertical: 'top',
        //   horizontal: 'left',
        // }}
        disableRestoreFocus
        sx={{
          ".MuiPopover-root": {
            boxSizing: "border-box",
           
            overflowY: "hidden",
          },
          ".MuiPopover-paper": {
            borderRadius: "8px",
            boxSizing: "border-box",
            overflowY: "hidden",
            marginTop: "17px",
            transform: "translateX(-50px) !important",
          },
        }}
      >
           <List>
          <MenuItem>
            <ListItemText onClick={handleClose} primary="View Details" />
          </MenuItem>
          <MenuItem >
            <ListItemText onClick={handleClose} primary="Edit Product" />
          </MenuItem>
          <MenuItem >
            <ListItemText onClick={()=>{
              getSingleProductId(ProductId,true);
              handleClose()
            }} primary="Hide Product from Listing" />
          </MenuItem>
          <MenuItem>
          <ListItemText onClick={()=>{
              getSingleProductId(ProductId,false);
              handleClose()
            }} primary="Delete Product" />
          </MenuItem>
        </List>
      </Popover>
    </div>
  );
};

export default DashboardTable;
