import React from "react";
// Customizable Area Start

import UserProfileBuyerController, {
  Props,
} from "./UserProfileBuyerController.web";
import {
    Box,
    Typography,
  } from "@mui/material";
// Customizable Area End

export default class UserProfileShopper extends UserProfileBuyerController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
       <Box>
        <Typography>UserProfileShopper</Typography>
       </Box>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
// Customizable Area End