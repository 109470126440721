import React from 'react';
import { Box, Typography, Select, MenuItem, IconButton, FormControl, SelectChangeEvent, Button } from '@mui/material';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';

interface CustomPaginationProps {
  data: Array<any>;
  rowsPerPage: number;
  page: number;
  handleChangePage: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void;
  handleChangeRowsPerPage:any;
}

const CustomPagination: React.FC<CustomPaginationProps> = ({
  data,
  rowsPerPage,
  page,
  handleChangePage,
  handleChangeRowsPerPage,
}) => {
  const totalPages = Math.ceil(data.length / rowsPerPage);
  
  // Generate page numbers (1, 2, 3, ...)
  const pageNumbers = [];
  for (let i = 0; i < totalPages; i++) {
    pageNumbers.push(i);
  }

  return (
    <Box sx={{ display: 'flex', height:"60px",justifyContent: 'space-between', alignItems: 'center', width: '100%',boxSizing:"border-box",padding:"0px 30px" }}>
      {/* Left Section: Show per page */}
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant="body2" sx={{ marginRight: 1 }}>
          Show
        </Typography>
        <FormControl size="small" sx={{ minWidth: 80 }}>
                    <Select
            value={rowsPerPage}
            onChange={handleChangeRowsPerPage}
            displayEmpty
            inputProps={{
                'aria-label': 'Rows per page',
            }}
            sx={{
                width: "40px",
                padding: "0px 3px",
                border:"1px solid rgba(255, 255, 255, 1)",
                background: "rgba(239, 238, 233, 1)",
                '& fieldset': {
                padding: '0px',
                },
                '& .MuiSelect-icon': {
                right: '3px',
                },
                '& .MuiSelect-select': {
                padding: '0px 4px',
                display: 'flex',
                alignItems: 'center',
                justifyContent:"center"
                },
                '&.Mui-focused': {
                    outline: 'none',
                    borderColor: 'transparent',
                  },
                  '&:focus': {
                    outline: 'none',
                  },
            }}
            >
            <MenuItem value={1}>1</MenuItem>
            <MenuItem value={2}>2</MenuItem>
            <MenuItem value={3}>3</MenuItem>
            <MenuItem value={4}>4</MenuItem>
          </Select>
        </FormControl>
        <Typography variant="body2" sx={{ marginLeft: 1 }}>
        </Typography>
      </Box>

      {/* Right Section: Page numbers and navigation */}
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <IconButton
          onClick={(event) => handleChangePage(event, page - 1)}
          disabled={page === 0}
          sx={{
            marginRight: -0.6,
            '&.Mui-disabled': {
              opacity: 0.3,
            },
            "&:hover":{
                background:"none"
            }
          }}
        >
          <KeyboardArrowLeft />
            <span style={{fontSize:"14px",fontWeight:500,color:"rgba(83, 80, 63, 1)"}}>prev</span>
        </IconButton>

        {/* Page Numbers */}
        {pageNumbers.map((pageNum) => (
          <span
            key={pageNum}
            onClick={(event:any) => handleChangePage(event, pageNum)}
            style={{
              margin: '0 4px',
              height:"32px",
              width:"32px",
              borderRadius:"50%",
              cursor:"pointer",
              display:"flex",alignItems:"center",justifyContent:"center",
              backgroundColor: page === pageNum ? "rgba(83, 80, 63, 1)" : 'rgba(239, 238, 233, 1)',
              color: page === pageNum ? 'rgba(255, 255, 255, 1)' : 'rgba(83, 80, 63, 1)',
            }}
          >
            {pageNum + 1}
          </span>
        ))}

        {/* Next Button */}
        <IconButton
          onClick={(event) => handleChangePage(event, page + 1)}
          disabled={page >= totalPages - 1}
          sx={{
            marginLeft: -0.6,
            '&.Mui-disabled': {
              opacity: 0.3,
            },
            "&:hover":{
                background:"none"
            }
          }}
        >
            <span style={{fontSize:"14px",fontWeight:500,color:"rgba(83, 80, 63, 1)"}}>
          Next 
            </span>
          <KeyboardArrowRight />
        </IconButton>
      </Box>
    </Box>
  );
};

export default CustomPagination;
