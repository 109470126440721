// Customizable Area Start
Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Wishlist";
exports.labelBodyText = "Wishlist Body";
exports.deleteAPiMethod = "DELETE"
exports.btnExampleTitle = "CLICK ME";
exports.urlGetWishlist = "bx_block_item/items";
exports.getWishlist = (sortBy)=>`bx_block_wishlist/wishlists?filter_by=${sortBy?.value}`;
exports.toggleWishlist = "bx_block_wishlist/toggle_wishlist"
exports.addRemoveFavApiEndPoint='bx_block_wishlist/wishlists/add_favorite_or_remove'
exports.moveToCartApiEndPoint='bx_block_shopping_cart/order_items'
exports.endPointYouMayLike='bx_block_catalogue/catalogues/new_arrivals'
exports.endPointCart=(cartId)=>`bx_block_shopping_cart/orders/${cartId}`
exports.backToHome='Back to Home'
exports.noFavouritesText='No Favourites'
exports.you_havent_liked_any_items_yet='You haven’t liked any items yet.'
exports.Favorites='Favorites'
exports.endPointgetcategoriesArrayData='bx_block_categories/categories'
exports.endPointcategories_hoverData=(categorID)=>`bx_block_categories/categories/categories_hover_options?category_id=${categorID}`
exports.filteredCatalogues=(categoryId)=>`bx_block_catalogue/catalogues/filtered_catalogues?category_id=${categoryId}`
exports.sortList=[{ value: 'recent', label: 'Recent' },
  { value: 'production_year', label: 'Production Year' },
  { value: 'date_of_listing', label: 'Date of Listing' },
  { value: 'price_high_to_low', label: 'Price: High to Low' },
  { value: 'price_low_to_high', label: 'Price: Low to High' },
]
exports.moveToCart='MOVE TO CART'
exports.remove='Remove'
exports.cancel='Cancel'
exports.confirmation='Confirmation'
exports.are_you_sure_you_want_to_remove_it_from_your_Favorites='Are you sure you want to remove it from your Favorites ?'
exports.success='Success!'
exports.your_Omega_Watch_has_been_successfully_moved_to_the_cart='Your Omega Watch has been successfully moved to the cart'
exports.goToCart='Go to Cart'
exports.you_May_like='You May like'
exports.oops='Oops!'
// Customizable Area End
