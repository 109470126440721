export const ProductsIcon=require("../assets/ProductsIcon.svg").default
export const upArrowIcon=require("../assets/uparrow.svg").default
export const noProductIcon=require("../assets/productIcon.svg").default
export const purcahedOrderIcon=require("../assets/purchasedordersIcon.svg").default
export const OffersIcon=require("../assets/offersIcon.svg").default
export const requestsIcon=require("../assets/requestsIcon.svg").default
export const downArrowIcon=require("../assets/downArrow.svg").default
export const calenderIcon=require("../assets/button_calendar.png").default



