import React from "react";
// Customizable Area Start
import UserProfileBuyerController, {
  Props,
  configJSON,
  CountryInterArr
} from "./UserProfileBuyerController.web";
import {
    Box,
    styled,
    Typography,
    Badge,
    Avatar,
    Modal,
    TextField,
    Button,
    InputAdornment,
    IconButton,
  } from "@mui/material";
  import { AppHeader } from "../../../components/src/AppHeader.web";
import { Footer } from "../../../components/src/Footer.web";
import Select from "react-select";
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import {profile,editpen, leftCircle, rightCircle, penIcon, crossIcon, calenderIcon,cameraIcon} from "./assets"
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// Customizable Area End

export default class UserProfileBuyer extends UserProfileBuyerController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
    hanldeReturnTextBox = (lable: string, value: string, lable2: string, value2: string) => {

        return <FlexBox>
            <Box className='leftBox'>
                <Typography className="lable">{lable}</Typography>
                <Typography className="lableValue">{value}</Typography>
            </Box>
            <Box className='rightBox'>
                <Typography className="lable">{lable2}</Typography>
                <Typography className="lableValue">{value2}</Typography>
            </Box>
        </FlexBox>
    }
    handleReturnInput = (label: string, placeholder: string, stateValue: string, state: string) => {
        return (
            <Box className='singleInputParentBox'>
                <LabelText >
                    {label}
                </LabelText>
                <InputField
                    fullWidth
                    error={state == 'email' && this.state.emailErr}
                    helperText={state == 'email' && this.state.emailErr ? configJSON.emailErr : ''}
                    placeholder={placeholder}
                    variant="outlined"
                    value={stateValue.trimStart()}
                    data-test-id='inputField'
                    onChange={(event) => this.handleChangeInput(event.target.value, state)}
                />

            </Box>
        )
    }
    handleReturnSelect = (optionArrList: CountryInterArr[], label: string, placeholder: string, stateValue: CountryInterArr | {} | undefined | null, state: string) => {
        return (
            <Box className='singleInputParentBox'>
                <LabelText >
                    {label}
                </LabelText>
                <ReactSelect
                    options={optionArrList}
                    placeholder={placeholder}
                    data-testid="countryCode"
                    value={stateValue}
                    onChange={(event) => this.handleChangeSelect(event, state)}
                    data-test-id='selectField'
                    isDisabled={optionArrList.length == 0}
                    classNamePrefix={'custom-select'}
                    styles={{

                        menu: (provided) => ({
                            ...provided,
                            border: '1px solid #53503F',
                            boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.15)',
                            zIndex: 10000,
                            borderRadius: '4px',
                            marginTop: '3.93px',
                            overflow: 'hidden',

                        }),
                        option: (provided, state) => ({
                            ...provided,
                            cursor: 'pointer',
                            gap: '8px',
                            boxSizing: 'border-box',
                            backgroundColor: state?.isSelected ? '#53503F' : 'unset',
                            paddingRight: '16px',
                            paddingLeft: '16px',
                            paddingBottom: '12px',
                            paddingTop: '12px',
                            fontWeight: state.isSelected ? 600 : 400,
                            color: state.isSelected ? '#FFFFFF' : '#3C3E49',
                            fontFamily: "'Montserrat', sans-serif",
                            fontSize: '12px',
                            "&:hover": {
                                backgroundColor: state?.isSelected ? '#53503F' : '#E0E0E0',
                            }

                        }),

                    }}
                />
            </Box>
        )
    }
    handleEditProfileModal = () => {
        return (
            <Box >
                <Modal
                    open={this.state.editModalOpen}
                    onClose={() => this.setState({ editModalOpen: false })}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    data-test-id='editModalOpen'
                    sx={{ backdropFilter: "blur(5px)" }}
                >
                    <EditProfileModalBox >
                        <Box data-test-id='crossIconBox' className="crossIconBox" onClick={() => this.setState({ editModalOpen: false })}>
                            <img src={crossIcon} alt="" />
                        </Box>
                        <Typography className="editProfileTex">{configJSON.edit_Profile}</Typography>
                        <Box className='gridInputBox'>
                            <Box className='cicularImageBox'>
                                <BadgeCustomModal
                                    overlap="circular"
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                    className="badge"
                                    data-test-id='badgeCustom2'
                                    badgeContent={
                                        <label htmlFor="file-inputImage">
                                            <CameraIcon alt="" src={cameraIcon} className="cameraIcon" />
                                            <input type="file" data-test-id='editProfileImage2' accept='image/*' id='file-inputImage' style={{ display: 'none' }} onChange={this.handleImageUpload} />
                                        </label>
                                    }
                                >
                                    <Avatar alt="" className="avatar" src={this.state.profileImage} />
                                </BadgeCustomModal>
                            </Box>
                            <Box className='gridInputBoxChiled'>
                                <Box className='TwoInputBox'>
                                    {this.handleReturnInput(configJSON.first_Name, configJSON.Your_first_name, this.state.first_Name, `first_Name`)}
                                    {this.handleReturnInput(configJSON.last_Name, configJSON.Your_last_name, this.state.last_Name, `last_Name`)}
                                </Box>
                                <Box className='TwoInputBox'>
                                    {this.handleReturnSelect(this.state.countryListArray, configJSON.location, configJSON.Select, this.state.country_id, 'country_id')}
                                    <Box className='singleInputParentBox'>
                                        <LabelText >
                                            {configJSON.phone_Number}
                                        </LabelText>
                                        <PhoneNumStyle
                                            sx={{ border: this.state.new_phone_numberErr ? '1px solid #DC2626' : '' }}
                                            data-test-id="txtInputPhonenumber"
                                            className="custominput"
                                            value={this.state.phoneNumber}
                                            defaultCountry={this.state.country_id?.countryCode as "AE"}
                                            countries={[]}
                                            international
                                            onChange={(event) => this.handleChangePhoneNumber(event)}
                                            countrySelectProps={{
                                                tabIndex: -1,
                                                disabled: true,
                                            }}
                                        />
                                        {this.state.new_phone_numberErr && <Typography data-test-id='phoneNumberErr' sx={styles.errorText}>{this.state.new_phone_numberErrText!==''?this.state.new_phone_numberErrText:configJSON.phoneErr}</Typography>}
                                    </Box>
                                </Box>
                                <Box className='TwoInputBox'>
                                    {this.handleReturnInput(configJSON.email, configJSON.your_Email, this.state.email, `email`)}
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DateOfBirthBox >
                                            <LabelText >
                                                {configJSON.date_of_Birth}
                                            </LabelText>
                                            <DatePicker
                                                data-test-id="dateOfBirthTestId"
                                                name='dateOfBirth'
                                                value={this.state.date_of_Birth}
                                                onChange={(newValue) => this.setState({ date_of_Birth: newValue ,date_of_birthErr:false,date_of_birthErrText:''})}
                                                open={this.state.calendarOpen}
                                                onClose={() => this.setState({ calendarOpen: false })}
                                                slots={{
                                                    textField: TextField
                                                }}
                                                className="datePicker"
                                                slotProps={{
                                                    textField: {
                                                        placeholder: this.state.date_of_Birth ? "" : "MM/DD/YY",
                                                        helperText: <Typography style={{ ...styles.errorText, ...styles.errorTextDob, visibility: this.state.date_of_birthErr ? 'visible' : 'hidden' }}>{this.state.date_of_birthErrText}</Typography>,
                                                        InputProps: {
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <IconButton edge="end" onClick={this.handleCalendarIconClick}>
                                                                        <img src={calenderIcon} alt='data' />
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            ),
                                                        },
                                                        sx: {
                                                            backgroundColor: '#fff',
                                                            height: '56px',
                                                            border: this.state.date_of_birthErr ? '1px solid #DC2626' : '1px solid transparent',
                                                            boxSizing: 'border-box',
                                                            '& .MuiInputBase-input': {
                                                                padding: '15px 14px',
                                                                color: '#53503F',
                                                                fontWeight: 400,
                                                                fontFamily: "'Montserrat', sans-serif",
                                                                fontSize: '16px',
                                                            },
                                                            "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                                                                border: 'none',
                                                            },

                                                            borderRadius: '4px',
                                                        },
                                                        fullWidth: true,
                                                    },
                                                    popper: {
                                                        sx: {
                                                            ".MuiPickersCalendarHeader-root": {
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'space-between',
                                                                padding: '0 16px',
                                                                color: 'blue',
                                                            },
                                                            ".MuiPaper-root": {
                                                                borderRadius: "10px",
                                                                backgroundColor: '#fff',
                                                                color: '#53503F',
                                                            },
                                                            ".MuiPickersCalendarHeader-labelContainer": {
                                                                position: 'absolute',
                                                                left: '49%',
                                                                transform: 'translateX(-50%)',
                                                                color: '#53503F',
                                                            },
                                                            ".MuiPickersArrowSwitcher-root": {
                                                                width: '100%',
                                                                justifyContent: 'space-between',
                                                                display: 'flex',
                                                                color: 'pink',
                                                            },
                                                            ".Mui-selected:hover": {
                                                                backgroundColor: '#53503F',
                                                            },
                                                            ".Mui-selected": {
                                                                backgroundColor: '#53503F',
                                                                color: 'pink',
                                                            },
                                                            ".MuiPickersDay-root": {
                                                                color: '#53503F',
                                                            },
                                                            ".MuiPickersDay-root:hover": {
                                                                backgroundColor: '#53503F',
                                                                color: 'black',
                                                            },
                                                            ".MuiPickersDay-root.Mui-disabled": {
                                                                color: 'rgba(255, 255, 255, 0.3)',
                                                            },
                                                            ".MuiPickersDay-root.Mui-selected": {
                                                                color: '#fff',
                                                                backgroundColor: '#53503F',
                                                            },
                                                        },
                                                    },
                                                }}
                                            />
                                        </DateOfBirthBox >
                                    </LocalizationProvider>
                                </Box>
                                <Box>
                                    <LabelText >
                                        {configJSON.gender}
                                    </LabelText>
                                    <Box className='TwoInputBoxButton'>
                                        <MaleButton data-test-id='maleButton' style={{ backgroundColor: this.state.gender == 'Male' ? '#53503F' : "transparent", color: this.state.gender == 'Male' ? '#EDE6DC' : '#53503F' }} onClick={() => this.setState({ gender: 'Male' })} variant="contained" >{configJSON.male}</MaleButton>
                                        <FemaleButton data-test-id='femaleButton' style={{ backgroundColor: this.state.gender == 'Female' ? '#53503F' : "transparent", color: this.state.gender == 'Female' ? '#EDE6DC' : '#53503F' }} onClick={() => this.setState({ gender: 'Female' })} variant="contained" >{configJSON.female}</FemaleButton>
                                    </Box></Box>
                            </Box>
                        </Box>
                        <SaveButton data-test-id='saveButton' disabled={this.handleDisable()} variant="contained" onClick={() => this.handleSubmitEditProfile()}>{configJSON.save}</SaveButton>
                    </EditProfileModalBox>
                </Modal>
            </Box>
        )
    }
    handleSuccessModal = () => {
        return (
            <Box >
                <Modal
                    open={this.state.successPopupOpen}
                    onClose={() => this.setState({ successPopupOpen: false, successMessage: '' })}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    data-test-id='successPopupOpen'
                    sx={{ backdropFilter: "blur(5px)" }}
                >
                    <SuccessModalBox >
                        <Box data-test-id='crossIconBox2' className="crossIconBox" onClick={() => this.setState({ successPopupOpen: false, successMessage: '' })}>
                            <img src={crossIcon} alt="" />
                        </Box>
                        <Typography className="modalHeading">{configJSON.success}</Typography>
                        <Typography className="successPopupSubHeading" >{this.state.successMessage}</Typography>
                        <ButtonParentBox>
                            <GoToButton data-test-id='go_to_Profile' variant="contained" onClick={() => this.setState({ successPopupOpen: false, successMessage: '' })}>{configJSON.go_to_Profile}</GoToButton>
                        </ButtonParentBox>
                    </SuccessModalBox>
                </Modal>
            </Box>
        )
    }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const profileData=this.state.profileData?.attributes
    return (
        <ContainerBox>
            <AppHeader data-test-id='appHeader' count={this.state.cartData?.length}/>
            <OuterBox>
                <MainBox>
                    <TopBox>
                        <Box className='profileHeadingBox'>
                            <Typography className="profile">{configJSON.profile}</Typography>
                            <Typography data-test-id='edit_ProfileText' className="edit_ProfileText" onClick={() => this.handleOpenModal()}><img src={penIcon} alt="" className="pendIcon" />{configJSON.edit_Profile}</Typography>
                        </Box>
                        <img className="leftCircle" src={leftCircle} alt="" />
                        <img className="rightCircle" src={rightCircle} alt="" />
                    </TopBox>
                    <FormBox>
                        <BadgeCustom
                            overlap="circular"
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                            className="badge"
                            data-test-id='badgeCustom'
                            badgeContent={
                                <label htmlFor="file-input">
                                    <Editpen alt="" src={editpen} className="editpen" />
                                    <input type="file" data-test-id='editProfileImage' accept='image/*' id='file-input' style={{ display: 'none' }} onChange={(event) => this.handleUpdateProfile(event, this.state?.profileData?.id)} />
                                </label>
                            }
                        >
                            <Avatar alt="" data-test-id='avatar' className="avatar" src={profileData?.avatar_url !== null ? profileData?.avatar_url : profile} />
                        </BadgeCustom>
                        <ProfileDetailsBox>
                            <Box className='nameEmailCountryTextBox'>
                                <Typography className="nameText">{profileData.first_name} {profileData.last_name}</Typography>
                                <Typography className="emailText">{profileData.email}</Typography>
                                <Typography className="countryText">{profileData.country_name}</Typography>
                            </Box>
                            <PersonalInformationBox>
                                <Typography className="personal_Information">{configJSON.personal_Information}</Typography>
                                {this.hanldeReturnTextBox(configJSON.first_Name, profileData.first_name, configJSON.last_Name, profileData.last_name)}
                                {this.hanldeReturnTextBox(configJSON.email, profileData.email, configJSON.gender, profileData.gender)}
                                {this.hanldeReturnTextBox(configJSON.location, profileData.country_name, configJSON.date_of_Birth, profileData.date_of_birth)}
                                {profileData.full_phone_number !== '' && profileData.full_phone_number !== null && this.hanldeReturnTextBox(configJSON.phone_Number, `+${profileData.full_phone_number}`, '', '')}
                            </PersonalInformationBox>
                        </ProfileDetailsBox>
                    </FormBox>
                </MainBox>
            </OuterBox>
            {this.handleEditProfileModal()}
            {this.handleSuccessModal()}
            <Footer />
        </ContainerBox>

    );
    // Customizable Area End
  }
}

// Customizable Area Start
const ContainerBox = styled(Box)({
    backgroundColor: '#EDE6DC',
})
const OuterBox = styled(Box)({
    padding: '50.6px 112px 50.6px 112px',
    display: 'flex',
    justifyContent: 'center',
    boxSizing: 'border-box',
    "@media (max-width: 1050px)": {
        padding: '50.6px 50.6px 50.6px 50.6px',
    },
    "@media (max-width: 890px)": {
        padding: '50.6px 0px 50.6px 0px',
    }

})
const MainBox = styled(Box)({
    width: '1196px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    "@media (max-width: 1050px)": {
        width: '100%',
    }

})
const TopBox = styled(Box)({
    backgroundColor: '#53503F',
    height: '323px',
    width: '100%',
    position: 'relative',
    borderTopRightRadius: '8px',
    borderTopLeftRadius: '8px',

    "& .leftCircle": {
        position: 'absolute',
        left: 0,
        bottom: 0
    },
    "& .rightCircle": {
        position: 'absolute',
        right: 0,
        top: 0
    },
    "& .profileHeadingBox": {
        width: "100%",
        alignSelf: 'flex-end'
    },
    "& .profile": {
        margin: 'auto',
        width: 'fit-content',
        marginTop: '58.56px',
        fontWeight: 600,
        fontSize: '32px',
        fontFamily: "'Montserrat', sans-serif",
        color: '#EDE6DC',
        lineHeight: 'normal',
    },
    "& .edit_ProfileText": {
        position: 'absolute',
        top: '62.56px',
        right: '50.5px',
        fontWeight: 600,
        fontSize: '22px',
        fontFamily: "'Montserrat', sans-serif",
        color: '#EDE6DC',
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
        cursor: 'pointer',
        zIndex: 1,
        "& .pendIcon": {
            height: '19.83px',
            width: '19.83px'
        }
    },
    "@media (max-width: 760px)": {
        "& .edit_ProfileText": {
            right: '35px',
        }
    },
    "@media (max-width: 560px)": {
        "& .edit_ProfileText": {
            position: 'unset',
            width: 'fit-content',
            margin: 'auto'
        }
    },
})
const FormBox = styled(Box)({
    backgroundColor: '#FFFFFF',
    width: '678.78px',
    position: 'relative',
    bottom: '76px',
    left: '0px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    border: '1px solid #53503F',
    borderRadius: '4px',
    boxShadow: '0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 25px 50px 0px rgba(0, 0, 0, 0.09)',
    "@media (max-width: 1050px)": {
        width: '80%',
    },
    "@media (max-width: 760px)": {
        width: '90%',
    },
    "@media (max-width: 560px)": {
        width: '100%',
        borderTopRightRadius: '25px',
        borderTopLeftRadius: '25px'
    }
})
const ProfileDetailsBox = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    marginTop: '-76px',
    padding: '0px 90.39px 102.84px 90.39px',
    boxSizing: 'border-box',
    height: '100%',
    "& .nameEmailCountryTextBox": {
        textAlign: 'center',
        paddingTop: "12px",
        paddingBottom: "25px",
        boxSizing: 'border-box',
        borderBottom: '1px dashed #53503F',
        width: '100%'
    },
    "& .nameText": {
        fontWeight: 600,
        color: '#53503F',
        fontFamily: "'Montserrat', sans-serif",
        fontSize: '22px',
        lineHeight: 'normal'
    },
    "& .emailText, .countryText": {
        fontWeight: 400,
        color: '#53503F',
        fontFamily: "'Montserrat', sans-serif",
        fontSize: '16px',
        lineHeight: 'normal'
    },
    "@media (max-width: 776px)": {
        padding: '0px 30.39px 102.84px 30.39px',
    }
})
const PersonalInformationBox = styled(Box)({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '18px',
    paddingTop: '25px',
    boxSizing: 'border-box',
    "& .personal_Information": {
        fontWeight: 600,
        color: '#53503F',
        fontFamily: "'Montserrat', sans-serif",
        fontSize: '20px',
        lineHeight: 'normal'
    }


})
const BadgeCustomModal = styled(Badge)({
    position: 'relative',
    "& .avatar": {
        height: '117.97px',
        width: '117.97px',
        border: '2px solid #53503F'
    },
})
const CameraIcon = styled(Avatar)({
    height: '38.75px',
    width: '38.75px',
    position: 'relative',
    bottom: '16px',
    backgroundColor:'#53503F',
    border:'4px solid #F0E5FF',
    "& img":{
        width:'unset',
        height:'unset'
    }
})
const BadgeCustom = styled(Badge)({
    position: 'relative',
    bottom: '75px',

    "& .avatar": {
        height: '149.99px',
        width: '149.99px',
        border: '2px solid #EDE6DC'
    },
})
const Editpen = styled(Avatar)({
    height: '49.26px',
    width: '49.26px',
    position: 'relative',
    bottom: '16px'
})
const FlexBox = styled(Box)({
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    gap: '18px',

    "& .leftBox": {
        width: '200px',
        textAlign: 'left',
    },
    "& .lable": {
        fontWeight: 400,
        fontSize: '16px',
        fontFamily: "'Montserrat', sans-serif",
        color: '#53503F',
        lineHeight: 'normal',
        width: 'fit-content'
    },
    "& .lableValue": {
        fontWeight: 600,
        fontSize: '16px',
        fontFamily: "'Montserrat', sans-serif",
        color: '#53503F',
        lineHeight: 'normal',
        wordWrap: 'anyWhere',
        width: 'fit-content'
    },
    "& .rightBox": {
        width: '124px',
        textAlign: 'left',
    },
    "@media (max-width: 850px)": {
        "& .rightBox , .leftBox": {
            width: '200px',
        },
    },

    "@media (max-width: 550px)": {
        "& .rightBox , .leftBox": {
            width: '220px',
        },
    },
    "@media (max-width: 400px)": {
        flexDirection: 'column',
        "& .rightBox , .leftBox": {
            width: '100%',
        },

    }

})
const LabelText = styled(Typography)({
    fontFamily: "'Montserrat', sans-serif",
    color: '#53503F',
    fontWeight: 600,
    fontSize: '16px',
    marginBottom: '4px'
})
const DateOfBirthBox = styled(Box)({
    width: '100%',

})
const ReactSelect = styled(Select)({
    minWidth: '327px',
    outline: 'none',
    height: '56px',
    scrollbarGutter: 'stable',

    '& .custom-select__menu-list': {
        scrollbarWidth: '5px', scrollbarColor: '#53503F transparent',
        direction: 'ltr',
        borderRadius: '8px'
    },
    '& .custom-select__menu-list::-webkit-scrollbar': {
        scrollbarWidth: '5px',
        scrollbarColor: '#53503F transparent',
        direction: 'ltr',
    },
    '& .custom-select__menu-list::-webkit-scrollbar-thumb': {
        scrollbarColor: '#53503F transparent',
        scrollbarWidth: '5px',
        direction: 'ltr',
        borderRadius: '8px'
    },

    '& .custom-select__menu-list:hover': {
        scrollbarWidth: '5px',
        direction: 'ltr',
        scrollbarColor: '#53503F transparent',
        borderRadius: '8px'
    },
    "& .css-1uccc91-singleValue": {
        color: "#53503F",
        fontWeight: 600,
        fontSize: '16px',
        fontFamily: "'Montserrat', sans-serif",
    },
    "& .css-1okebmr-indicatorSeparator": {
        display: 'none'
    },
    "& .css-yk16xz-control": {
        border: 'none',
        borderColor: 'none',
        height: '100%',
    },
    "& .css-yk16xz-control:hover": {
        border: 'none',
        borderColor: 'none'

    },
    "& .css-g1d714-ValueContainer": {
        height: '56px',
        border: 'none'

    },
    "& .css-1pahdxg-control": {
        borderColor: 'transparent !important',
        outline: 'none !important',
        boxShadow: 'none !important',
        border: 'none !important',
        height: '56px',
        minHeight: '56px'
    },
    "& .custom-select__control": {
        minHeight: '56px',
        height: '56px',
    },
    "& .custom-select__value-container": {
        height: '56px',
    },
    "& .css-1wa3eu0-placeholder": {
        color: '#BABABA',
        fontWeight: 400,
        opacity: 1,
        fontFamily: "'Montserrat', sans-serif",
        fontSize: '16px',

    },
    "& .css-1hb7zxy-IndicatorsContainer": {
        width: '24px',
        display: 'flex',
        justifyContent: "center"
    },
    "& .css-tlfecz-indicatorContainer": {
        padding: '0'
    },
    "& .css-6q0nyr-Svg": {
        color: '#53503F',
        cursor: 'pointer'
    },
    "& .css-26l3qy-menu": {
        fontWeight: 400,
        color: '#3C3E49',
        fontFamily: "'Montserrat', sans-serif",
        fontSize: '12px',
        zIndex: 1000
    },
    "& .css-9gakcf-option": {
        backgroundColor: '#53503F',
        fontWeight: 600,
        color: '#FFFFFF',
        fontFamily: "'Montserrat', sans-serif",
        fontSize: '12px',
    },
    "& span": {
        display: 'none'
    },
    "& svg": {
        cursor: 'pointer',
        position: 'absolute',
        right: '8px',
        top: '20px',
        color: '#53503F'
    },

    "@media (max-width: 400px)": {
        minWidth: 'unset'
    }
})
const PhoneNumStyle = styled(PhoneInput)({

    minWidth: "327px",
    boxSizing: "border-box",
    height: '56px',
    zIndex: 1,
    borderRadius: 4,
    position: "relative",
    display: 'flex',
    alignItems: 'center',
    color: "#53503F",
    paddingLeft: "8px",
    backgroundColor: '#FFFFFF',
    paddingRight: '1px',

    '& input': {
        border: 'none',
        fontWeight: 600,
        fontFamily: "'Montserrat', sans-serif",
        fontSize: '16px',
        color: "#53503F",
    },
    '& input:focus': {
        border: 'none',
        outline: 'none'
    },
    '& .PhoneInputInput': {
        fontWeight: 600,
        fontFamily: "'Montserrat', sans-serif",
        fontSize: '16px',
        color: "#53503F",
    },
    '& input:focus-visible': {
        border: 'none',
        outline: 'none'
    },
    "& .PhoneInputCountry": {
        marginRight: '8px',
        cursor: 'pointer'
    },
    '& .PhoneInputCountrySelectArrow': {
        color: '#53503F',
        borderLeft: '0px',
        borderTop: '0px',
        borderWidth: '2.8px',
        opacity: 2,
        fontSize: '22px',
        borderRadius: '.5px',
        marginLeft: '6.41px',
        cursor: 'pointer',
        display: 'none'
    },

    '@media (max-width:600px)': {
        minWidth: "unset"
    },


});
const EditProfileModalBox = styled(Box)({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%) scale(0.834)',
    width: '766px',
    borderRadius: '4px',
    padding: '50px 37.5px',
    boxSizing: 'border-box',
    backgroundColor: '#EFEEE9',
    display: 'flex',
    flexDirection: 'column',
    height: '816px',
    maxHeight: '816px',
    justifyContent: 'center',
    alignItems: 'center',
    "& .editProfileTex": {
        fontFamily: "'Montserrat', sans-serif",
        fontSize: '28px',
        fontWeight: 600,
        color: '#53503F',
        lineHeight: 'normal'
    },
    "& .TwoInputBox": {
        display: 'flex',
        gap: '25px',
        alignItems: 'center',
    },
    "& .TwoInputBoxButton": {
        display: 'flex',
        gap: '17px',
        alignItems: 'center',
    },
    '& .gridInputBox': {
        display: 'flex',
        gap: '20px',
        flexDirection: 'column',
        alignItems: 'center',
        margin: '50px 0px'
    },
    '& .gridInputBoxChiled': {
        display: 'flex',
        gap: '20px',
        flexDirection: 'column',
    },
    "& .crossIconBox": {
        alignSelf: 'end',
        cursor: 'pointer',
        position: 'absolute',
        right: '24px',
        top: '24.5px'
    },
    "& .makeAddressDefaultCheckBoxParent": {
        display: 'flex',
        gap: '10px',
        alignItems: 'center',
        justifyContent: 'center',
        fontFamily: "'Montserrat', sans-serif",
        fontSize: '14px',
        color: '#53503F',
        fontWeight: 400,
        "& img": {
            cursor: 'pointer',
            height: '24px',
            width: '24px'
        }
    },
    "& .cicularImageBox": {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    "@media (min-width:500px) and (max-width:780px)": {
        '& .gridInputBox': {
            width: '80%'
        },
        '& .gridInputBoxChiled': {
            width: '100%'
        }
    },
    "@media  (max-width:500px)": {
        '& .gridInputBox': {
            width: '100%'
        },
        '& .gridInputBoxChiled': {
            width: '100%'
        }
    },
    "@media (max-width:780px)": {
        padding: '20px 20px',
        maxHeight: 'unset',
        height: "unset",
        transform: 'translate(-50%, -50%) scale(0.77)',
        width: '95%',
        "& .TwoInputBox": {
            flexDirection: 'column',
            gap: '20px',
        },
        "& .TwoInputBox1": {
            alignItems: 'start'
        },
        '& .gridInputBox': {
            gap: '20px',
            margin: '20px 0px'
        },
        "& .singleInputParentBox": {
            width: '100%'

        }
    },
    "@media (max-width:370px)": {
        width: '125%',
        "& .singleInputParentBox": {
        }
    }
})
const SaveButton = styled(Button)({
    width: '360px',
    height: '56px',
    backgroundColor: '#53503F',
    textTransform: 'none',
    borderRadius: '4px',
    fontFamily: "'Montserrat', sans-serif",
    fontSize: '18px',
    fontWeight: 600,
    color: '#EDE6DC',
    cursor: 'pointer',
    '&.Mui-disabled': {
        backgroundColor: '#53503F',
        color: '#EDE6DC',
        opacity: 0.5,
        cursor: 'not-allowed'
    },
    "&:hover": {
        backgroundColor: '#53503F',
    },
    "@media (max-width:780px)": {
        width: '80%'
    },
    "@media  (max-width:500px)": {
        width: '100%'
    }

})
const InputField = styled(TextField)({
    minWidth: "327px",
    height: '56px',

    '& input::placeholder': {
        color: '#BABABA',
        opacity: 1,
        fontWeight: 400,
        fontSize: '16px',
        fontFamily: "'Montserrat', sans-serif",
    },

    "& .MuiInputBase-root": {
        color: "#53503F",
        fontWeight: 600,
        fontSize: '16px',
        fontFamily: "'Montserrat', sans-serif",
        height: '100%'
    },

    "& .MuiOutlinedInput-notchedOutline": {
        borderWidth: "0px",

    },
    "& .MuiOutlinedInput-root": {
        "&:hover fieldset": {
        },
        "&.Mui-focused fieldset": {
            borderWidth: "0px",

        },
    },

    "& .MuiOutlinedInput-input": {
        padding: "17px 8px",
        backgroundColor: '#FFFFFF',
        borderRadius: '4px',
        boxSizing: 'border-box',
        height: '100%'

    },

    "& .MuiFormHelperText-contained": {
        marginLeft: "0px",
        marginRight: "0px",
        fontWeight: 600,
        lineHeight: 'normal',
        fontSize: '12px',
        fontFamily: "'Montserrat', sans-serif",
        color: "#DC2626",
    },
    '& .css-1wc848c-MuiFormHelperText-root.Mui-error': {
        color: "#DC2626",
        margin: '0px',
        marginTop: '0.5px'
    },
    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
        border: '1px solid #DC2626'
    },
    '@media (max-width:660px )': {
        minWidth: "unset",
        maxWidth: "unset",

    },
});
const MaleButton = styled(Button)({
    width: '155px',
    height: '56px',
    textTransform: 'none',
    borderRadius: '4px',
    fontFamily: "'Montserrat', sans-serif",
    fontSize: '18px',
    fontWeight: 600,
    cursor: 'pointer',
    border: '1px solid rgba(83, 80, 63, 1)',

})
const FemaleButton = styled(Button)({
    width: '155px',
    border: '1px solid rgba(83, 80, 63, 1)',
    height: '56px',
    borderRadius: '4px',
    textTransform: 'none',
    fontFamily: "'Montserrat', sans-serif",
    fontWeight: 600,
    fontSize: '18px',
    cursor: 'pointer',


})
const SuccessModalBox = styled(Box)({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%) scale(0.89)',
    width: '600px',
    borderRadius: '4px',
    padding: '40px 49px',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#EFEEE9',
    height: '284px',
    justifyContent: 'center',
    maxHeight: '284px',
    alignItems: 'center',

    "& .areYouSureText": {
        fontSize: '20px',
        fontFamily: "'Montserrat', sans-serif",
        fontWeight: 400,
        color: '#53503F',
        marginTop: '8px',
        maxWidth: '408px',
        textAlign: "center"
    },
    "& .successPopupSubHeading": {
        fontWeight: 400,
        fontSize: '20px',
        fontFamily: "'Montserrat', sans-serif",
        marginTop: '8px',
        color: '#53503F',
        textAlign: "center",
        maxWidth: '392px',
    },
    "& .crossIconBox": {
        position: 'absolute',
        right: '28px',
        top: '28px',
        cursor: 'pointer'
    },
    "& .modalHeading": {
        fontSize: '22px',
        fontFamily: "'Montserrat', sans-serif",
        fontWeight: 600,
        color: '#53503F',
    },
    "@media  (max-width:550px)": {
        width: '100%',

    },
    '@media (max-width:400px )': {
        padding: '16.21px 25px 39.85px 25px',
        height: '215.57px',
        borderRadius: '4px',
        "& .modalHeading": {
            fontSize: '18px',
        },
        "& .areYouSureText, .successPopupSubHeading": {
            fontSize: '14px',
        },
        "& .crossIconBox": {
            right: '6px',
            top: '6px',
            "& img": {
                height: '24px',
                width: '24px'
            }
        }
    },
    '@media (max-width:362px )': {
        maxHeight: 'unset',
        height: 'unset',
    },
    '@media (max-width:359px )': {
        width: '100%',
    },
})



const GoToButton = styled(Button)({
    width: '360px',
    backgroundColor: '#53503F',
    height: '56px',
    borderRadius: '8px',
    textTransform: 'none',
    fontSize: '18px',
    fontFamily: "'Montserrat', sans-serif",
    fontWeight: 600,
    cursor: 'pointer',
    color: '#EDE6DC',
    "&:hover": { backgroundColor: '#53503F', },
    '@media (max-width:400px )': {
        width: '117px',
        borderRadius: '4px',
        height: '36px',
        fontSize: '12px',
    },
})
const ButtonParentBox = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    gap: '50px',
    marginTop: '40px',
    '@media (max-width:420px )': {
        gap: '28.45px',
    },

})
const styles = {
    errorText: {
        fontWeight: 600,
        fontFamily: "'Montserrat', sans-serif",
        fontSize: '12px',
        color: "#DC2626",
        position: 'absolute' as 'absolute',
        lineHeight: 'normal',
        marginTop: '2px'
    },
    errorTextDob: {
        marginLeft: '-15px'
    }

}
// Customizable Area End