import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { Message } from "../../../framework/src/Message";
interface ApiData {
  contentType?: string,
  method?: string,
  endPoint?: string,
  body?: {},
  type?: string,
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  handleFilterClose: any;
  handleApplyButton: any;
  categoryId: any;
  genderForFilter: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  priceRange: any;
  brand: number;
  subCategory: string[];
  year: string;
  datePosted: string;
  color: string;
  size: string;
  openFilter: boolean;
  brandData: any;
  subCategoryData: any;
  productionYearData: any;
  selectColorData: any;
  sizeData: any;
  minPriceData: any,
  maxPriceData: any,
  searchTerm: string,
  filteredBrands: any
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class FilterController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  filterDynamicDataApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      priceRange: [50, 450],
      brand: 0,
      subCategory: [],
      year: '',
      datePosted: '',
      color: '',
      size: '',
      openFilter: true,
      brandData: [],
      subCategoryData: [],
      productionYearData: [],
      selectColorData: [],
      sizeData: [],
      minPriceData: 0,
      maxPriceData: 0,
      searchTerm: '',
      filteredBrands: [] 
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        if(apiRequestCallId === this.filterDynamicDataApiCallId){
          this.handleFilterResponse(responseJson)
        }
      }
    }
  }

  handleFilterResponse = (responseJson: any) => {    
    let minPriceFloor = Math.floor(responseJson.min_price)
    let maxPriceFloor = Math.floor(responseJson.max_price)

    if(responseJson){
      this.setState({
        priceRange: [minPriceFloor, maxPriceFloor],
        brandData: responseJson.brands,
        subCategoryData: responseJson.sub_categories,
        productionYearData: responseJson.production_year,
        selectColorData: responseJson.color_codes,
        sizeData: responseJson.size_names,
        minPriceData: minPriceFloor,
        maxPriceData: maxPriceFloor,
        filteredBrands: responseJson.brands
      })
    }
  }

  handleFilterData = async () => {
    const queryParamsObj: any = {
      category_id: this.props.categoryId,
    };

    if ( this.props.genderForFilter === 'sortByWomen' ) {
      queryParamsObj.sorted_by_women = 'true';
    }

    if ( this.props.genderForFilter === 'sortByMen' ) {
      queryParamsObj.sorted_by_men = 'true';
    }

    const queryParams = new URLSearchParams(queryParamsObj).toString();

    this.filterDynamicDataApiCallId = await this.apiCall({
      method: configJSON.httpGetType,
      endPoint: `${configJSON.filterEndPoint}?${queryParams}`,
    });
  }

  async componentDidMount() {
    this.handleFilterData()
  }

  apiCall = async (data: ApiData) => {
    const { contentType, method, endPoint, body } = data
    const header = {
      'Content-Type': contentType,
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  handleSubCategorySelect = (subCat: any) => {
    const { subCategory } = this.state;
    this.setState({
      subCategory: subCategory.includes(subCat)
        ? subCategory.filter((name) => name !== subCat)
        : [...subCategory, subCat],
    });
  };


  

  handlePriceChange = (event: Event, newValue: number | number[]) => {
    if (Array.isArray(newValue) && newValue.length === 2) {
      this.setState({ priceRange: newValue });
    }
  };

  filterBrands = () => {
    const { searchTerm, brandData } = this.state;
    const filteredBrands = brandData.filter((brand: any) =>
      brand.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    this.setState({ filteredBrands });
  };

  handleSearchChange = (newInputValue: string) => {
    this.setState({ searchTerm: newInputValue });
  };
  
  handleSelectBrand = (selectedBrand: any) => {
    this.setState({ 
      searchTerm: selectedBrand ? selectedBrand.name : '',
      brand: selectedBrand ? selectedBrand.id : '' 
    });
  };

  handleApplyButton = () => {
    const { priceRange, brand, subCategory, year, datePosted, color, size } = this.state;
    const { genderForFilter } = this.props;

    const filterData = {
      ...(priceRange && priceRange.length > 0 && { priceRange }),
      ...(brand && { brand }),
      ...(subCategory && subCategory.length > 0 && { subCategory }),
      ...(year && { year }),
      ...(datePosted && { datePosted }),
      ...(color && { color }),
      ...(size && {size}),
      ...(genderForFilter === 'sortByWomen' && { sortByWomen: 'true' }),
      ...(genderForFilter === 'sortByMen' && { sortByMen: 'true' }),
    };
    
    this.props.handleApplyButton(filterData)
    this.props.handleFilterClose()
    }
  
    renderValueLabel = (value: number) => {
      return `$${value}`;
    };

    handleClearAllBtn = () => {
      const filterData = {
        priceRange: [],
        brand: '',
        subCategory: [],
        year: '',
        datePosted: '',
        color: '',
        size: '',
        sortByWomen: null,
        sortByMen: null,
      };

      this.props.handleApplyButton(filterData);
      this.props.handleFilterClose()

    }
  // Customizable Area End
}