import React, { useState } from "react";
import { styled } from "@mui/system";
import { Modal, Box, Typography, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const ScrollableContent = styled(Box)({
  width: '103%',
  boxSizing: "border-box",
  paddingLeft: "16px",
  minHeight: '300px',
  overflowY: 'auto',
  borderRadius: '8px',
  paddingRight: "19px",
  '&::-webkit-scrollbar': {
    width: '7px',
  },
  '&::-webkit-scrollbar-track': {
    background: '#f9f9f9',
    borderRadius: "8px"
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#53503F',
    borderRadius: '8px',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    background: '#53503F',
  },
});

const CustomModal = styled(Box)(({ theme }) => ({
  width: "33vw",
  maxHeight:"95vh",
  backgroundColor:"EFEEE9",
  height: "auto",
  boxSizing: "border-box",
  background: "#EFEEE9",
  padding: "20px",
  gap: "10px",
  borderRadius: "4px",
  position: "relative",
  margin: "auto",
  top: "50%",
  transform: "translateY(-50%)",
  display: "flex",
  flexDirection: "column",
}));

const ModalHeading = styled(Typography)({
  textAlign: "left",
  color: "#53503F",
  fontFamily: "'Montserrat', sans-serif",
  fontSize: "24px",
  fontWeight: 600,
});

const CloseButton = styled(IconButton)({
  position: "absolute",
  top: "35px",
  right: "10px",
});

const FooterButton = styled("button")({
  position: "absolute",
  bottom: "25px",
  left: "50%",
  transform: "translateX(-50%)",
  width: "360px",
  height: "56px",
  padding: "16px 0px",
  borderRadius: "8px",
  color: "white",
  border: "none",
  backgroundColor: "#53503F",
  fontFamily: "'Montserrat', sans-serif",
  fontSize: "18px",
  fontWeight: 600,
  cursor: "pointer",
});

const CharityPolicy = (props:any) => {

  return (
    <Modal
      sx={{
        maxWidth: "100%",
        backdropFilter: "blur(6px)",
        backgroundColor: "rgba(0, 0, 0, 0.3)",
      }}
      open={props.openModal}
      onClose={props.handleClose}
    >
      <CustomModal>
        <div
          style={{
            marginTop: "20px",
            display: "flex",
            justifyContent: "space-between",
            padding: "0px 10px",
            alignItems: "center",
          }}
        >
          <ModalHeading variant="h6">{"Charity Policy"}</ModalHeading>
          <CloseButton onClick={props.handleClose}>
            <CloseIcon />
          </CloseButton>
        </div>
        <ScrollableContent>
          <Typography
            style={{
              fontFamily: "'Montserrat', sans-serif",
              fontSize: "16px",
              fontWeight: 400,
              color: "#53503F",
            }}
            dangerouslySetInnerHTML={{
              __html:props?.data
            }}
          />
          <FooterButton onClick={props.handleClose}>Close</FooterButton>
        </ScrollableContent>
      </CustomModal>
    </Modal>
  );
};

export default CharityPolicy;
