import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import firebase from "firebase";

// Customizable Area Start
import moment from "moment";
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  activeTab:string;
  txtInputValue:string;
  subTab:string;
  selectedDayFilter:any;
  subTabsList:any;
  tabsList:any;
  searchQuery:string;
  selectedSortFilter:any;
  openFilter:boolean;
  openFromCalendar:boolean;
  fromDateFilter:any;
  openToCalendar:boolean;
  toDateFilter:any;
  selectedCategoryList:any[];
  selectedBrandList:any[];
  selectedUseFilterList:any[];
  priceRange: any[],
  minPriceData: any,
  maxPriceData: any,
  selectedStatusFilterList:any[]
  overviewData:any,
  tabData:any[],
  selectedIds:any[],
  openHideModal:boolean,
  hidePeriod:number|string;
  deleteOrderModal:boolean;
  singleProductId:any;
  categoryList:any[],
  brandsList:any[],
  statusList:any[]
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AnalyticsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  overviewApiId:string="";
  cataloguesApi:string="";
  hideProductApiId:string='';
  deleteProductsApi:string="";
  searchDebounceTimer:any=null;
  searchAPIId:string="";
  productsFilterListApi:string="";
  filterApplyApiId:string=""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess)
      // Customizable Area Start
     , getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      activeTab:"Buying Requests",
      txtInputValue:"",
      subTab:configJSON?.subtabsMapping["Buying Requests"]?.[0] || "",
      selectedDayFilter:{value:"today",label:"Today"},
      subTabsList:configJSON.subtabsMapping,
      tabsList:configJSON.tabsNames,
      searchQuery:"",
      selectedSortFilter:{ value: "Sort by : Recent", label: "Sort by : Recent" },
      openFilter:false,
      openFromCalendar:false,
      fromDateFilter:"",
      openToCalendar:false,
      toDateFilter:"",
      selectedCategoryList:[],
      selectedBrandList:[],
      selectedUseFilterList:[],
      priceRange: [45, 450],
      minPriceData: 50,
      maxPriceData: 500,
      selectedStatusFilterList:[],
      overviewData:{},
      tabData:[],
      selectedIds:[],
      openHideModal:false,
      hidePeriod:"",
      deleteOrderModal:false,
      singleProductId:null,
      categoryList:[],
      brandsList:[],
      statusList:[]
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
    if (firebase.apps.length !== 0) {
      const defaultAnalytics = firebase.app().analytics();
      defaultAnalytics.logEvent("Analytics::Web::Load");
    }
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
    
    if(apiRequestCallId==this.overviewApiId){
      if(responseJson){
        this.setState({overviewData:responseJson})
      }
    }else if(apiRequestCallId==this.cataloguesApi){
     this.formdataResponseHandler(responseJson)
    }else if(apiRequestCallId===this.hideProductApiId){
      if(responseJson){
        this.setState({openHideModal:false,selectedIds:[],hidePeriod:""})
      }
    }else if(apiRequestCallId===this.deleteProductsApi){
        this.setState({deleteOrderModal:false,selectedIds:[]},()=>{
          this.getTabData(Boolean(this.state.singleProductId))
        })
    }
    else if(apiRequestCallId==this.searchAPIId){
      this.formatData(responseJson.data)
    }else if(apiRequestCallId==this.productsFilterListApi){
      this.handleFiltersListResponse(responseJson)
    }else if(apiRequestCallId===this.filterApplyApiId){
    this.formdataResponseHandler(responseJson)
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  async doButtonPressed() {
    const defaultAnalytics = firebase.app().analytics();
    defaultAnalytics.logEvent("Analytics::Web::button_clicked");
  }

  // Customizable Area Start
  handleTabChange = (tabName: string) => {
    const defaultSubTab = configJSON.subtabsMapping[tabName]?.[0] || ''
    this.setState({ activeTab: tabName, subTab: defaultSubTab,searchQuery:"" },()=>{
      this.getTabData(false);
      this.getFilterList()
    });
}
getFilterList=async()=>{
const{activeTab} =this.state
switch (activeTab){
  case "Products":
        this.getProductsFilterList()
  break;
  case "Orders":

  break;

  default:break;

}
}
handleFiltersListResponse=async(responseJson:any)=>{
  const {activeTab} =this.state
  const{brands,categories,max_listed_price,min_listed_price,status} =responseJson.filter
  let minPriceFloor = Math.floor(min_listed_price)
  let maxPriceFloor = Math.floor(max_listed_price)
  switch (activeTab){
    case "Products":
      this.setState({
        categoryList:categories,
        brandsList:brands,
        statusList:status,
        priceRange:[minPriceFloor+700,maxPriceFloor-2000],
        maxPriceData:maxPriceFloor,
        minPriceData:minPriceFloor
      })
      break;
      case "Orders":
        console.log("response",responseJson)
      break;
      case "Offers":
        console.log("response offers",responseJson.filter)
      break;
    default:break;
  }
}
getProductsFilterList=async()=>{
  const token=await getStorageData("loginToken");
  this.productsFilterListApi=this.apiCall(token,{
    method:"GET",
    endPoint:`/bx_block_catalogue/catalogues/load_shopper_filter`
  })
}
formdataResponseHandler=(responseJson:any)=>{
  if(!responseJson.errors){
    this.formatData(responseJson.data)
  }else{
    this.setState({tabData:[]})
  }
}
getTabData=async(getHiddenProducts:boolean)=>{
  const {activeTab}=this.state
  const token=await getStorageData("loginToken")
  if(activeTab=="Products"){
    this.cataloguesApi=this.apiCall(token,{
      method:"GET",
      endPoint:getHiddenProducts?`/bx_block_catalogue/catalogues?scope=hidden_products`:`/bx_block_catalogue/catalogues`
    })
  } 
}
async componentDidMount() {
  super.componentDidMount();
this.getOverviewdata();
}

handleSubTabChange = (subTabname: string) => {
    this.setState({ subTab: subTabname,selectedIds:[],searchQuery:"" },()=>{
      if(this.state.subTab=="Hidden Products"){
        this.getTabData(true)
      }else if(this.state.subTab=="All Products"){
        this.getTabData(false)
      }
      }
    );
}
handleSearchDebounce = () =>{
  this.searchDebounceTimer = setTimeout(async() => {
    this.searchApiCall()
    
   }, 500);
  }
  searchApiCall=async()=>{
    const token=await getStorageData("loginToken")
    if(this.state.searchQuery){
      this.searchAPIId=this.apiCall(token,{
        method:"GET",
        endPoint:`/bx_block_catalogue/catalogues?query=${this.state.searchQuery}`
      })
    }
  }
formatData=(response:any)=>{
  const {activeTab}=this.state;
  let data:any[]=[]
  if(activeTab=="Products"){
    data=response.map((ele:any)=>{
      return {
        modelNumber:ele.attributes?.modal_no||"",
        image:ele?.attributes?.variant_info?.image_url||"",
        listedDate:ele.attributes?.listed_date||"",
        category:ele.attributes?.category,
        brand:ele.attributes?.brand,
        productName:ele.attributes?.product_name,
        listedPrice:ele.attributes?.variant_info?.listed_price,
        sold: ele.attributes?.sold||"-",
        offers: ele.attributes?.offers||"-",
        orders: ele.attributes?.orders||"-",
        status:ele.attributes?.status,
        selected:false,
        id:ele.id
      }
    })
  }
  this.setState({tabData:data})
}
handleDeleteProductsModal=()=>{
  this.setState({deleteOrderModal:!this.state.deleteOrderModal})
}
getSingleProductId=(id:any,isHideModalOpen:boolean)=>{
  this.setState({singleProductId:id},()=>{
    if(isHideModalOpen){
      this.setState({openHideModal:true})
    }else{
      this.setState({deleteOrderModal:true})
    }
  })
}
getFilteredData = () => {
  const { activeTab, subTab } = this.state;
  switch (activeTab) {
    case "Buying Requests":
      if (subTab === "Requests") {
        return configJSON.tabData[activeTab].map((item:any) => ({
          ...item,
          requestList: item.requestList
        }));
      }
      return []

    case "Products":
      return this.state.tabData

    case "Orders":
      return []

    case "Offers":
      return [];
    case "Wish Requests":
      return []

    default:
      return [];
  }
};
handleCheckboxChange = (id: number) => {
  this.setState((prevState:any) => {
    const { selectedIds } = prevState;
    const isSelected = selectedIds.includes(id);
    const updatedIds = isSelected
      ? selectedIds.filter((selectedId:any) => selectedId !== id)
      : [...selectedIds, id];

    return { selectedIds: updatedIds };
  });
};
handleDaySelect=(data:any)=>{
  this.setState({selectedDayFilter:data},this.getOverviewdata)
}
handleSortOptionChange=(data:any)=>{
this.setState({selectedSortFilter:data})
}
handlesearchChange=(e:any)=>{
  this.setState({searchQuery:e.target.value},()=>{
    this.handleSearchDebounce()
  })
}
handleFilter=()=>{
  this.setState({openFilter:!this.state.openFilter,selectedBrandList:[],selectedCategoryList:[],selectedStatusFilterList:[]})
}
renderValueLabel = (value: number) => {
  return `$${value}`;
};
handleFromDate = (newValue: Date | null) => {
  this.setState({ fromDateFilter: moment(newValue).format("YYYY-MM-DD") });
};
handleFromCalendar=()=>{
  this.setState({openFromCalendar:!this.state.openFromCalendar})
}
handleToDate = (newValue: Date | null) => {
  this.setState({ toDateFilter: moment(newValue).format("YYYY-MM-DD") });
};
handleToCalendar=()=>{
  this.setState({openToCalendar:!this.state.openFromCalendar})
}
handleCategorySelect = (subCat: any) => {
  const { selectedCategoryList } = this.state;
  this.setState({
    selectedCategoryList: selectedCategoryList.includes(subCat)
      ? selectedCategoryList.filter((name:any) => name !== subCat)
      : [...selectedCategoryList, subCat],
  });
};
handleBrandSelect = (subCat: any) => {
  const { selectedBrandList } = this.state;
  this.setState({
    selectedBrandList: selectedBrandList.includes(subCat)
      ? selectedBrandList.filter((name:any) => name !== subCat)
      : [...selectedBrandList, subCat],
  });
};
handleUseFilterSelect = (subCat: any) => {
  const { selectedUseFilterList } = this.state;
  this.setState({
    selectedUseFilterList: selectedUseFilterList.includes(subCat)
      ? selectedUseFilterList.filter((name:any) => name !== subCat)
      : [...selectedUseFilterList, subCat],
  });
};
handleStatusFilterSelect = (subCat: any) => {
  const { selectedStatusFilterList } = this.state;
  this.setState({
    selectedStatusFilterList: selectedStatusFilterList.includes(subCat)
      ? selectedStatusFilterList.filter((name:any) => name !== subCat)
      : [...selectedStatusFilterList, subCat],
  });
};
handlePriceChange = (event: any, newValue: number | number[]) => {
  if (Array.isArray(newValue) && newValue.length === 2) {
    this.setState({ priceRange: newValue });
  }
};
handleCancelFilter=()=>{
  this.setState({
    priceRange:[1000,1250],
    selectedUseFilterList:[],
    selectedBrandList:[],
    selectedCategoryList:[],
    selectedStatusFilterList:[],
    fromDateFilter:"",
    toDateFilter:""
  },this.handleFilter)
}
getApplyFilterParams = () => {
  let filterParams = "";

  switch (this.state.activeTab) {
    case "Products":
      const params = new URLSearchParams();

      if (this.state.priceRange) {
        params.append("filter_by_price_ranges",this.state.priceRange.join(",")
      );
      }

      if (this.state.selectedCategoryList && this.state.selectedCategoryList.length > 0) {
        params.append(
          "filter_by_category_ids",
          this.state.selectedCategoryList.join(",")
        );
      }
      if (this.state.selectedBrandList && this.state.selectedBrandList.length > 0) {
        params.append(
          "filter_by_brand_ids",
          this.state.selectedBrandList.join(",")
        );
      }
    
      filterParams= `${decodeURIComponent(params.toString())}`;
      break;
    case "Orders":

      break;
      case "Offers":
        break;
      default:
      return filterParams;
  }
  return `?${filterParams}`;
};

handleApplyBtn=async()=>{
  const {priceRange,selectedBrandList,selectedCategoryList,selectedUseFilterList,fromDateFilter,toDateFilter}=this.state
  const filters=this.getApplyFilterParams();
  const token=await getStorageData("loginToken")
  this.filterApplyApiId=this.apiCall(token,{
    method:"GET",
    endPoint:`/bx_block_catalogue/catalogues${filters}`
  })
  this.handleFilter();
}
getOverviewdata=async()=>{
  const token=await getStorageData("loginToken")
  this.overviewApiId=this.apiCall(token,{
    method:"GET",
    endPoint:`/bx_block_analytics/analytics?filter_by=${this.state.selectedDayFilter.value}`
  })
}
apiCall = (token: any,payload:any) => {
  let {method,endPoint,body}=payload
  const header = {
    'Content-Type': 'application/json',
    token: token||"",
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    endPoint
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  body&&requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    body
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
   method
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
 return requestMessage.messageId;

};
handleHideModal=()=>{
  this.setState({openHideModal:!this.state.openHideModal})
}
handlePeriodChange=(event:any)=>{
  this.setState({hidePeriod:event?.target.value})
}
handleConfirmHidePeriod=async()=>{
  const {hidePeriod,selectedIds,singleProductId}=this.state
  const token =await getStorageData("loginToken");
  const body={
    "catalogue_ids":singleProductId|| selectedIds,
    "hidden_days": hidePeriod
}
this.hideProductApiId=this.apiCall(token,{
  method:"PUT",
  endPoint:`/bx_block_catalogue/catalogues/hide_catalogues`,
  body:JSON.stringify(body)
})
}
handleConfirmDeleteProducts=async()=>{
  const {selectedIds,singleProductId}=this.state
  const token =await getStorageData("loginToken");
  const body={
    "catalogue_ids":singleProductId|| selectedIds
}
this.deleteProductsApi=this.apiCall(token,{
  method:"DELETE",
  endPoint:`/bx_block_catalogue/catalogues/delete_all`,
  body:JSON.stringify(body)
})
}
navigationToAnyPage = (pageName: string) => {
  const navigation = new Message(getName(MessageEnum.NavigationMessage));
  navigation.addData(
      getName(MessageEnum.NavigationTargetMessage),
      pageName
  );
  navigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
  this.send(navigation);
};
  // Customizable Area End
}
