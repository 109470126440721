import React from "react";
import {
  Box,
  TextField,
  Grid,
  Typography,
  Modal,
  styled,
  InputLabel
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
const eyeIcon=require("../socialmediaicons/image_.png").default
const eyeCloseIcon=require("../socialmediaicons/image_visibility_off.png").default



const FooterButton = styled("button")({
  width: "360px",
  height: "56px",
  padding: "16px 0px",
  borderRadius: "4px",
  color: "white",
  border: "none",
  backgroundColor: "#53503F",
  fontFamily: "'Montserrat', sans-serif",
  fontSize: "18px",
  fontWeight: 600,
  cursor: "pointer",
});
const MyTextField = styled(TextField)(({ theme }) => ({
    width: "360px",
    boxSizing: "border-box",
    height: "56px",
    justifyContent: "center",
    padding: "10px 8px",
    gap: 8,
    borderRadius: "8px",
    borderWidth: "0.2px 0 0 0",
    backgroundColor: "white",
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "none",
        width: "100%",
      },
    },
    [theme.breakpoints.down('md')]: {
      width: "300px",
    },
    [theme.breakpoints.down('sm')]: {
      width: "100%",
    },
  }));

interface Iprops {
    openHideModal:boolean;
    handleClose:any;
    handlePeriodChange:any;
    hidePeriod:any;
    handleConfirmHidePeriod:any
}
const HideProductModal = (props: Iprops) => {
   const {openHideModal,handleClose,handlePeriodChange,hidePeriod,handleConfirmHidePeriod}=props
  return (
    <Modal
open={openHideModal}
onClose={handleClose}
aria-labelledby="modal-modal-title"
aria-describedby="modal-modal-description"
sx={{              
  maxWidth: '100%',
  backdropFilter: "blur(6px)",
  backgroundColor: "rgba(0, 0, 0, 0.3)",
}}
>
<Box
  sx={{
    boxSizing: "border-box",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "#EFEEE9",
    boxShadow: 24,
    p: 4,
    borderRadius: "8px",
    maxWidth: "460px",
    width: "460px",
    outline: "none",
  }}
>
  <div
    onClick={handleClose}
    style={{ display: "flex", justifyContent: "flex-end" }}
  >
    <CloseIcon
      style={{ height: "24px", width: "24px", float: "right" }}
    />
  </div>

  <div
    style={{
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
    }}
  >
    <Typography
      id="modal-modal-title"
      variant="h6"
      style={{
        fontFamily: "'Montserrat', sans-serif",
        fontSize: "28px",
        fontWeight: 600,
        lineHeight: "32px",
        letterSpacing: "-0.11999999731779099px",
        textAlign: "center",
        color: "#53503F",
      }}
      component="h2"
    >
      Hide Product?
    </Typography>
    <Typography
      id="modal-modal-description"
      style={{
        marginTop: "25px",
        fontFamily: "'Montserrat', sans-serif",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "24px",
        letterSpacing: "-0.11999999731779099px",
        textAlign: "center",
        maxWidth: "299px",
        wordWrap: "break-word",
        whiteSpace: "normal",
        color: "#53503F",
      }}
      sx={{ mt: 2 }}
    >
      For how long you want to hide the product 
      from listing ?
    </Typography>
    <div
      style={{
        marginTop: "35px",
        marginBottom: "15px",
        display: "flex",
        flexDirection: "column",
        gap: "4px",
      }}
    >
      <label
        htmlFor="email"
        style={{
          fontSize: "16px",
          lineHeight: "24px",
          fontFamily: "'Montserrat', sans-serif",
          fontWeight: 600,
          color: "#53503F",
        }}
      >
        Period
      </label>
      <MyTextField
      data-test-id="textfieldId"
        placeholder="Your email"
        id="email"
        type="number"
        required
        value={hidePeriod}
        onChange={(e: any) =>handlePeriodChange(e)}
      />
    </div>
    <div style={{width:"100%",marginTop:"30px",marginBottom:"15px",display:"flex",gap:"50px"}}>
            <FooterButton data-test-id="cancel-button"  style={{fontSize: '18px',backgroundColor:"#EFEEE9",color:"#53503F",border:"1px solid #53503F"}} onClick={handleClose}>Not Now</FooterButton>
            <FooterButton data-test-id="yes-button" style={{fontSize: '18px',backgroundColor:"#53503F"}}  onClick={handleConfirmHidePeriod}>Confirm</FooterButton>
            </div>
  </div>
</Box>
</Modal>
  );
};

export default HideProductModal;













