import React from "react";
// Customizable Area Start
import { 
 Box, Grid, Checkbox, InputLabel, Button,IconButton, Typography, Autocomplete, 
  ToggleButtonGroup, ToggleButton,InputAdornment, FormControl, Select, MenuItem, TextField, Dialog,
  DialogContent
} from "@mui/material";
import {
  createTheme
} from "@mui/material/styles";
import { MONTSERRAT_THIN } from '../../../components/src/fontConstants.web';
import {downArrow, searchIcon, checkBox, checkedBox, addIcon, editIcon, deleteIcon, uploadIcon, crossIcon, 
  closeIcon} from './assets';
import { SelectChangeEvent } from '@mui/material/Select';
import ShopperHeader from "../../../components/src/ShopperHeader.web";
export const configJSON = require("./config");
interface SelectFieldProps {
  selectProps: {
    label: string;
    value: string;
    onChange: (event: SelectChangeEvent) => void, 
    name: string;
  };
  dropdownProps: {
    openDropdown: boolean;
    dropdownClose: () => void;
    dropdownOpen: () => void;
  };
  selectOptions: Array<Record<string, any>>;
  handleIconClick: () => void;
  testId: string;
  useNameAsValue: boolean;
  disabled: boolean
}

// Customizable Area End

import CatalogueController, { Props } from "./CatalogueController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class Catalogue extends CatalogueController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderInputField(
    label: string, 
    placeholder: string, 
    value: string | undefined, 
    name: string, 
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void, 
    testId: string
  ) {
    return (
      <>
        <InputLabel className="montserratMediumGoogle">{label}</InputLabel>
        <TextField
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                border: 'none', 
              },
              '&.Mui-focused fieldset': {
                border: 'none', 
              },
            },
            '& .MuiInputBase-input::placeholder': {
              fontFamily: `${MONTSERRAT_THIN.fontFamily}, Arial, sans-serif`,
              color: '#53503F'
            },
            '& .MuiInputBase-input': {
              fontFamily: `${MONTSERRAT_THIN.fontFamily}, Arial, sans-serif`,
              color: '#53503F'
            },
          }}
          fullWidth
          name={name}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          style={webStyle.inputField}
          data-testid={testId}
        />
      </>
    );
  }
  
  renderSelectField = ({
    selectProps,
    dropdownProps,
    selectOptions,
    handleIconClick,
    testId,
    useNameAsValue,
    disabled
  }: SelectFieldProps) => {
  
    return (
      <>
        <InputLabel className="montserratMediumGoogle">{selectProps.label}</InputLabel>
        <FormControl fullWidth sx={webStyle.selectField}>
          <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            value={selectProps.value}
            onChange={selectProps.onChange}
            open={dropdownProps.openDropdown}
            onClose={dropdownProps.dropdownClose}
            onOpen={dropdownProps.dropdownOpen}
            data-testid={testId}
            displayEmpty
            sx={{
              ...webStyle.locationDropdown,
              '& .MuiSelect-select': {
                color: '#53503F',
                fontFamily: `${MONTSERRAT_THIN.fontFamily}, Arial, sans-serif`,
              },
              '&.Mui-disabled': {
                backgroundColor: '#f0f0f0', 
                color: '#b0b0b0',             
                cursor: 'not-allowed',        
              },
            }}
            name={selectProps.name}
            MenuProps={{
              PaperProps: {
                style: {
                  border: '1px solid #53503F',
                  minWidth: '200px',
                  width: '100px'
                },
              },
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'right',
              },
            }}
            IconComponent={() => 
              <img 
                src={downArrow}
                alt="arrow" 
                style={{ padding: '10px' }}
                data-testid="dropDownClickTest"
                onClick={handleIconClick}
              />
            }
            disabled={disabled}  
          >
            <MenuItem 
              value=""
              sx={{
              fontFamily: 'Montserrat, sans-serif',
              '&.Mui-selected:hover': {
                backgroundColor: '#5b5b5b',
              },
              '&.Mui-selected': {
                backgroundColor: '#80806e',  
                color: '#fff',
              },
              '&:hover': {
                backgroundColor: '#e0e0e0',
              },
              }}
            >
              <em style={webStyle.selectPlaceholder}>Select</em>
            </MenuItem>
            {selectOptions.map((option) => (
              <MenuItem 
                key={option.id}
                value={useNameAsValue ? option.attributes.name : option.id}
                sx={{
                  '&.Mui-selected': {
                    fontWeight: '400 !important',
                    backgroundColor: '#53503F', 
                    color: '#ffffff',
                    fontFamily: `${MONTSERRAT_THIN.fontFamily}, Arial, sans-serif`,
                  },
                  '&:hover': {
                    backgroundColor: '#53503F',
                    color: '#ffffff',
                  },
                }}
              >
                {option.attributes.name}
              </MenuItem>
            ))}
            <MenuItem>
            </MenuItem>
          </Select>
        </FormControl>
      </>
    );
  };
  
  brandSearch(
    testId: string
  ){
    return (
      <Box sx={{ marginBottom: '15px' }}>
        <Typography className="montserratFont" sx={{ fontSize: '14px', fontWeight: '400', marginBottom: '8px', color: '#333' }}>
          {configJSON.brandLabel}
        </Typography>
        <FormControl variant="outlined" fullWidth>
          <Autocomplete
            data-testid={testId}
            value={this.state.filteredBrands.find((brand: any) => brand.attributes.name === this.state.searchTerm) || null}
            onInputChange={(event, newInputValue) => this.handleSearchChange(newInputValue)}
            options={this.state.filteredBrands}
            getOptionLabel={(option) => option.attributes.name}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Search Brands"
                variant="outlined"
                sx={{
                  fontFamily: `${MONTSERRAT_THIN.fontFamily}, Arial, sans-serif`,
                  backgroundColor: '#F9F9F9',
                  borderRadius: '4px',
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '4px',
                    border: 'none',
                    '& .MuiOutlinedInput-notchedOutline': {
                      border: 'none',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      border: 'none',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      border: 'none',
                    },
                  },
                }}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      <img 
                      src={searchIcon}
                       alt='searchIcon'/>
                    </InputAdornment>
                  ),
                  endAdornment: null,
                }}
              />
            )}
            onChange={(event, value) => this.handleSelectBrand(value)}
            noOptionsText="No brands found"
            PaperComponent={({ children }) => (
              <Box
                sx={{
                  backgroundColor: '#ffffff',
                  border: '1px solid rgba(83, 80, 63, 1)',
                  borderRadius: '4px',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', 
                  marginTop: '8px',
                }}
              >
                {children}
              </Box>
            )}
            renderOption={(props, option) => {
              const isSelected = this.state.filteredBrands.find((brand: any) => brand.name === this.state.searchTerm) === option;
              
              const baseStyle = {
                padding: '8px 16px',
                fontSize: '14px',
              };
            
              const selectedStyle = isSelected ? {
                backgroundColor: 'rgba(83, 80, 63, 1)', 
                color: '#ffffff', 
                fontWeight: 'bold',
              } : {
                backgroundColor: '#FFF', 
                color: '#000000', 
                fontWeight: 'normal',
              };

              const finalStyle = { ...baseStyle, ...selectedStyle };

              return (
                <li
                  {...props}
                  key={option.id}
                  style={finalStyle}
                >
                  {option.attributes.name}
                </li>
              );
            }}

            fullWidth
          />
        </FormControl>
      </Box>
    )
  }

  productBox(
    serialNumber: number,
    productImage: string,
    productPrice: number,
    color: number,
    size: number,
    testId: string
  ){
    const colorObj = this.state.selectColorData.find(item => item.attributes.id === color); 
    const colorName = colorObj ? colorObj.attributes.name : null;
    const colorCode = colorObj ? colorObj.attributes.color_code : null;

    return (
        <Box sx={webStyle.container} data-testid="testId">
          <Box sx={webStyle.leftSide}>
            <Typography className='montserratFont' sx={webStyle.serialNumber}>{serialNumber}</Typography>
            <Box sx={webStyle.productMain}>
              <img 
              src={Object.keys(this.state.productImageData).length > 0 ? this.state.productImageData.image_url : productImage}
              alt="Product Image" 
              style={{
                width: '50px',
                height: '50px',
                objectFit: 'cover',
                marginRight: '8px',
                borderRadius: '2px',
                border: '1px solid rgba(83, 80, 63, 1)'
              }}/>
              <Typography className="montserratFont" sx={webStyle.price}>${productPrice}</Typography>
            </Box>
            <Typography className="montserratFont" sx={webStyle.colorText}>
              <Box sx={{ ...webStyle.colorBox, backgroundColor: colorCode }} />
              <Box>{colorName} ({colorName})</Box>
            </Typography>
            <Typography className="montserratFont" sx={webStyle.colorText}>{size}</Typography>
          </Box>

          <Box sx={webStyle.rightSide}>
            <IconButton 
            aria-label="edit" 
            sx={webStyle.iconButton} 
            data-testid='editIconTestId' 
            onClick={this.handleVariantPopupOpen}
            >
              <img src={editIcon} alt="Edit" style={webStyle.iconImage} />
            </IconButton>
            
            <IconButton aria-label="delete" sx={webStyle.iconButton}>
              <img src={deleteIcon} alt="Delete" style={webStyle.iconImage} />
            </IconButton>
          </Box>
        </Box>
    )
  };
  
  handleAddVariantPopUp(){
    return (
          <Dialog
            data-testid="variantTestId"
            open={this.state.variantPopup}
            onClose={this.handleVariantPopupClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="md"
            fullWidth
          >
            <DialogContent sx={webStyle.dialogBoxStyle}>
              <Box>
                <Box sx={closeIconStyle}>
                  <img src={closeIcon} alt="closeIcon" onClick={this.handleVariantPopupClose} />
                </Box>
                <InputLabel className="montserratFont" sx={webStyle.variantDetailHead}>Variant Details</InputLabel>
                <Box sx={webStyle.uploadBoxMain}>
                  {this.uploadVariant()}
                  <Grid container spacing={2}>
                    <Grid item lg={6} md={4} sm={4} xs={12} style={webStyle.marginB}>
                      {this.renderInputWithDoller(
                        configJSON.priceLabel,
                        'price',
                        '00',
                        this.state.price,
                        this.handlePrice,
                        'priceTestId',
                        false
                      )}
                    </Grid>
                    <Grid item lg={6} md={4} sm={4} xs={12} style={webStyle.marginB}>
                      {this.renderInputWithDoller(
                        configJSON.youEarnLabel,
                        'youEarn',
                        '00',
                        this.state.youEarn,
                        this.handleEarn,
                        'earnTestId',
                        true
                      )}
                    </Grid>
                  </Grid>

                  <InputLabel className="montserratMediumGoogle">{configJSON.selectColorLabel}</InputLabel>
                  <FormControl fullWidth sx={{ marginBottom: '15px' }}>
                    <Select
                      id="demo-simple-select"
                      labelId="demo-simple-select-label"
                      displayEmpty
                      data-testid="colorChoiceTestId"
                      name='color'
                      value={this.state.color}
                      onChange={(e) => this.setState({ color: e.target.value })}
                      renderValue={(selected) => {
                        if (selected === "") {
                          return <em style={webStyle.selectPlaceholder}>{configJSON.selectColorLabel}</em>;
                        }
                        
                        const selectedColor = this.state.selectColorData.find((c: any) => c.id === selected);
                  
                        if (selectedColor) {
                          return (
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              <Box
                              sx={{
                                backgroundColor: '#fff',
                                width: '33px',
                                height: '33px',
                                borderRadius: '4px',
                                padding: '4px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                border: '1px solid #000',
                                margin: '0px 15px 0px 0px'
                              }}
                            >
                              <Box
                                sx={{
                                  backgroundColor: selectedColor.attributes.color_code, 
                                  width: '31px',
                                  height: '31px',
                                  borderRadius: '4px',
                                }}
                              />
                            </Box>
                              {selectedColor.attributes.name}
                            </Box>
                          );
                        } else {
                          return <em style={webStyle.selectPlaceholder}>{configJSON.selectColorLabel}</em>;
                        }
                      }}
                      sx={{
                        backgroundColor: '#F9F9F9',
                        borderRadius: '8px',
                        height: '56px',
                        '& .MuiSelect-select': {
                          display: 'flex',
                          alignItems: 'center',
                        },
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            border: 'none',
                          },
                          '&.Mui-focused fieldset': {
                            border: 'none', 
                          },
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                          border: 'none'
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                          border: 'none', 
                        },
                      }}
                    >
                      <MenuItem 
                      value="" 
                      sx={{
                        fontFamily: 'Montserrat, sans-serif',
                        '&.Mui-selected:hover': {
                          backgroundColor: '#5b5b5b',
                        },
                        '&.Mui-selected': {
                          color: '#fff',
                          backgroundColor: '#80806e',  
                        },
                        '&:hover': {
                          backgroundColor: '#e0e0e0',
                        },
                        }}>
                          <em style={webStyle.selectPlaceholder}>{configJSON.selectColorLabel}</em>
                        </MenuItem>
                      {this.state.selectColorData.map((color) => (
                        <MenuItem 
                        key={color.id} 
                        value={color.id}  
                        sx={{
                          fontFamily: 'Montserrat, sans-serif',
                          color: 'rgba(60, 62, 73, 1)'
                        }}>
                          <Box
                            sx={{
                              backgroundColor: color.attributes.color_code,
                              width: '16px',
                              height: '16px',
                              borderRadius: '50%',
                              marginRight: '8px',
                              fontFamily: `${MONTSERRAT_THIN.fontFamily}, Arial, sans-serif`,
                              '&.Mui-selected': {
                                fontFamily: `${MONTSERRAT_THIN.fontFamily}, Arial, sans-serif`,
                              }
                            }}
                          />
                          {color.attributes.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  {this.renderSelectField({
                    selectProps: {
                      label: configJSON.sizeLabel, 
                      value: this.state.size,       
                      onChange: this.handleSize,    
                      name: 'SubCategory',                 
                    },
                    dropdownProps: {
                      openDropdown: this.state.sizeDropdown,   
                      dropdownClose: this.handleSizeClose,      
                      dropdownOpen: this.handleSizeOpen,      
                    },
                    selectOptions: this.state.sizeData,                    
                    handleIconClick: this.handleSizeDropdown,
                    testId: 'sizeTestId',
                    useNameAsValue: false,
                    disabled: false               
                  })}
                </Box> 
                <Grid item lg={12} md={6} sm={6} xs={12} style={webStyle.marginB}>
                    <Box display='flex' justifyContent='space-between'>
                      <Box>
                        <Button 
                          variant="contained" 
                          className="montserratFont"
                          sx={{
                            ...webStyle.btnMain, 
                            fontWeight: 600,
                            '&.Mui-disabled': {
                              backgroundColor: '#53503F', 
                              color: '#EDE6DC', 
                              cursor: 'not-allowed',
                              opacity: 0.5,
                              fontSize: '18px'
                            },
                          }}
                        >
                          {configJSON.discardText}
                        </Button>
                      </Box>
                      <Box style={webStyle.submitBtnMain}>
                        <Button 
                          variant="contained" 
                          className="montserratFont"
                          sx={{
                            ...webStyle.btnMain, 
                            fontWeight: 600,
                            '&.Mui-disabled': {
                              color: '#EDE6DC', 
                              backgroundColor: '#53503F', 
                              cursor: 'not-allowed',
                              fontSize: '18px',
                              opacity: 0.5,
                            },
                          }}
                        >
                          {configJSON.saveText}
                        </Button>
                        <Button 
                        className="montserratFont"
                        variant="contained" 
                        sx={{
                          ...webStyle.btnMain, 
                          fontWeight: 600,
                          backgroundColor: 'rgba(83, 80, 63, 1)',
                          color: '#ffffff',
                          '&.Mui-disabled': {
                            color: '#EDE6DC', 
                            backgroundColor: '#53503F', 
                            cursor: 'not-allowed',
                            opacity: 0.5,
                            fontSize: '18px'
                          },
                        }}
                        data-testid="addVariantTestId"
                        onClick={this.handleAddVariant}
                        >
                          {configJSON.addVariantText}                
                        </Button>
                      </Box>
                    </Box>
                  </Grid>
              </Box>
            </DialogContent>
          </Dialog>
          )
}
  uploadDocument() {
    return (
      <>
        <Box
          border="2px dashed #53503F"
          p={2}
          onDrop={this.handleDrop}
          textAlign="center"
          onDragOver={this.handleDragOver}
          sx={{
            display: 'flex',
            margin: 'auto',
            flexDirection: 'column',
            justifyContent: 'center',
            backgroundColor: 'rgba(83, 80, 63, 0.1)'
          }}
          data-testid="dropTestId"
          data-name="dropTest"
        >
          <Box sx={{ width: '100%' }}>
            <Typography
              component="label"
              htmlFor="file-upload" 
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: 'auto', 
                fontWeight: 400,
                cursor: 'pointer',
              }}
            >
              <img src={uploadIcon} alt="add icon" style={webStyle.uploadIconStyle} />
              <span 
              style={{textDecoration: 'underline', fontWeight: 600, color: 'rgba(83, 80, 63, 1)'}} 
              className="montserratFont" >{configJSON.clickToUpload}</span>
              <input
                id="file-upload"
                type="file"
                hidden
                multiple
                onChange={this.handleFileChange}
                accept=".jpg, .png, .pdf"
                name="imageFile"
                data-testid="imageTestId"
              />
            </Typography>
            <Typography className="montserratFont" sx={{color: 'rgba(83, 80, 63, 1)'}}>{configJSON.dragAndDrop}</Typography>
          </Box>
        </Box>
        <Typography className="montserratFont" style={webStyle.uploadNote}>{configJSON.note}</Typography>
        {this.state.errorMessage && (
          <Typography color="error" mt={2} sx={{margin: 'auto' }} className="montserratLightError">
            {this.state.errorMessage}
          </Typography>
        )}

        {this.state.previews.length > 0 && (
          <>
            <Box data-name="previewTestId" display="flex" flexWrap="wrap">
              {this.state.previews.map((preview: any, index: any) => {
                const fileType = this.state.selectedFiles[index].type;
                return (
                  <Box key={index} p={1}>
                    {fileType === 'application/pdf' ? (
                      <>
                      <Box sx={webStyle.previewDoc}>
                        <embed style={webStyle.embed} src={preview} width="200px" height="200px" />
                        <Box sx={webStyle.closeIcon}>
                          <img onClick={() => this.handleRemoveFile(index)} src={crossIcon} alt='closeIcon'/>
                        </Box>
                      </Box>
                      </>
                    ) : (
                      <>
                      <Box sx={webStyle.previewDoc}>
                        <img style={webStyle.imageUploaded} src={preview} alt="preview" width="200px" height="200px" />
                        <Box sx={webStyle.closeIcon}>
                          <img onClick={() => this.handleRemoveFile(index)} src={crossIcon} alt='uploadIcon'/>
                        </Box>
                      </Box>
                      </>
                    )}
                  </Box>
                );
              })}
            </Box>
          </>
        )}
      </>
    )
  }

  uploadVariant() {
    return (
      <>
        <Box
          border="2px dashed #53503F"
          p={2}
          onDrop={this.handleDropVariant}
          textAlign="center"
          onDragOver={this.handleDragOverVariant}
          sx={{
            display: 'flex',
            margin: 'auto',
            flexDirection: 'column',
            justifyContent: 'center',
            backgroundColor: 'rgba(83, 80, 63, 0.1)'
          }}
          data-testid="variantTestIdUpload"
          data-name="dropTest"
        >
          <Box sx={{ width: '100%' }}>
            <Typography
              component="label"
              htmlFor="file-uploadVariant" 
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: 'auto', 
                fontWeight: 400,
                cursor: 'pointer',
              }}
            >
              <img src={uploadIcon} alt="add icon" style={webStyle.uploadIconStyle} />
              <span 
              style={{textDecoration: 'underline', fontWeight: 600, color: 'rgba(83, 80, 63, 1)'}} 
              className="montserratFont" >{configJSON.clickToUploadProduct}</span>
              <input
                id="file-uploadVariant"
                type="file"
                hidden
                multiple
                onChange={this.handleFileChangeVariant}
                accept=".jpg, .png, .pdf"
                name="imageFile"
                data-testid="variantImageTestId"
              />
            </Typography>
            <Typography className="montserratFont" sx={{color: 'rgba(83, 80, 63, 1)'}}>{configJSON.dragAndDrop}</Typography>
          </Box>
        </Box>
        <Typography className="montserratFont" style={webStyle.uploadNote}>{configJSON.note}</Typography>
        {this.state.errorMessageVariant && (
          <Typography color="error" mt={2} sx={{margin: 'auto' }} className="montserratLightError">
            {this.state.errorMessageVariant}
          </Typography>
        )}
        {this.state.previewsVariant.length > 0 && (
          <>
            <Box data-name="previewTestId" display="flex" flexWrap="wrap">
              {this.state.previewsVariant.map((preview: any, index: any) => {
                const fileType = this.state.selectedFilesVariant[index].type;
                return (
                  <Box key={index} p={1}>
                    {fileType === 'application/pdf' ? (
                      <>
                      <Box sx={webStyle.previewDoc}>
                        <embed style={webStyle.embed} src={preview} width="200px" height="200px" />
                        <Box sx={webStyle.closeIcon}>
                          <img onClick={() => this.handleRemoveFileVariant(index)} src={crossIcon} alt='closeIcon'/>
                        </Box>
                      </Box>
                      </>
                    ) : (
                      <>
                      <Box sx={webStyle.previewDoc}>
                        <img style={webStyle.imageUploaded} src={preview} alt="preview" width="200px" height="200px" />
                        <Box sx={webStyle.closeIcon}>
                          <img onClick={() => this.handleRemoveFileVariant(index)} src={crossIcon} alt='uploadIcon'/>
                        </Box>
                      </Box>
                      </>
                    )}
                  </Box>
                );
              })}
            </Box>
          </>
        )}
      </>
    )
  }

  renderInputWithDoller(
    label: string,
    name: string,
    placeholder: string,
    value: number,
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
    testId: string,
    disabled: boolean
  ) {
    return (
      <>
        <InputLabel className="montserratMediumGoogle">{label}</InputLabel>
        <TextField
        disabled={disabled}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <span 
                style={{ 
                  backgroundColor: 'rgba(244, 244, 244, 1)', 
                  color: 'rgba(83, 80, 63, 1)',
                  padding: '10px 20px', 
                  borderRadius: '4px',
                }}>$</span>
              </InputAdornment>
            ),
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                border: 'none', 
              },
              '&.Mui-focused fieldset': {
                border: 'none', 
              },
            },
            '& .MuiInputBase-input::placeholder': {
              fontFamily: `${MONTSERRAT_THIN.fontFamily}, Arial, sans-serif`,
              color: '#53503F'
            },
            '& .MuiInputBase-input': {
              fontFamily: `${MONTSERRAT_THIN.fontFamily}, Arial, sans-serif`,
              color: '#53503F'
            },
          }}
          fullWidth
          name={name}
          placeholder={placeholder}
          style={webStyle.inputField}
          value={value}
          onChange={onChange}
          data-testid={testId}
        />
      </>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start    
    
    // Customizable Area End
    return (
      //Merge Engine DefaultContainer
      // Customizable Area Start
      <>
        {this.handleAddVariantPopUp()}
        <ShopperHeader/>
        <Box style={webStyle.sellAnItemMain as React.CSSProperties}>
          <Box style={webStyle.sellAnItem as React.CSSProperties}>
            <Typography className="montserratFont" sx={webStyle.sellItemText}>
              {configJSON.sellAnItem}
            </Typography>
            <Typography className="montserratFont" sx={webStyle.addProductText}>
              
              {configJSON.addProductDetails}
            </Typography>
          </Box>
          <Box style={webStyle.sellerForm}>
            <Grid item lg={6} md={4} sm={4} xs={12}>
              <InputLabel className="montserratFont" sx={webStyle.lightLabel}>
                {configJSON.whatKindOfItem}              
              </InputLabel>
              <ToggleButtonGroup
                value={this.state.gender}
                onChange={this.handleGenderChange}
                fullWidth
                aria-label="gender selection"
                sx={webStyle.toggleMain}
                data-testid="genderTestId"
                exclusive
              >
                <ToggleButton 
                value="male" 
                aria-label="Men" 
                sx={{...webStyle.toggleBtn, textTransform: 'none',fontFamily: `${MONTSERRAT_THIN.fontFamily}, Arial, sans-serif`}}>
                  {configJSON.menText}
                </ToggleButton>
                <ToggleButton 
                value="female" 
                aria-label="Women" 
                sx={{...webStyle.toggleBtn, textTransform: 'none',fontFamily: `${MONTSERRAT_THIN.fontFamily}, Arial, sans-serif`}}>
                  {configJSON.womenText}
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>

            <Grid item lg={6} md={4} sm={4} xs={12} style={webStyle.marginB}>
              {this.renderInputField(
                configJSON.productNameLabel,
                configJSON.productPlaceholder,
                this.state.productName,
                'Product Name',
                this.handleProductName,
                'productNameTest'
              )}
            </Grid>

            <Grid item lg={12} md={6} sm={6} xs={12} style={webStyle.marginB}>
              <Grid container spacing={2}>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <div className="section-1">
                    {this.renderSelectField({
                      selectProps: {
                        label: configJSON.categoryLabel,
                        value: this.state.category,       
                        onChange: this.handleCategory,    
                          name: 'Category',                 
                      },
                      dropdownProps: {
                        openDropdown: this.state.categoryDropdown,   
                        dropdownClose: this.handleCategoryClose,      
                        dropdownOpen: this.handleCategoryOpen,       
                      },
                      selectOptions: this.state.categoryData,                    
                      handleIconClick: this.handleCategoryDropdown,    
                      testId: 'categoryTestId',
                      useNameAsValue: false,
                      disabled: false                           
                    })}
                  </div>
                </Grid>

                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <div className="section-2">            
                    {this.renderSelectField({
                      selectProps: {
                        label: configJSON.subCategoryLabel, 
                        value: this.state.subCategory,       
                        onChange: this.handleSubCategory,    
                        name: 'SubCategory',                 
                      },
                      dropdownProps: {
                        openDropdown: this.state.subCategoryDropdown,   
                        dropdownClose: this.handleSubcategoryClose,      
                        dropdownOpen: this.handleSubcategoryOpen,      
                      },
                      selectOptions: this.state.subCategoryData,                    
                      handleIconClick: this.handleSubCategoryDropdown,
                      testId: 'subCategoryTestId',
                      useNameAsValue: false ,
                      disabled: !this.state.category                       
                    })}
                  </div>
                </Grid>
              </Grid>
            </Grid> 
            {this.brandSearch('brandSearchTestId')}
            <Grid item lg={12} md={6} sm={6} xs={12} style={webStyle.marginB}>
              <Grid container spacing={2}>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <div className="section-1">
                    {this.renderSelectField({
                      selectProps: {
                        label: configJSON.conditionLabel,   
                        value: this.state.condition,         
                        onChange: this.handleCondition,      
                        name: 'Condition',                   
                      },
                      dropdownProps: {
                        openDropdown: this.state.conditionDropdown,  
                        dropdownClose: this.handleConditionClose,    
                        dropdownOpen: this.handleConditionOpen,    
                      },
                      selectOptions: this.state.conditionData,                    
                      handleIconClick: this.handleConditionDropdown,   
                      testId: 'conditionTestId',
                      useNameAsValue: true,
                      disabled: false                                
                    })}
                  </div>
                </Grid>

                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <div className="section-2">
                    {this.renderSelectField({
                      selectProps: {
                        label: configJSON.materialLabel,    
                        value: this.state.material,         
                        onChange: this.handleMaterial,      
                        name: 'Material',                   
                      },
                      dropdownProps: {
                        openDropdown: this.state.materialDropdown,  
                        dropdownClose: this.handleMaterialClose,    
                        dropdownOpen: this.handleMaterialOpen,     
                      },
                      selectOptions: this.state.materialData,                    
                      handleIconClick: this.handleMaterialDropdown,  
                      testId: 'materialTestId',
                      useNameAsValue: true,
                      disabled: false                           
                    })}
                  </div>
                </Grid>
              </Grid>
            </Grid>    

            <Grid item lg={12} md={6} sm={6} xs={12} style={webStyle.marginB}>
              <Grid container spacing={2}>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <div className="section-1">
                    {this.renderSelectField({
                      selectProps: {
                        label: configJSON.useLabel,    
                        value: this.state.use,         
                        onChange: this.handleUse,      
                        name: 'Use',                   
                      },
                      dropdownProps: {
                        openDropdown: this.state.useDropdown,  
                        dropdownClose: this.handleUseClose,    
                        dropdownOpen: this.handleUseOpen,    
                      },
                      selectOptions: this.state.useData,                    
                      handleIconClick: this.handleUseDropdown,        
                      testId: 'useTestId',
                      useNameAsValue: true,
                      disabled: false                               
                    })}
                  </div>
                </Grid>

                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <div className="section-2">
                  <InputLabel className="montserratMediumGoogle">{configJSON.waterResistance}</InputLabel>
                    <FormControl fullWidth sx={webStyle.selectField}>
                    <Select
                        data-testid='waterResistanceTestId'
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        value={this.state.waterResistance}
                        onChange={this.handleWaterResistance}
                        open={this.state.waterResistanceDropdown}
                        onClose={this.handleWaterResistanceClose}
                        onOpen={this.handleWaterResistanceOpen}
                        name='WaterResistance'
                        displayEmpty
                        MenuProps={{
                          PaperProps: {
                            style: {
                              border: '1px solid #53503F',
                              minWidth: '200px',
                              width: '100px',
                              height: '180px',
                            },
                          },
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'right',
                          },
                          transformOrigin: {
                            vertical: 'top',
                            horizontal: 'right',
                          },
                        }}
                        IconComponent={() => 
                          <img 
                            alt="arrow" 
                            src={downArrow}
                            style={{ padding: '10px' }}
                            data-testid="dropDownClickTest"
                            onClick={this.handleWaterResistanceDropdown}
                          />
                        }
                        sx={{
                          ...webStyle.locationDropdown,
                          '& .MuiSelect-select': {
                            color: '#53503F',
                            fontFamily: `${MONTSERRAT_THIN.fontFamily}, Arial, sans-serif`,
                          },
                        }}
                      >
                        <MenuItem value="" 
                        sx={{
                          fontFamily: 'Montserrat, sans-serif',
                          '&.Mui-selected:hover': {
                            backgroundColor: '#5b5b5b',
                          },
                          '&.Mui-selected': {
                            backgroundColor: '#80806e',  
                            color: '#fff',
                          },
                          '&:hover': {
                            backgroundColor: '#e0e0e0',
                          },
                          }}
                        >
                          <em style={webStyle.selectPlaceholder}>Select</em>
                        </MenuItem>
                        {['Yes','No'].map((option) => (
                          <MenuItem 
                            key={option}
                            value={option}
                            sx={{
                              '&.Mui-selected': {
                                fontWeight: '400 !important',
                                backgroundColor: '#53503F', 
                                color: '#ffffff',
                                fontFamily: `${MONTSERRAT_THIN.fontFamily}, Arial, sans-serif`,
                              },
                              '&:hover': {
                                backgroundColor: '#53503F',
                                color: '#ffffff',
                              },
                            }}
                          >
                            {option}
                          </MenuItem>
                        ))}
                        <MenuItem>
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </Grid>
              </Grid>
            </Grid>   

            <Grid item lg={12} md={6} sm={6} xs={12} style={webStyle.marginB}>
              <InputLabel className="montserratMediumGoogle">{configJSON.description}</InputLabel>
              <TextField
                data-testid="descriptionTestId"
                name="Description"
                id="description"
                placeholder="Write here.."
                multiline
                value={this.state.description}
                onChange={this.handleDescription}
                rows={3}
                sx={{
                  width: '100%',
                  backgroundColor: '#ffffff',
                  borderRadius: '4px',
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: 'none', 
                    },
                    '&.Mui-focused fieldset': {
                      border: 'none', 
                    },
                  },
                  '& .MuiInputBase-input::placeholder': {
                    fontFamily: `${MONTSERRAT_THIN.fontFamily}, Arial, sans-serif`,
                    color: '#53503F'
                  },
                  '& .MuiInputBase-input': {
                    fontFamily: `${MONTSERRAT_THIN.fontFamily}, Arial, sans-serif`,
                    color: '#53503F'
                  },
                }}
                className="custom-placeholder"
              />
            </Grid>

            <Grid item lg={12} md={6} sm={6} xs={12} style={webStyle.marginB}>
              <Grid container spacing={2}>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <div className="section-1">
                  {this.renderInputField(
                    configJSON.modalNumber,
                    configJSON.productPlaceholder,
                    this.state.modalNumber,
                    'Modal Number',
                    this.handleModalNumber,
                    'ModalNumberTestId'
                  )}
                  </div>
                </Grid>

                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <div className="section-2">   
                  <InputLabel className="montserratMediumGoogle">{configJSON.yearOfProduction}</InputLabel>
                  <FormControl fullWidth sx={webStyle.selectField}>
                    <Select
                      data-testid='yearOfProductionTestId'
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={this.state.yearOfProduction}
                      onChange={this.handleYearOfProduction}
                      open={this.state.yearOfProductionDropdown}
                      onClose={this.handleYearProductionClose}
                      onOpen={this.handleYearProductionOpen}
                      name='yearOfProduction'
                      displayEmpty
                      MenuProps={{
                        PaperProps: {
                          style: {
                            border: '1px solid #53503F',
                            minWidth: '200px',
                            width: '100px',
                            height: '180px',
                          },
                        },
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'right',
                        },
                        transformOrigin: {
                          vertical: 'top',
                          horizontal: 'right',
                        },
                      }}
                      IconComponent={() => 
                        <img 
                          alt="arrow" 
                          src={downArrow}
                          style={{ padding: '10px' }}
                          data-testid="dropDownClickTest"
                          onClick={this.handleYearProductionDropdown}
                        />
                      }
                      sx={{
                        ...webStyle.locationDropdown,
                        '& .MuiSelect-select': {
                          color: '#53503F',
                          fontFamily: `${MONTSERRAT_THIN.fontFamily}, Arial, sans-serif`,
                        },
                      }}
                    >
                      <MenuItem value="" 
                      sx={{
                        fontFamily: 'Montserrat, sans-serif',
                        '&.Mui-selected:hover': {
                          backgroundColor: '#5b5b5b',
                        },
                        '&.Mui-selected': {
                          backgroundColor: '#80806e',  
                          color: '#fff',
                        },
                        '&:hover': {
                          backgroundColor: '#e0e0e0',
                        },
                        }}
                      >
                        <em style={webStyle.selectPlaceholder}>Select</em>
                      </MenuItem>
                      
                      {this.state.productionYear.map((year) => (
                        <MenuItem 
                          key={year}
                          value={year}
                          sx={{
                            '&.Mui-selected': {
                              fontWeight: '400 !important',
                              backgroundColor: '#53503F', 
                              color: '#ffffff',
                              fontFamily: `${MONTSERRAT_THIN.fontFamily}, Arial, sans-serif`,
                            },
                            '&:hover': {
                              backgroundColor: '#53503F',
                              color: '#ffffff',
                            },
                          }}
                        >
                          {year}
                        </MenuItem>
                      ))}
                      <MenuItem>
                      </MenuItem>
                    </Select>
                  </FormControl>      
                  </div>
                </Grid>
              </Grid>
            </Grid>  

            <Grid item lg={12} md={6} sm={6} xs={12} style={webStyle.marginB}>
              <div className="section-2">
                {this.renderSelectField({
                  selectProps: {
                    label: configJSON.packages,         
                    value: this.state.packages,         
                    onChange: this.handlePackages,     
                    name: 'packages',                  
                  },
                  dropdownProps: {
                    openDropdown: this.state.packagesDropdown,  
                    dropdownClose: this.handlePackagesClose,    
                    dropdownOpen: this.handlePackagesOpen,      
                  },
                  selectOptions: this.state.packagesData,                              
                  handleIconClick: this.handlePackagesDropdown,              
                  testId: 'packagesTestId',
                  useNameAsValue: true,
                  disabled: false
                })}
              </div>
            </Grid>

            <Grid item lg={12} md={6} sm={6} xs={12} style={webStyle.marginB}>
              <Box sx={webStyle.dustBag}>
                <Checkbox 
                  sx={{
                    "&.Mui-checked": {
                      color: '#53503F'
                  }}} 
                  checkedIcon={<img src={checkedBox} alt='checkedBox'/>}
                  icon={<img src={checkBox} alt='checkbox' />}
                  value={this.state.dustBag} 
                  onChange={this.handleDustBag}
                  name="dustBag"
                  data-testid="dustBagTestId"
                  />
                <InputLabel className="montserratMediumGoogle">{configJSON.dustBag}</InputLabel>
              </Box>
            </Grid>

            <Grid item lg={12} md={6} sm={6} xs={12} style={webStyle.marginB}>
              <Box sx={webStyle.variantBtn}>
                <InputLabel className="montserratMediumGoogle">{configJSON.productVariant}</InputLabel>
                <Button 
                className="montserratLightGoogle"
                sx={{
                  ...webStyle.btn, 
                  '&.Mui-disabled': {
                    backgroundColor: '#53503F', 
                    color: '#EDE6DC', 
                    cursor: 'not-allowed',
                    opacity: 0.5,
                    fontSize: '18px'
                  },
                }}>
                  <img src={addIcon} alt='addIcon' style={webStyle.addIcon}/>
                  <Typography
                  className='montserratFont' 
                  sx={webStyle.addVariant}
                  onClick={this.handleVariantPopupOpen}
                  >Add Variant</Typography>
                </Button>
              </Box>
            </Grid>

            <Grid item lg={12} md={6} sm={6} xs={12} style={webStyle.marginB}>
              {this.state.variants.map((data, index) => 
                this.productBox(
                  data.id,
                  addIcon,
                  data.price,
                  data.catalogue_variant_color_id,
                  data.catalogue_variant_size_id,
                  'productTestId'
                )
              )}
            </Grid>
            
            <Grid item lg={12} md={6} sm={6} xs={12} style={webStyle.marginB}>
              <InputLabel className="montserratMediumGoogle">{configJSON.certificate}</InputLabel>
              <InputLabel className="montserratMediumGoogle">
                {configJSON.proof} <span className="montserratFont" style={webStyle.optional}>{configJSON.optional}</span>
              </InputLabel>
              <Box sx={webStyle.uploadBoxMain}>
                {this.uploadDocument()}
              </Box>
            </Grid>
              
            <Grid item lg={12} md={6} sm={6} xs={12} style={webStyle.marginB}>
              <Box display='flex' justifyContent='space-between'>
                <Box>
                  <Button 
                    variant="contained" 
                    className="montserratFont"
                    sx={{
                      ...webStyle.btnMain, 
                      fontWeight: 600,
                      '&.Mui-disabled': {
                        backgroundColor: '#53503F', 
                        color: '#EDE6DC', 
                        cursor: 'not-allowed',
                        opacity: 0.5,
                        fontSize: '18px'
                      },
                    }}
                  >
                    Discard
                  </Button>
                </Box>
                <Box style={webStyle.submitBtnMain}>
                  <Button 
                    variant="contained" 
                    className="montserratFont"
                    sx={{
                      ...webStyle.btnMain, 
                      fontWeight: 600,
                      '&.Mui-disabled': {
                        backgroundColor: '#53503F', 
                        color: '#EDE6DC', 
                        cursor: 'not-allowed',
                        opacity: 0.5,
                        fontSize: '18px'
                      },
                    }}
                    data-testid="saveTestId"
                    onClick={(event) => this.handleAddProduct(event, 'Draft')}
                  >
                    {configJSON.saveText}
                  </Button>
                  <Button 
                  variant="contained" 
                  className="montserratFont"
                  sx={{
                    ...webStyle.btnMain, 
                    fontWeight: 600,
                    backgroundColor: 'rgba(83, 80, 63, 1)',
                    color: '#ffffff',
                    '&.Mui-disabled': {
                      backgroundColor: '#53503F', 
                      color: '#EDE6DC', 
                      cursor: 'not-allowed',
                      opacity: 0.5,
                      fontSize: '18px'
                    },
                  }}
                  onClick={(event) => this.handleAddProduct(event,'Pending')}
                  data-testid="addProductTestId"
                  >
                    {configJSON.addProductText}
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Box>
        </Box>
        </>
      // Customizable Area End
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start
const closeIconStyle = ({
  display: 'flex',
  justifyContent: 'flex-end'
})

const webStyle = {
  variantDetailHead: {
    fontWeight: 600,
    fontSize: '24px',
    margin: '0px 0px 30px 0px',
    textAlign: 'center'
  },
  dialogBoxStyle: {
    padding: 2, 
    overflow: 'scroll',
    backgroundColor: 'rgba(239, 238, 233, 1)'
  },
  variantMain: {
    width: '750px',
    backgroundColor: 'rgba(239, 238, 233, 1)',
  },
  submitBtnMain: {
    width: '19%',
    display: 'flex',
    justifyContent: 'space-between'
  },
  saveColor: {
    backgroundColor: 'rgba(224, 223, 216, 1)',
    color: '#000000'
  },
  btnMain: {
    backgroundColor: 'transparent',
    color: 'rgba(83, 80, 63, 1)',
    border: '1px solid rgba(83, 80, 63, 1)',
    marginTop: '10px',
    borderRadius: '4px',
    textTransform: 'none',
    '&:hover': {
      color: '#ffffff',
      backgroundColor: '#403D2F',
    },
    '.MuiButtonBase-root-MuiButton-root': {
      padding: '100px'
    }
  },
  uploadNote: {
    fontWeight: 400,
    margin: '5px 0px 10px 0px'
  },
  uploadBoxMain: {
    padding: '20px',
    borderRadius: '4px',
    border: '1px solid rgba(83, 80, 63, 1)'
  },
  uploadIconStyle: {
    marginLeft: '8px',
    margin: '12px 0px'
  },
  imageUploaded: {
    height: '100px',
    width: '100px',
  },
  closeIcon: {
    position: 'absolute',
    right: '0px',
    top: '0px'
  },
  embed: {
    width: '100px',
    height: '100px'
  },
  previewDoc: {
    border: '1px solid rgba(83, 80, 63, 1)',
    position: "relative",
    padding: '15px',
    backgroundColor: '#fff'
  },
  imageSupport: {
    margin: '10px 0px',
    fontSize: '12px'
  },
  dragText: {
    fontSize: '14px'
  },
  optional: {
    fontWeight: 400
  },
  addVariant: {
    fontWeight: 500,
  },
  productMain: {
    display: 'flex',
    alignItems: 'center',
    padding: '0px 20px'
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px',
    border: '1px solid rgba(83, 80, 63, 1)',
    backgroundColor: '#ffffff',
    borderRadius: '8px',
    marginBottom: '16px',
  },
  leftSide: {
    display: 'flex',
    alignItems: 'center',
  },
  rightSide: {
    display: 'flex',
    alignItems: 'center',
  },
  serialNumber: {
    marginRight: '8px', 
    fontWeight: 500,
    color: 'rgba(83, 80, 63, 1)'
  },
  productImageStyle: {
    width: '50px',
    height: '50px',
    objectFit: 'cover',
    marginRight: '8px',
    borderRadius: '2px',
    border: '1px solid rgba(83, 80, 63, 1)'
  },
  price: {
    marginRight: '16px',
    fontWeight: 500,
    color: 'rgba(83, 80, 63, 1)'
  },
  colorBox: {
    display: 'inline-block',
    width: '20px',
    height: '20px',
    marginRight: '8px',
    borderRadius: '50%',
  },
  colorText: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '16px',
    padding: '0px 20px',
    fontWeight: 500,
    color: 'rgba(83, 80, 63, 1)'
  },
  iconButton: {
    marginLeft: '8px',
  },
  iconImage: {
    width: '20px',
    height: '20px',
  },
  addIcon: {
    padding: '0px 5px'
  },
  variantBtn: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  btn: {
    backgroundColor: '#53503F',
    color: '#fff',
    borderRadius: '8px',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#403D2F',
      boxShadow: 'none',
    },
    '.MuiButtonBase-root-MuiButton-root': {
      padding: '100px'
    }
  },
  dustBag: {
    display: 'flex',
    alignItems: 'center'
  },
  marginB: {
    marginBottom: '10px'
  },
  inputField: {
    borderRadius: '4px',
    marginTop: '5px',
    backgroundColor: '#fff',
    marginBottom: '10px',
    border: 'none',
  },
  sellAnItemMain: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: '30px',
    backgroundColor: 'rgb(237, 230, 220)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  sellAnItem: {
    textAlign: 'center'
  },
  sellItemText: {
    fontSize: '30px',
    fontWeight: 600,
    color: 'rgba(83, 80, 63, 1)'
  },
  addProductText: {
    fontSize: '18px',
    fontWeight: 400,
    color: 'rgba(83, 80, 63, 1)'
  },
  sellerForm: {
    border: '1px solid rgba(83, 80, 63, 1)',
    margin: '20px 0px',
    borderRadius: '4px',
    backgroundColor: 'rgba(239, 238, 233, 1)',
    width: '75%',
    padding: '40px'
  },
  toggleMain: {
    gap: '15px',
    marginTop: '5px',
    marginBottom: '10px',
    width: '30%'
  },
  toggleBtn: {
    borderRadius: '3px !important',
    textDecoration: 'none',
    border: '2px solid #53503F !important', 
    color: '#53503F', 
    '&.Mui-selected': {
      backgroundColor: '#53503F', 
      color: '#fff',
    },
    '&.Mui-selected:hover': {
      backgroundColor: '#53503F',
    },
  },
  lightLabel: {
    padding: '10px 0px'
  },
  selectField: {
    marginTop: '5px',
    backgroundColor: '#f0f0f0', 
    marginBottom: '10px',
    '&:focus': {
        backgroundColor: '#f0f0f0',
    },
  },
  locationDropdown: {
    backgroundColor: 'white',  
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none', 
    },
  },
  selectPlaceholder: {
    color: '#B0B0B0',
    fontSize: 'inherit',
    display: 'block',
    fontStyle: 'normal',
  },
  productContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    width: "100%",
    backgroundColor: "white",
    marginTop: "20px",
  },
  productBox: {
    height: 250,
    width: "49%",
    marginRight: "5px",
    flexDirection: "column",
  },
  ImgContainer: {
    height: 150,
  },
  productImg: {
    width: "100%",
    height: "100%",
  },
  detailContent: {
    display: "flex",
    flexDirection: "column",
  },
};
// Customizable Area End
