import React from "react";

// Customizable Area Start
import EmailRegistrationSellerForm2Controller, {
    Props,
    configJSON
} from "./EmailRegistrationSellerForm2Controller.web";
import { uploadIcon, rightArrowIcon, closeIcon, downArrow } from "./assets";
import Select from '@mui/material/Select';
import {Box, Grid, InputLabel, TextField, Button, Typography, Divider, Checkbox} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import { MONTSERRAT_THIN } from '../../../components/src/fontConstants.web';
import MenuItem from '@mui/material/MenuItem';
// Customizable Area End

// Customizable Area Start

// Customizable Area End

export default class EmailRegistrationSellerForm2 extends EmailRegistrationSellerForm2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderInputField(
    label: string, 
    placeholder: string, 
    value: string | undefined, 
    name: string, 
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void, 
    dataTestId: string,
  ) {
    return (
      <Grid item lg={6} md={4} sm={4} xs={12}>
        <InputLabel className="montserratMediumGoogle">{label}</InputLabel>
        <TextField
          sx={{
            '& .MuiOutlinedInput-root': {
              '&.Mui-focused fieldset': {
                border: '1px solid #53503F',
              },
            },
            "& fieldset": { border: 'none' },
            '& .MuiInputBase-input::placeholder': {
              fontFamily: `${MONTSERRAT_THIN.fontFamily}, Arial, sans-serif`,
              color: '#53503F',
            },
            '& .MuiInputBase-input': {
              color: '#53503F',
              fontFamily: `${MONTSERRAT_THIN.fontFamily}, Arial, sans-serif`
            },
          }}
          fullWidth
          name={name}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          data-testid={dataTestId}
          style={styles.inputField}
        />
      </Grid>
    );
  }
  // Customizable Area End

  render() {
     // Customizable Area Start
     const { selectedFiles, errorMessage, previews } = this.state;
      // Customizable Area End
    return (
    // Customizable Area Start
      //Merge Engine DefaultContainer
      <>      
        <Box sx={styles.root}>
          <Box style={styles.welcome}>
            <label style={styles.welcomeText} className="montserratFont">Create Account</label>
          </Box>
          <Box style={styles.formMain}>
            <form>
              <Grid container spacing={2}>
              {this.renderInputField(
                configJSON.addressLine1Label,
                configJSON.addresssPlaceholder,
                this.state.addresssLine1,
                'addressLine1',
                this.handleAddresssLine1,
                'addressLine1TestId',
              )}
              {this.renderInputField(
                configJSON.addressLine2Label,
                configJSON.addresssPlaceholder,
                this.state.addresssLine2,
                'addressLine2',
                this.handleAddresssLine2,
                'addressLine2TestId',
              )}
              <Grid item lg={6} md={4} sm={4} xs={12}>
                <InputLabel className="montserratMediumGoogle">{configJSON.cityLabel}</InputLabel>
                <FormControl fullWidth sx={styles.selectField}>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={this.state.city}
                    displayEmpty
                    onChange={this.handleCityChange}
                    open={this.state.openDropdown} 
                    onClose={() => this.setState({ openDropdown: false })}
                    onOpen={() => this.setState({ openDropdown: true })}
                    data-testid="cityTestId"
                    sx={{
                      ...styles.locationDropdown,
                      '& .MuiSelect-select': {
                        color: '#53503F',
                        fontFamily: `${MONTSERRAT_THIN.fontFamily}, Arial, sans-serif`
                      },
                    }}
                    name="city"
                    MenuProps={{
                      PaperProps: {
                        style: {
                          border: '1px solid #53503F',
                          minWidth: '200px',
                          width: '100px',
                          height: '180px',
                        },
                      },
                      anchorOrigin: {
                        vertical: 'bottom', 
                        horizontal: 'right',
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                      },
                    }}
                    IconComponent={() => 
                      <img 
                        src={downArrow} 
                        alt="arrow" 
                        style={{padding: '10px'}}
                        data-testid="dropDownClickTest"
                        onClick={this.handleIconClick}
                      />}
                  >
                    <MenuItem value="" 
                    sx={{
                    fontFamily: 'Montserrat, sans-serif',
                    '&.Mui-selected': {
                      backgroundColor: '#80806e',  
                      color: '#fff',
                    },
                    '&.Mui-selected:hover': {
                      backgroundColor: '#5b5b5b',
                    },
                    '&:hover': {
                      backgroundColor: '#e0e0e0',
                    },
                    }}>
                      <em style={styles.selectPlaceholder}>Select</em>
                    </MenuItem>
                    {Array.isArray(this.state.cityData) && this.state.cityData.map((city) => 
                      <MenuItem 
                      value={city}
                      sx={{
                        '&.Mui-selected': {
                          fontWeight: '400 !important',
                          backgroundColor: '#53503F', 
                          color: '#ffffff',
                          fontFamily: `${MONTSERRAT_THIN.fontFamily}, Arial, sans-serif`
                        },
                        '&:hover': {
                          backgroundColor: '#53503F',
                          color: '#ffffff'
                        },
                      }}
                      >{city}</MenuItem>
                    )}
                  </Select>
                </FormControl>
              </Grid>
              {this.renderInputField(
                configJSON.comRegistrationLabel,
                configJSON.comRegistrationPlaceholder,
                this.state.comRegistrationNum,
                'comRegistration',
                this.handleComRegistrationNum,
                'comRegistrationTestId',
              )}
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <InputLabel style={{paddingBottom: '10px'}} className="montserratMediumGoogle">{configJSON.uploadDocumentLabel}</InputLabel>
                <Box
                  border="2px dashed #53503F"
                  p={2}
                  onDrop={this.handleDrop}
                  textAlign="center"
                  onDragOver={this.handleDragOver}
                  sx={{
                    display: 'flex',
                    margin: 'auto',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    backgroundColor: 'rgba(83, 80, 63, 0.1)'
                  }}
                  data-testid="dropTestId"
                  data-name="dropTest"
                >
                  <Box><img src={uploadIcon} alt="uploadIcon"/></Box> 
                  <p style={{...styles.dragText,fontFamily: `${MONTSERRAT_THIN.fontFamily}, Arial, sans-serif`,color:'#53503F',marginBottom: '0px',fontWeight: 400}}>Drag & Drop here</p>
                  <Divider style={{ width: '50%', margin: 'auto',padding: '10px',fontFamily: `${MONTSERRAT_THIN.fontFamily}, Arial, sans-serif`,color:'#53503F',fontWeight: 400 }}>OR</Divider>
                  <Box sx={{width: '100%'}}>
                    <Button
                      variant="contained"
                      className="montserratMediumGoogle"
                      component="label"
                      sx={{
                        color: 'rgba(255, 255, 255, 1)',
                        backgroundColor: '#53503F',
                        width: '150px',
                        fontWeight: 400,
                        textTransform: 'none',
                        '&:hover': { backgroundColor: '#53503F' },
                      }}
                    >
                      Browse files 
                      <img src={rightArrowIcon} alt='rightArrow'/>
                      <input
                        type="file"
                        hidden
                        multiple
                        onChange={this.handleFileChange}
                        accept=".jpg, .png, .pdf"
                        name='imageFile'
                        data-testid="imageTestId"
                      />
                    </Button>
                  </Box>
                  <Typography sx={{...styles.imageSupport ,fontSize: '12px',color:'#53503F',fontFamily: `${MONTSERRAT_THIN.fontFamily}, Arial, sans-serif`}}>
                  Only supports .jpg, .png and .pdf files<br/>
                  file size should be more than 10 kb and not more than 5 MB
                  </Typography>
                </Box>

                {errorMessage && (
                  <Typography color="error" mt={2} sx={{margin: 'auto' }} className="montserratLightError">
                    {errorMessage}
                  </Typography>
                )}

                {this.state.imageUploadBackendError ? 
                  <Typography color="error" mt={2} sx={{margin: 'auto', paddingTop: '15px' }} className="montserratLightError">
                    {this.state.imageUploadBackendError} 
                  </Typography>: null
                }

                {previews.length > 0 && (
                  <>
                    <Box data-name="previewTestId" display="flex" flexWrap="wrap">
                      {previews.map((preview: any, index: any) => {
                        const fileType = selectedFiles[index].type;
                        return (
                          <Box key={index} p={1}>
                            {fileType === 'application/pdf' ? (
                              <>
                              <Box sx={styles.previewDoc}>
                                <embed style={styles.embed} src={preview} width="200px" height="200px" />
                                <Box sx={styles.closeIcon}>
                                  <img onClick={() => this.handleRemoveFile(index)} src={closeIcon} alt='closeIcon'/>
                                </Box>
                              </Box>
                              </>
                            ) : (
                              <>
                              <Box sx={styles.previewDoc}>
                                <img style={styles.imageUploaded} src={preview} alt="preview" width="200px" height="200px" />
                                <Box sx={styles.closeIcon}>
                                  <img onClick={() => this.handleRemoveFile(index)} src={closeIcon} alt='uploadIcon'/>
                                </Box>
                              </Box>
                              </>
                            )}
                          </Box>
                        );
                      })}
                    </Box>
                  </>
                )}
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box sx={styles.termCondition}>
                    <Checkbox 
                    sx={{
                      "&.Mui-checked": {
                        color: '#53503F'
                    }}} 
                    value={this.state.termsCondition} 
                    onChange={this.handleTermsCondition}
                    name="termsCondition"
                    data-testid="termsConditionTestId"
                    />
                    <p className="montserratFont">I agree with <span onClick={this.props.handleTermsModal} style={{cursor:"pointer",...styles.termBold,fontFamily: `${MONTSERRAT_THIN.fontFamily}, Arial, sans-serif`}}>Terms and Conditions</span> & <span onClick={this.props.handleOpenPrivacyModal} style={{cursor:"pointer",...styles.termBold,fontFamily: `${MONTSERRAT_THIN.fontFamily}, Arial, sans-serif`}}>Privacy Policy</span></p>
                </Box>
              </Grid>
              </Grid>
              <Box style={styles.createAccount}>
                <Box style={styles.buttonStyle}>
                  <Button className="montserratLightGoogle" variant="contained" data-testid="cancelBtnTestId" onClick={this.props.handleCancelButton} sx={{...styles.btnCancel, fontSize: '18px !important'}}>Cancel</Button>
                  <Button
                  className="montserratLightGoogle"
                  disabled={this.state.termsCondition === false ||this.state.addresssLine1 === "" || this.state.addresssLine2 === "" || this.state.city === "" || this.state.comRegistrationNum === "" || this.state.previews.length < 1}
                  data-testid='submitTestId' onClick={this.handleSubmit} variant="contained" sx={{...styles.btn, fontSize: '18px !important'}}>
                    Submit
                  </Button>
                </Box>
              </Box>
            </form>
          </Box>
        </Box>
      </>
      //Merge Engine End DefaultContainer
    // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = {
  welcome: {
    paddingBottom: '15px',
    margin: '10px',
  },
  selectPlaceholder: {
    color: '#B0B0B0',
    fontStyle: 'normal',
    fontSize: 'inherit',
    display: 'block'
  },
  previewDoc: {
    position: "relative",
    padding: '15px',
    backgroundColor: '#fff'
  },
  imageSupport: {
    margin: '10px 0px',
    fontSize: '12px'
  },
  dragText: {
    fontSize: '14px'
  },
  dropzone: {
    padding: '10px',
    border: '1px dashed #000000',
  },
  closeIcon: {
    position: 'absolute',
    right: '0px',
    top: '0px'
  },
  embed: {
    width: '100px',
    height: '100px'
  },
  imageUploaded: {
    height: '100px',
    width: '100px',
  },
  inputField: {
    borderRadius: '4px',
    marginTop: '5px',
    backgroundColor: '#ffffff',
    marginBottom: '10px',
    border: 'none',
  },
  formMain: {
    width: '100%'
  },
  passwordField: {
    position: 'relative'
  },
  fieldLabel: {
    fontFamily: "Avenir",
    lineHeight: '24px',
    fontSize: '10px',
    fontWeight: 800,
    textAlign: "left",
    color: '#53503F'
  }, 
  remember: {
    display: 'flex',
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: '16px',
    alignItems: 'center',
    backgroundColor: '#EFEEE9', 
    borderRadius: 8,
  },
  eyeIconMainWithOutError: {
    position: 'absolute',
    bottom: '40%',
    right: '15px',
  },
  eyeIconWithError: {
    position: 'absolute',
    right: '15px',
    bottom: '56%'
  },
  rememberMain: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  createAccount: {
    margin: 'auto',
    width: '100%'
  },
  logo: {
    padding: '10px'
  },
  btn: {
    backgroundColor: '#53503F',
    color: '#ffffff',
    marginTop: '10px',
    paddingBottom: '15px',
    paddingTop: '15px',
    textTransform: 'none',
    width: '195px',
    '&:hover': {
      backgroundColor: '#403D2F',
      boxShadow: 'none',
    },
    '.MuiButtonBase-root-MuiButton-root': {
      padding: '100px'
    }
  },
  btnCancel: {
    backgroundColor: 'transparent',
    color: '#53503F',
    marginTop: '10px',
    paddingBottom: '15px',
    paddingTop: '15px',
    textTransform: 'none',
    border: '1px solid #53503F',
    boxShadow: 'none',
    width: '195px',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: '#403D2F',
      color: '#ffffff'
    },
    '.MuiButtonBase-root-MuiButton-root': {
      padding: '100px'
    }
  },
  buttonStyle: {
    display: 'flex',
    justifyContent: 'center',
    padding: '0px 70px',
    gap: '25px'
  },
  error: {
    margin: '0px',
    fontWeight: 800,
    color: '#ff0000',
    fontSize: "12px",
    paddingBottom: '15px'
  },
  welcomeText: {
    fontSize: '24px',
    fontFamily: "Avenir",
    fontWeight: 600,
    color: '#53503F'
  },
  selectField: {
    marginTop: '5px',
    backgroundColor: '#f0f0f0', 
    marginBottom: '10px',
    '&:focus': {
        backgroundColor: '#f0f0f0',
    },
  },
  radioField: {
    marginBottom: '10px',
    backgroundColor: '#f0f0f0', 
    marginTop: '5px',
    '&:focus': {
        backgroundColor: '#f0f0f0',
    },
  },
  toggleMain: {
    gap: '15px',
    marginTop: '5px',
    marginBottom: '10px'
  },
  toggleBtn: {
    borderRadius: '8px !important',
    color: '#53503F', 
    border: '2px solid #53503F !important', 
    '&.Mui-selected': {
      backgroundColor: '#53503F', 
      color: '#ffffff',
    },
    '&.Mui-selected:hover': {
      backgroundColor: '#53503F',
    },
  },
  termCondition: {
    display: 'flex',
    color: '#53503F'
  },
  termBold: {
    fontWeight: 600,
    textDecoration: 'underline'
  },
  locationDropdown: {
    backgroundColor: '#ffffff',
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
},
  uploadBoxMain: {
    border: '2px solid #53503F',
    borderStyle: 'dashed',
  }
};
// Customizable Area End