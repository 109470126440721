export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const upArrow = require("../assets/up.png");
export const downArrow = require("../assets/down.png");
export const checked = require("../assets/check_box.png");
export const unchecked = require("../assets/blank_check_box.png");
export const Delete = require("../assets/delete.png");
export const watch1 = require("../assets/watch1.png").default;
export const watch2 = require("../assets/watch2.png").default;
export const HeartIconOutlined = require("../assets/borderHeart.png").default;
export const SearchIcon = require("../assets/searchIcon.png").default;
export const rightIcon = require("../assets/rightIcon.png").default;