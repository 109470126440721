import React from "react";
// Customizable Area Start
import {
  Button,
  Box,
  styled,
  Typography,
  Autocomplete,
  TextField,
  Paper,
  Modal ,
  InputAdornment
} from "@mui/material";


import FavoritesController, {
  Props,
configJSON,

} from "./FavoritesController.web";
import { AppHeader } from "../../../components/src/AppHeader.web";
import { Footer } from "../../../components/src/Footer.web";
import FullWidthTabComponent from "../../../components/src/LandingPageTabList";
import {favHeartIcon,noFavouriteHeart,crossIcon,heartIcon} from "./assets"
import Select from "react-select";
import CategoriesWeb from "../../categoriessubcategories/src/Categories.web";
import SearchIcon from "@mui/icons-material/Search";
// Customizable Area End


export default class Favorites extends FavoritesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
 
    handleReturnSelect = () => {
        return (
            <Box className='singleInputParentBox'>

                <ReactSelect

                    options={configJSON.sortList}
                    data-testid="countryCode"
                    data-test-id='selectField'
                    classNamePrefix={'custom-select'}
                    value={this.state.sortBy}
                    onChange={(event) => this.handleSetSort(event)}

                    styles={{

                        menu: (provided) => ({
                            ...provided,
                            border: '1px solid #53503F',
                            boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.15)',
                            zIndex: 10000,
                            borderRadius: '4px',
                            marginTop: '3.93px',
                            overflow: 'hidden',

                        }),
                        option: (provided, state) => ({
                            ...provided,
                            cursor: 'pointer',
                            gap: '8px',
                            boxSizing: 'border-box',
                            backgroundColor: state?.isSelected ? '#53503F' : 'unset',
                            paddingRight: '16px',
                            paddingLeft: '16px',
                            paddingBottom: '12px',
                            paddingTop: '12px',
                            fontWeight: state.isSelected ? 600 : 400,
                            color: state.isSelected ? '#FFFFFF' : '#3C3E49',
                            fontFamily: "'Montserrat', sans-serif",
                            fontSize: '12px',
                            "&:hover": {
                                backgroundColor: state?.isSelected ? '#53503F' : '#E0E0E0',
                            }

                        }),

                    }}
                />

            </Box>
        )
    }
    handleCartRemoveModal = (isOpen: boolean, state: string, heading: string, subHeading: string) => {
        return (
            <Box >
                <Modal
                    open={isOpen}
                    onClose={() => this.setState((prevState) => ({ ...prevState, [state]: false, cartId: null,successMessage:'',isAlreadyAdded:false }))}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    data-test-id={state}
                    sx={{backdropFilter: "blur(5px)"}}
                >
                    <RemoveCartModalBox >
                        <Box data-test-id='crossIconBox2' className="crossIconBox" onClick={() => this.setState((prevState) => ({ ...prevState, [state]: false, cartId: null,successMessage:'' ,isAlreadyAdded:false}))}>
                            <img src={crossIcon} alt="" />
                        </Box>
                        <Typography className="modalHeading">{heading}</Typography>
                        <Typography className={state == 'moveToCartModal' ? "moveToCartSubHeading" : "areYouSureText"}>{subHeading}</Typography>
                        <CancelRemoveButtonBox>
                            {state == 'removeFavModal' && <CancelButton data-test-id='cancelButton' onClick={() => this.setState((prevState) => ({ ...prevState, [state]: false, cartId: null ,successMessage:'' ,isAlreadyAdded:false}))} variant="contained" >{configJSON.cancel}</CancelButton>}
                            {state == 'removeFavModal' && <RemoveButton data-test-id='removeButton' onClick={() => this.handleRemoveFavorite()} variant="contained" >{configJSON.remove}</RemoveButton>}
                            {state == 'moveToCartModal' && <GoToCartButton data-test-id='goToCartButton' onClick={() => this.handleNavigate('ShoppingCart')} variant="contained" >{configJSON.goToCart}</GoToCartButton>}
                        </CancelRemoveButtonBox>
                    </RemoveCartModalBox>
                </Modal>
            </Box>
        )
    }
  
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
     
        <ContainerBox >
            <AppHeader
            closeLoginModal={this.closeLoginModal}
            openLoginModal={this.state.openLoginModal}
            count={this.state.cartData?.length}
            allFilter={this.state.allFilter} 
            handleCloseAllFilter={this.handleCloseAllFilter}
            categoryPageVisibility={this.handleCategoryVisibility}
            handleApplyButton={this.handleApplyButton}
            categoryId={this.state.categoryId}
            genderForFilter={this.state.gender}
            data-test-id='appHeader'
        />
         <FullWidthTabComponent 
            categoriesOnHoverList={this.state.categoriesOnHoverListData}
            getCategoriesOnHover={this.getCategoriesOnHoverData}
            categoriesList={this.state.categoriesArrayData}
            handleCategories={this.handleCategoryData}
            data-test-id='fullWidthTabComponent'
            handleMoveToHome={this.handleCategoryVisibility}
        />
        <div style={{ width: "100%", textAlign: "center" }}>
          <MyTextField
            placeholder="Search for.."
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </div>
     {this.state.showCategoryPage?
          <CategoriesWeb data-test-id='categoriesWeb' navigation={this.props.navigation} id={""} filterData={this.state.filterData} categoryData={this.state.categoryData} passDataToFilter={this.handlePassData} allFilter={this.handleAllFilter} categoryId={this.state.categoryId} categoryName={this.state.categoryName}/> 
 :<>
            <OuterMainBox >
                <EmptyCartMainBox>
                    {this.state.cartDataFav?.length == 0 && <EmptyBagMainBox>
                        <Box className='emptyImageText'>
                            <img className="bagIcon" src={noFavouriteHeart} alt="" />
                            <Box className='emptyTextBox'>
                                <Typography className='emptyText'>{configJSON.noFavouritesText}</Typography>
                                <Typography className='emptyText2'>{configJSON.you_havent_liked_any_items_yet}</Typography>
                                <Button data-test-id='startShopping' className="startShopping" onClick={() => this.handleNavigate('Home')} variant="contained">
                                    {configJSON.backToHome}
                                </Button>
                            </Box>
                        </Box>
                    </EmptyBagMainBox>}
                    <ExploreCardBoxMain>
                        {this.state.cartDataFav?.length > 0 && <Box className='sortAndQuantBox'>
                            <Typography className="exploreMoreText">{configJSON.Favorites} <span className="countText">{this.handleReturnZero(this.state.cartDataFav?.length)}</span></Typography>
                            {this.handleReturnSelect()}
                        </Box>}
                        {this.state.cartDataFav?.length == 0 && <Box className='sortAndQuantBox'>
                            <Typography className="exploreMoreText">{configJSON.you_May_like}</Typography>
                        </Box>}
                        <Box className='emptyCartContainer'>
                        <Box className='emptyCardOuter'>
                            {this.state.cartDataFav?.length > 0 && this.state.cartDataFav?.map((item, index) => (
                                
                                <EmptyCardBox className='cardMain' data-test-id='emptyCardBox' key={index} onClick={() => this.props.navigation.navigate("ProductDescription", { productId: item.attributes.catalogue_id })} >
                                    

                                    <Box className='cardImageWhiteBox'>
                                        <img className="cardImage" src={item.attributes?.image_url} alt="" />
                                        <img className="heartIcon" data-test-id='heartIconFav'
                                            onClick={(event) => {
                                                event.stopPropagation()
                                                this.setState({ removeFavModal: true, cartId: item.id })
                                            }}
                                            src={favHeartIcon} alt="" />
                                        <Box data-test-id='moveToCart' onClick={(event) => {
                                            event.stopPropagation()
                                            this.handleMoveToCart(item.id, item.attributes.catalogue_id)
                                        }} className="hoverBlackBox bigCaslont">{configJSON.moveToCart}</Box>
                                    </Box>

                                    <Box className='cardBottumBox'>
                                        <Typography className="brandText">{item?.attributes?.brand}</Typography>
                                        <Typography className="descText">{item?.attributes?.description}</Typography>
                                        <Typography className="priText">{item?.attributes?.price}</Typography>
                                    </Box>

                                </EmptyCardBox>
                            ))}
                            {this.state.youMayLikeData?.length > 0 && this.state.cartDataFav?.length == 0 && this.state.youMayLikeData?.map((element) => (
                                (element?.catalogues?.length > 0 && element?.catalogues.map((item, index) => (
                                    <EmptyCardBox data-test-id='cardMain' className='cardMainYouLike' key={index} onClick={() => this.props.navigation.navigate("ProductDescription", { productId: item.attributes.catalogue_id })} >

                                        <Box className='cardImageWhiteBox'>
                                            <img className="cardImage" src={item?.attributes?.image_url} alt="" />
                                            <img className="heartIcon" data-test-id='heartIconFav'
                                                onClick={(event) => {
                                                    event.stopPropagation()
                                                    this.handleAddFavorite(item?.id)
                                                }}
                                                src={item?.attributes?.is_favorited ? favHeartIcon : heartIcon} alt="" />
                                        </Box>

                                        <Box className='cardBottumBox'>
                                            <Typography className="brandText">{item?.attributes?.brand}</Typography>
                                            <Typography className="descText">{item?.attributes?.description}</Typography>
                                            <Typography className="priText">{item?.attributes?.price}</Typography>
                                        </Box>

                                    </EmptyCardBox>
                                )))))}
                        </Box>
                        </Box>
                    </ExploreCardBoxMain>

                </EmptyCartMainBox>
            </OuterMainBox>
            {this.state.removeFavModal && this.handleCartRemoveModal(this.state.removeFavModal, 'removeFavModal', configJSON.confirmation, configJSON.are_you_sure_you_want_to_remove_it_from_your_Favorites)}
            {this.state.moveToCartModal && this.handleCartRemoveModal(this.state.moveToCartModal, 'moveToCartModal', this.state.isAlreadyAdded?configJSON.oops:configJSON.success, this.state.successMessage)}
</>}  
            <Footer />
        </ContainerBox>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const ContainerBox = styled(Box)({
    backgroundColor: '#EDE6DC',
})
const OuterMainBox = styled(Box)({
    padding: '41px',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    justifyContent: 'center',
    backgroundColor: '#EDE6DC',
    boxSizing: 'border-box',
    '@media (max-width: 560px) ': {
        padding: '24px',
    }

})
const EmptyCartMainBox = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    gap: '97.23px',

})
const EmptyBagMainBox = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    "& .emptyImageText": {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '30px'
    },
    "& .emptyTextBox": {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '15px'
    },
    "& .emptyText": {
        textAlign: 'center',
        fontFamily: "'Montserrat', sans-serif",
        fontSize: '25px',
        fontWeight: 600,
        color: '#53503F',
    },
    "& .emptyText2": {
        fontFamily: "'Montserrat', sans-serif",
        textAlign: 'center',
        fontSize: '20px',
        color: '#53503F',
        fontWeight: 400,
        maxWidth: '338px'
    },
    "& .startShopping": {
        textTransform: 'none',
        borderRadius: '4px',
        fontFamily: "'Montserrat', sans-serif",
        fontSize: '18px',
        backgroundColor: '#53503F',
        color: '#EFEEE9',
        fontWeight: 600,
        cursor: 'pointer',
        width: '283px',
    },
    "& .startShopping:hover": {
        backgroundColor: '#53503F',
    },
    "@media  (max-width:500px)": {
        '& .startShopping': {
            width: 'unset',
            fontWeight: 500,
            fontSize: '14px',
        },
        "& .emptyText": {
            fontSize: '16px',
        },
        "& .emptyText2": {
            fontSize: '14px',
        },
        "& .bagIcon": {
            height: '100px',
            width: '100px'
        }
    }
})
const ExploreCardBoxMain = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    gap: '28px',
    maxWidth:'1600px',
    margin:'auto',
    "& .countText": {
        fontSize: '28px',
        fontFamily: "'Montserrat', sans-serif",
        textAlign: 'left',
        color: '#53503F',
        fontWeight: 400,
    },
    "& .exploreMoreText": {
        fontFamily: "'Montserrat', sans-serif",
        textAlign: 'left',
        fontSize: '30px',
        color: '#53503F',
        fontWeight: 600,
    },
    "& .emptyCardOuter": {
        display: 'grid',
        gridTemplateColumns: 'repeat(4,1fr)',
        gap: '55px',

    },
    "& .sortAndQuantBox": {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        alignItems: 'center'
    },
    "& .emptyCartContainer":{
        minHeight:'100vh'
    },

    "@media (max-width:1470px)": {
        "& .emptyCardOuter": {
            gap: '25px',
        }

    },
    "@media (max-width:1230px)": {
        "& .emptyCardOuter": {
            gridTemplateColumns: 'repeat(3,1fr)',
        }

    },
    "@media (max-width:935px)": {
        "& .emptyCardOuter": {
            gridTemplateColumns: 'repeat(2,1fr)',
        }

    },

    "@media (max-width:660px)": {
        alignItems:'center',
        "& .emptyCardOuter": {
            gridTemplateColumns: 'repeat(1,1fr)',
            padding: '0 56px 0 56px'
        },
        
    },
    "@media (max-width:600px)": {
        "& .sortAndQuantBox": {
           flexDirection:'column'
        }
    }

})
const EmptyCardBox = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    cursor: 'pointer',
     
    "&:hover": {
        "& .hoverBlackBox": {
            height:'75px',
            visibility: 'visible'
        }
    },
    "& .cardImageWhiteBox": {
        padding: '70px 45px 70px 45px',
        backgroundColor: '#FFFFFF',
        borderRadius: '4px',
        boxSizing: 'border-box',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        position: 'relative',

    },
    "& .hoverBlackBox": {
        height:'0px',
        backgroundColor: '#000000A6',
        borderRadius: '0px 0px 4px 4px',
        boxSizing: 'border-box',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        position: 'absolute',
        bottom: '0px',
        left: '0px',
        right: '0px',
        textAlign: 'center',
        fontSize: '20px',
        color: '#EDE6DC',
        fontWeight: 500,
        cursor: 'pointer',
        transition: 'height 0.4s ease',
        visibility: 'hidden',
        overflow:'hidden'

    },
    "& .cardBottumBox": {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
        justifyContent: 'center',
        gap: '12px',
        padding: '0 0px 20px 0',
        boxSizing: 'border-box',
        maxWidth: '300px'
    },
    "& .cardImage": {
        height: '210px',
        width: "210px",
        objectFit: 'contain'
    },
    "& .heartIcon": {
        position: 'absolute',
        top: '15px',
        right: '15px',
        cursor: "pointer"
    },
    "& .brandText": {
        fontFamily: "'Montserrat', sans-serif",
        textAlign: 'left',
        fontSize: '22px',
        color: '#53503F',
        fontWeight: 600,
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
    },
    "& .descText": {
        fontFamily: "'Montserrat', sans-serif",
        textAlign: 'left',
        fontSize: '16px',
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
        color: '#53503F',
        fontWeight: 400,
    },
    "& .priText": {
        fontFamily: "'Montserrat', sans-serif",
        textAlign: 'left',
        fontSize: '20px',
        color: '#53503F',
        whiteSpace: "nowrap",
        fontWeight: 500,
        overflow: "hidden",
        textOverflow: "ellipsis"
    },
    "@media (max-width:1380px)": {
        "& .cardImageWhiteBox": {
            padding: '50px 25px 50px 25px',
        },

    },

})
const SearchParentBox = styled(Box)({
    padding: '0 41px 0 41px',
    boxSizing: 'border-box',
    "& .SearchChiledBox": {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '30px 0 30px 0',
        boxSizing: 'border-box',
        borderTop: '1px solid #53503F'
    }
})
const CustomPaper = styled(Paper)({
    marginTop: '5px',
    marginBottom: '5px',
    borderRadius: '8px',
    border: '1px solid #53503F',
    color: '#53503F'
});
const AutocompleteComponentSearch = styled(Autocomplete)({
    width: "480px",

    "& .MuiAutocomplete-endAdornment": {
        display: 'none'
    },

    "& .MuiFormControl-marginNormal": {
        marginBottom: "unset",
        marginTop: "unset",
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
        padding: "0px 0px 0px 8px"
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input': {
        padding: "10px 8px 10px 8px",
        marginBottom: '-4px'
    },
    "& .MuiOutlinedInput-root": {
        height: "44px",

        "&:hover fieldset": {
            border: "0.2px solid rgba(83, 80, 63, 1)",
        },
        "&.Mui-focused fieldset": {
            border: "0.2px solid rgba(83, 80, 63, 1)",
        },
    },
    "& .MuiInputBase-root": {
        color: "rgba(137, 135, 123, 1)",
    },
    "& .MuiOutlinedInput-root.Mui-focused.MuiOutlinedInput-notchedOutline": {

        border: "0.2px solid rgba(83, 80, 63, 1)",
    },
    "& .MuiOutlinedInput-notchedOutline": {
        border: "0.2px solid rgba(83, 80, 63, 1)",
        borderRadius: '25px',
        backgroundColor: 'rgba(255, 255, 255, 0.5)'

    },

})
const RemoveCartModalBox = styled(Box)({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%) scale(0.89)',
    width: '600px',
    borderRadius: '20px',
    padding: '68px 49px',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#EFEEE9',
    height: '340px',
    justifyContent: 'center',
    maxHeight: '340px',
    alignItems: 'center',

    "& .areYouSureText": {
        fontSize: '20px',
        fontFamily: "'Montserrat', sans-serif",
        fontWeight: 400,
        color: '#53503F',
        marginTop: '8px',
        maxWidth: '408px',
        textAlign: "center"
    },
    "& .moveToCartSubHeading": {
        fontWeight: 400,
        fontSize: '20px',
        fontFamily: "'Montserrat', sans-serif",
        marginTop: '8px',
        color: '#53503F',
        textAlign: "center",
        maxWidth: '425px',
    },
    "& .crossIconBox": {
        position: 'absolute',
        right: '28px',
        top: '28px',
        cursor: 'pointer'
    },
    "& .modalHeading": {
        fontSize: '22px',
        fontFamily: "'Montserrat', sans-serif",
        fontWeight: 600,
        color: '#53503F',
    },
    "@media  (max-width:550px)": {
        width: '100%',

    },
    '@media (max-width:400px )': {
        padding: '16.21px 25px 39.85px 25px',
        height: '215.57px',
        borderRadius: '4px',
        "& .modalHeading": {
            fontSize: '18px',
        },
        "& .areYouSureText, .moveToCartSubHeading": {
            fontSize: '14px',
        },
        "& .crossIconBox": {
            right: '6px',
            top: '6px',
            "& img": {
                height: '24px',
                width: '24px'
            }
        }
    },
    '@media (max-width:362px )': {
        maxHeight: 'unset',
        height: 'unset',
    },
    '@media (max-width:359px )': {
        width: '100%',
    },
})
const CancelRemoveButtonBox = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    gap: '50px',
    marginTop: '40px',
    '@media (max-width:420px )': {
        gap: '28.45px',
    },

})
const GoToCartButton = styled(Button)({
    width: '360px',
    backgroundColor: '#53503F',
    height: '56px',
    borderRadius: '8px',
    textTransform: 'none',
    fontSize: '18px',
    fontFamily: "'Montserrat', sans-serif",
    fontWeight: 600,
    cursor: 'pointer',
    color: '#EDE6DC',
    "&:hover": { backgroundColor: '#53503F', },
    '@media (max-width:400px )': {
        width: '117px',
        borderRadius: '4px',
        height: '36px',
        fontSize: '12px',
    },
})
const RemoveButton = styled(Button)({
    width: '155px',
    height: '56px',
    backgroundColor: '#53503F',
    textTransform: 'none',
    borderRadius: '4px',
    fontFamily: "'Montserrat', sans-serif",
    fontSize: '18px',
    fontWeight: 600,
    color: '#EDE6DC',
    cursor: 'pointer',
    "&:hover": {
        backgroundColor: '#53503F',
    },
    '@media (max-width:400px )': {
        fontSize: '12px',
        width: '98.27px',
        height: '35.51px',
    },
})
const CancelButton = styled(Button)({
    width: '155px',
    border: '1px solid rgba(83, 80, 63, 1)',
    backgroundColor: 'transparent',
    height: '56px',
    borderRadius: '4px',
    textTransform: 'none',
    fontFamily: "'Montserrat', sans-serif",
    fontWeight: 600,
    fontSize: '18px',
    cursor: 'pointer',
    color: '#53503F',
    "&:hover": {
        backgroundColor: 'transparent',
    },
    '@media (max-width:400px )': {
        fontSize: '12px',
        width: '98.27px',
        height: '35.51px',
    },
})


const ReactSelect = styled(Select)({
    width: '275px',
    outline: 'none',
    height: '44px',
    scrollbarGutter: 'stable',
    backgroundColor: 'transparent',

    '& .custom-select__menu-list': {
        scrollbarWidth: '5px', scrollbarColor: '#53503F transparent',
        direction: 'ltr',
        borderRadius: '8px'
    },
    '& .custom-select__menu-list::-webkit-scrollbar': {
        scrollbarWidth: '5px',
        scrollbarColor: '#53503F transparent',
        direction: 'ltr',
    },
    '& .custom-select__menu-list::-webkit-scrollbar-thumb': {
        scrollbarColor: '#53503F transparent',
        scrollbarWidth: '5px',
        direction: 'ltr',
        borderRadius: '8px'
    },

    '& .custom-select__menu-list:hover': {
        scrollbarWidth: '5px',
        direction: 'ltr',
        scrollbarColor: '#53503F transparent',
        borderRadius: '8px'
    },
    "& .css-1uccc91-singleValue": {
        color: "#53503F",
        fontWeight: 400,
        fontSize: '16px',
        fontFamily: "'Montserrat', sans-serif",
    },
    "& .css-1okebmr-indicatorSeparator": {
        display: 'none'
    },
    "& .css-yk16xz-control": {
        border: '1px solid #53503F !important',
        borderColor: 'none',
        height: '100%',
        backgroundColor: 'transparent !important',
    },
    "& .css-yk16xz-control:hover": {
        border: '1px solid #53503F !important',
        borderColor: 'none'

    },
    "& .css-g1d714-ValueContainer": {
        height: '44px',
        backgroundColor: 'transparent !important',
    },
    "& .css-1pahdxg-control": {
        borderColor: 'transparent !important',
        outline: 'none !important',
        boxShadow: 'none !important',
        border: '1px solid #53503F !important',
        height: '44px',
        minHeight: '44px',
        backgroundColor: 'transparent !important',
    },
    "& .custom-select__control": {
        minHeight: '44px',
        height: '44px',
        "&:before": {
            content: '"Sort By :"',
            display: "block",
            width: 'fit-content',
            color: "#53503F",
            fontWeight: 400,
            fontSize: '16px',
            fontFamily: "'Montserrat', sans-serif",
            paddingLeft: '16px'
        },
    },
    "& .custom-select__value-container": {
        height: '44px',
        padding: '0px'
    },
    "& .css-1wa3eu0-placeholder": {
        display: 'none'
    },
    "& .css-1hb7zxy-IndicatorsContainer": {
        width: '24px',
        display: 'flex',
        justifyContent: "center"
    },
    "& .css-tlfecz-indicatorContainer": {
        padding: '0'
    },
    "& .css-6q0nyr-Svg": {
        color: '#53503F',
        cursor: 'pointer'
    },
    "& .css-26l3qy-menu": {
        fontWeight: 400,
        color: '#3C3E49',
        fontFamily: "'Montserrat', sans-serif",
        fontSize: '12px',
        zIndex: 1000
    },
    "& .css-9gakcf-option": {
        backgroundColor: '#53503F',
        fontWeight: 600,
        color: '#FFFFFF',
        fontFamily: "'Montserrat', sans-serif",
        fontSize: '12px',
    },
    "& span": {
        display: 'none'
    },
    "& svg": {
        cursor: 'pointer',
        position: 'relative',
        right: '16px',
        top: '50%',
        color: '#53503F'
    },

    "@media (max-width: 400px)": {
        minWidth: 'unset'
    },
    "@media (max-width: 302px)": {
        width: '250px'
    }
})
const MyTextField = styled(TextField)(({ theme }) => ({
    width: "480px",
    boxSizing: "border-box",
    height: "44px",
    justifyContent: "center",
    padding: "10px 8px",
    gap: 8,
    borderRadius: "25px",
    borderWidth: "0.2px 0 0 0",
    backgroundColor: "rgba(255, 255, 255, 0.5)",
    margin:"25px",
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "none",
        width: "100%",
      },
    },
    [theme.breakpoints.down('md')]: {
      width: "300px",
    },
    [theme.breakpoints.down('sm')]: {
      width: "100%",
    },
  }));

const styles = {
    errorText: {
        fontWeight: 600,
        fontFamily: "'Montserrat', sans-serif",
        fontSize: '12px',
        color: "#DC2626",
        position: 'absolute',
        lineHeight: 'normal'
    },
    add_address: {
        fontFamily: "'Montserrat', sans-serif",
        fontSize: '24px',
        fontWeight: 600,
        color: '#53503F',
    },
    confirmation: {
        fontSize: '22px',
        fontFamily: "'Montserrat', sans-serif",
        fontWeight: 600,
        color: '#53503F',
    },

    colorBox: {
        height: '12px',
        width: '12px',
        borderRadius: '2px',
        backgroundColor: "blue"
    },
    crossIcon: {
        cursor: 'pointer'
    }
}
// Customizable Area End
