import React from "react";

// Customizable Area Start
import FilterController, {
    Props,
} from "./FilterController.web";
import { Divider,InputAdornment, Drawer, Slider,Typography,TextField,Chip, FormControl, Button, Select, MenuItem, Autocomplete } from '@mui/material';
import { Box } from '@mui/system';
import {SearchIcon} from './assets';
import { MONTSERRAT_THIN } from '../../../components/src/fontConstants.web';

interface Color {
  id: string; 
  name: string;
  color_code: string; 
}
// Customizable Area End

// Customizable Area Start
// Customizable Area End

export default class FilterWeb extends FilterController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  brandSearch(){
    return (
      <Box sx={{ marginBottom: '15px' }}>
        <Typography className="montserratFont" sx={{ fontSize: '14px', fontWeight: 400, marginBottom: '8px', color: '#333' }}>Brand</Typography>
        <FormControl variant="outlined" fullWidth>
          <Autocomplete
            data-testid="brandTestId"
            value={this.state.filteredBrands.find((brand: any) => brand.name === this.state.searchTerm) || null}
            onInputChange={(event, newInputValue) => this.handleSearchChange(newInputValue)}
            options={this.state.filteredBrands}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Search Brands"
                variant="outlined"
                sx={{
                  fontFamily: `${MONTSERRAT_THIN.fontFamily}, Arial, sans-serif`,
                  backgroundColor: '#F9F9F9',
                  borderRadius: '4px',
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '4px',
                    border: 'none',
                    '& .MuiOutlinedInput-notchedOutline': {
                      border: 'none',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      border: 'none',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      border: 'none',
                    },
                  },
                }}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      <img src={SearchIcon} alt='searchIcon'/>
                    </InputAdornment>
                  ),
                  endAdornment: null,
                }}
              />
            )}
            onChange={(event, value) => this.handleSelectBrand(value)}
            noOptionsText="No brands found"
            PaperComponent={({ children }) => (
              <Box
                sx={{
                  backgroundColor: '#ffffff',
                  border: '1px solid rgba(83, 80, 63, 1)',
                  borderRadius: '4px',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', 
                  marginTop: '8px',
                }}
              >
                {children}
              </Box>
            )}
            renderOption={(props, option) => {
              const isSelected = this.state.filteredBrands.find((brand: any) => brand.name === this.state.searchTerm) === option;
              
              const baseStyle = {
                padding: '8px 16px',
                fontSize: '14px',
              };
            
              const selectedStyle = isSelected ? {
                backgroundColor: 'rgba(83, 80, 63, 1)', 
                color: '#ffffff', 
                fontWeight: 600,
              } : {
                backgroundColor: '#FFF', 
                color: '#000000', 
                fontWeight: 400,
              };

              const finalStyle = { ...baseStyle, ...selectedStyle };

              return (
                <li
                  {...props}
                  key={option.id}
                  style={finalStyle}
                >
                  {option.name}
                </li>
              );
            }}

            fullWidth
          />
        </FormControl>
      </Box>
    )
  }

  handleSubCategory(){
    return (
      <Box sx={{ marginBottom: '15px' }}>
        <Typography className="montserratFont" sx={{ fontSize: '14px', fontWeight: 400, marginBottom: '8px', color: '#333' }}>Subcategory</Typography>
        <Box display="flex" flexWrap="wrap" gap={1}>
          {this.state.subCategoryData.map((subCat: any) => (
            <Chip
              className="montserratFont"
              data-testid="subcategoryTestId"
              key={subCat.id}
              label={subCat.name}
              onClick={() => this.handleSubCategorySelect(subCat.id)}
              sx={{
                backgroundColor: this.state.subCategory.includes(subCat.id) ? '#70624D' : '#F5F5F5',
                color: this.state.subCategory.includes(subCat.id) ? '#FFFFFF' : '#333',
                borderRadius: '4px',
                fontWeight: this.state.subCategory.includes(subCat.id) ? 600 : 400,
                padding: '10px 16px',
                height: '40px',
                border: '1px solid rgba(83, 80, 63, 1)',
                fontSize: '12px'
              }}
              clickable
            />
          ))}
        </Box>
      </Box>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { priceRange, year, color, size, searchTerm, filteredBrands } = this.state;

    // Customizable Area End
    return (
    // Customizable Area Start
      //Merge Engine DefaultContainer
      <Drawer
        anchor="left"
        open={true}
        onClose={() => this.props.handleFilterClose()}
        PaperProps={{ sx: { backgroundColor: 'rgba(239, 238, 233, 1)',width: '400px', padding: '10px 25px', borderRadius: '12px', boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)' } }}
        data-testid="filterTestId"
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography className="montserratFont" variant="h6" sx={{ color: 'rgba(83, 80, 63, 1)',fontWeight: 600, fontSize: '16px'}}>
            Filters
          </Typography>
          <Button
            color="error"
            className="montserratFont"
            data-testid="clearBtnTestId"
            onClick={this.handleClearAllBtn}
            sx={{ fontSize: '14px', fontWeight: 600, color: 'rgba(255, 61, 61, 1)', textTransform: 'capitalize' }}
          >
            Clear All
          </Button>
        </Box>
        <Divider sx={{margin: '15px 0px !important'}}/>
      <Box>
        <Typography className="montserratFont" sx={{ fontSize: "14px", fontWeight: 400, marginBottom: "8px", color: "#53503F" }}>
          Price Range
        </Typography>
        <Slider
          value={priceRange}
          step={1}
          min={this.state.minPriceData}
          max={this.state.maxPriceData}
          onChange={this.handlePriceChange}
          valueLabelDisplay="on"
          valueLabelFormat={this.renderValueLabel}
          data-testid="priceSliderTestId"
          sx={{
            margin: '30px 0px',
            color: "#70624D",
            height: 4,
            fontFamily: `${MONTSERRAT_THIN.fontFamily}, Arial, sans-serif`,
            "& .MuiSlider-thumb": {
              backgroundColor: "#70624D",
              border: "2px solid #FFFFFF",
              width: 20, 
              height: 20,
              "&:hover": {
                boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.25)", 
              },
            },
            "& .MuiSlider-rail": {
              backgroundColor: "#ffffff",
              opacity: 1,
            },
            "& .MuiSlider-track": {
              backgroundColor: "#70624D",
            },
            "& .MuiSlider-valueLabel": {
              fontFamily: `${MONTSERRAT_THIN.fontFamily}, Arial, sans-serif`,
              marginLeft: '10px !important', 
              borderRadius: '4px',
              backgroundColor: "#ffffff",
              border: '1px solid rgba(83, 80, 63, 1)',
              color: "#70624D",
              fontSize: "12px",
              fontWeight: 600,
              top: -6, 
              "&:before": {
                display: "none"
              },
            },
          }}
        />
      </Box>
      
      {this.brandSearch()}
      {this.handleSubCategory()}    

    <FormControl fullWidth margin="normal" sx={{ marginBottom: '15px' }}>
      <Typography className="montserratFont" sx={{ fontSize: '14px', fontWeight: 400, marginBottom: '8px', color: '#333' }}>Year of production</Typography>
      <Select
        id="demo-simple-select"
        labelId="demo-simple-select-label"
        displayEmpty
        sx={{
          fontFamily: `${MONTSERRAT_THIN.fontFamily}, Arial, sans-serif`,
          height: '56px',
          backgroundColor: '#F9F9F9',
          borderRadius: '4px',
          '& .MuiSelect-select': {
            padding: '10px',
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              border: 'none',
            },
            '&.Mui-focused fieldset': {
              border: 'none', 
            },
          },
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none'
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            border: 'none', 
          },
        }}
        data-testid="yearTestId"
        value={year}
        onChange={(e) => this.setState({ year: e.target.value })}
      >
        <MenuItem 
        value="" 
        sx={{
          fontFamily: `${MONTSERRAT_THIN.fontFamily}, Arial, sans-serif`,
          '&.Mui-selected:hover': {
            backgroundColor: '#5b5b5b',
          },
          '&.Mui-selected': {
            color: '#fff',
            backgroundColor: '#80806e',  
          },
          '&:hover': {
            backgroundColor: '#e0e0e0',
          },
          }}>
            <em style={webStyle.selectPlaceholder}>Select year</em>
          </MenuItem>
        {this.state.productionYearData.map((year: any) => (
          <MenuItem 
          key={year.product_year_production} 
          value={year.product_year_production}
          sx={{
            '&.Mui-selected': {
              backgroundColor: '#53503F', 
              color: '#ffffff',
              fontFamily: `${MONTSERRAT_THIN.fontFamily}, Arial, sans-serif`
            }
          }}
          >
            {year.product_year_production}
          </MenuItem>
        ))}
      </Select>
    </FormControl>

    <Box sx={{ marginBottom: '15px' }}>
      <Typography className="montserratFont" sx={{ fontSize: '14px', fontWeight: 400, marginBottom: '8px', color: '#333' }}>Date Posted</Typography>
      <Box >
        <Button
          data-testid="recentPostBtn"
          variant="outlined"
          onClick={() => this.setState({ datePosted: 'recent' })}
          className="montserratFont"
          sx={{
            marginRight: '10px',
            flex: 1,
            borderRadius: '4px',
            backgroundColor: this.state.datePosted === 'recent' ? '#70624D' : '#FFFFFF',
            color: this.state.datePosted === 'recent' ? '#FFFFFF' : '#333',
            borderColor: '#333',
            fontWeight: 500,
            textTransform: 'capitalize',
            '&:hover': {
              backgroundColor: this.state.datePosted === 'recent' ? '#70624D' : '#f1f1f1', 
              borderColor: '#333',
              boxShadow: 'none', 
            },
          }}
        >
          Recent
        </Button>
        <Button
          data-testid="oldPostBtn"
          variant="outlined"
          onClick={() => this.setState({ datePosted: 'older' })}
          className="montserratFont"
          sx={{
            flex: 1,
            borderRadius: '4px',
            backgroundColor: this.state.datePosted === 'older' ? '#70624D' : '#FFFFFF',
            color: this.state.datePosted === 'older' ? '#FFFFFF' : '#333',
            borderColor: '#333',
            fontWeight: 500,
            textTransform: 'capitalize',
            '&:hover': {
              backgroundColor: this.state.datePosted === 'recent' ? '#70624D' : '#f1f1f1', 
              borderColor: '#333',
              boxShadow: 'none', 
            },
          }}
        >
          Old
        </Button>
      </Box>
    </Box>

    <FormControl fullWidth sx={{ marginBottom: '15px' }}>
      <Typography className="montserratFont" sx={{ fontSize: '14px', fontWeight: 400, marginBottom: '8px', color: '#333' }}>Select Color</Typography>
      <Select
        id="demo-simple-select"
        labelId="demo-simple-select-label"
        displayEmpty
        data-testid="colorChoiceTestId"
        value={color}
        onChange={(e) => this.setState({ color: e.target.value })}
        renderValue={(selected) => {
          if (selected === "") {
            return <em style={webStyle.selectPlaceholder}>Select color</em>;
          }
          
          const selectedColor = this.state.selectColorData.find((c: Color) => c.id === selected);
    
          if (selectedColor) {
            return (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box
                sx={{
                  backgroundColor: '#fff',
                  width: '33px',
                  height: '33px',
                  borderRadius: '4px',
                  padding: '4px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  border: '1px solid #000',
                  margin: '0px 15px 0px 0px'
                }}
              >
                <Box
                  sx={{
                    backgroundColor: selectedColor.color_code,
                    width: '31px',
                    height: '31px',
                    borderRadius: '4px',
                  }}
                />
              </Box>
                {selectedColor.name}
              </Box>
            );
          } else {
            return <em style={webStyle.selectPlaceholder}>Select color</em>;
          }
        }}
        sx={{
          backgroundColor: '#F9F9F9',
          borderRadius: '8px',
          height: '56px',
          '& .MuiSelect-select': {
            display: 'flex',
            alignItems: 'center',
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              border: 'none',
            },
            '&.Mui-focused fieldset': {
              border: 'none', 
            },
          },
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none'
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            border: 'none', 
          },
        }}
      >
        <MenuItem 
        value="" 
        sx={{
          fontFamily: 'Montserrat, sans-serif',
          '&.Mui-selected:hover': {
            backgroundColor: '#5b5b5b',
          },
          '&.Mui-selected': {
            color: '#fff',
            backgroundColor: '#80806e',  
          },
          '&:hover': {
            backgroundColor: '#e0e0e0',
          },
          }}>
            <em style={webStyle.selectPlaceholder}>Select color</em>
          </MenuItem>
        {this.state.selectColorData.map((color: any) => (
          <MenuItem 
          key={color.id} 
          value={color.id}  
          sx={{
            fontFamily: 'Montserrat, sans-serif',
            color: 'rgba(60, 62, 73, 1)'
          }}>
            <Box
              sx={{
                backgroundColor: color.color_code,
                width: '16px',
                height: '16px',
                borderRadius: '50%',
                marginRight: '8px',
                fontFamily: `${MONTSERRAT_THIN.fontFamily}, Arial, sans-serif`,
                '&.Mui-selected': {
                  fontFamily: `${MONTSERRAT_THIN.fontFamily}, Arial, sans-serif`,
                }
              }}
            />
            {color.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>

    <FormControl fullWidth>
      <Typography className="montserratFont" sx={{ fontSize: '14px', fontWeight: 400, marginBottom: '8px', color: '#333' }}>Size</Typography>
      <Select
        id="demo-simple-select"
        labelId="demo-simple-select-label"
        displayEmpty
        data-testid="sizeTestId"
        value={size}
        onChange={(e) => this.setState({ size: e.target.value })}
        sx={{
          backgroundColor: '#F9F9F9',
          borderRadius: '8px',
          '& .MuiSelect-select': {
            display: 'flex',
            alignItems: 'center',
          },
          '& .MuiOutlinedInput-root': {
          '& fieldset': {
            border: 'none',
          },
          '&.Mui-focused fieldset': {
            border: 'none', 
          },
        },
        '& .MuiOutlinedInput-notchedOutline': {
          border: 'none'
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
          border: 'none', 
        },
        }}
      >
        <MenuItem 
        value="" 
        sx={{
          fontFamily: `${MONTSERRAT_THIN.fontFamily}, Arial, sans-serif`,
          '&.Mui-selected:hover': {
            backgroundColor: '#5b5b5b',
          },
          '&.Mui-selected': {
            color: '#fff',
            backgroundColor: '#80806e',  
          },
          '&:hover': {
            backgroundColor: '#e0e0e0',
          },
          }}>
            <em style={webStyle.selectPlaceholder}>Select size</em>
          </MenuItem>
        {this.state.sizeData.map((data: any) => 
          <MenuItem 
          value={data.id} 
          sx={{
            fontFamily: `${MONTSERRAT_THIN.fontFamily}, Arial, sans-serif`,
            color: 'rgba(60, 62, 73, 1)'
          }}>
           
            <Box
            sx={{
              width: '16px',
              height: '16px',
              borderRadius: '50%',
              marginRight: '8px',
              fontFamily: `${MONTSERRAT_THIN.fontFamily}, Arial, sans-serif`,
              '&.Mui-selected': {
                fontFamily: `${MONTSERRAT_THIN.fontFamily}, Arial, sans-serif`,
              }
            }}
          >
           {data.name}
          </Box>
          </MenuItem>)}
      </Select>
    </FormControl>

    <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '16px', marginTop: '24px' }}>
      <Button
        variant="outlined"
        data-testid="cancelBtnTestId"
        onClick={() => this.props.handleFilterClose()}
        sx={{
          flex: 1,
          height: '48px',
          borderRadius: '8px',
          color: '#333',
          borderColor: '#333',
          fontWeight: 500,
          textTransform: 'capitalize',
          '&:hover': {
            backgroundColor: '#70624D',
            color: '#FFFFFF',
            border: 'none'
          },
        }}
        className="montserratFont"
      >
        Cancel
      </Button>
      <Button
        variant="contained"
        data-testid="applyTestId"
        onClick={this.handleApplyButton}
        sx={{
          flex: 1,
          height: '48px',
          borderRadius: '8px',
          backgroundColor: '#70624D',
          color: '#FFFFFF',
          fontWeight: 500,
          textTransform: 'capitalize',
          '&:hover': {
            backgroundColor: '#70624D',
            color: '#FFFFFF', 
            border: 'none'
          },
        }}
        className="montserratFont"
      >
        Apply
      </Button>
    </Box>
      </Drawer>
      //Merge Engine End DefaultContainer
    // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  selectPlaceholder: {
    color: '#B0B0B0',
    fontSize: 'inherit',
    display: 'block',
    fontStyle: 'normal',
  },
  drawerPaper: {
    padding: 1,
    width: '375px',
    maxWidth: '500px',
    borderTopRightRadius: '25px',
    borderBottomRightRadius: '25px',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: '#3D3D3D',
  },
  slider: {
    color: '#3D3D3D',
  },
  chipSelected: {
    backgroundColor: '#70624D',
    color: '#FFFFFF',
  },
  chipDefault: {
    backgroundColor: '#FFFFFF',
    color: '#3D3D3D',
    border: '1px solid #3D3D3D',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  applyButton: {
    backgroundColor: '#70624D',
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#5a4f3d',
    },
  },
  cancelButton: {
    borderColor: '#70624D',
    color: '#70624D',
  },
};
// Customizable Area End