export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const profile = require("../assets/profile.png").default
export const editpen = require("../assets/editpen.png").default
export const leftCircle = require("../assets/left_circle.png").default
export const rightCircle = require("../assets/right_circle.png").default
export const penIcon = require("../assets/penIcon.png").default
export const crossIcon=require('../assets/crossIcon.svg').default
export const calenderIcon=require('../assets/button_calendar.png').default
export const cameraIcon=require('../assets/camIcon.png').default






