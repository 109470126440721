Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Analytics";
exports.labelBodyText = "Analytics Body";
exports.btnExampleTitle = "CLICK ME";
exports.tabsNames=["Buying Requests","Products","Orders","Offers","Wish Requests"]
exports.tabData = {
  "Buying Requests": [
    {
      modelNumber: "12345",
      image: "https://via.placeholder.com/60",
      listedDate: "2023-11-01",
      category: "Electronics",
      brand: "Brand A",
      productName: "Product A",
      listedPrice: "$500",
      use: "New",
      requests: 10,
      requestList: [
        { id: "1", date: "2023-11-10", customer: "John Doe", location: "New York", listed_price: "$500", offer_price: "$450" },
        { id: "2", date: "2023-11-11", customer: "Jane Smith", location: "Los Angeles", listed_price: "$500", offer_price: "$400" }
      ]
    },
    {
      modelNumber: "12345",
      listedDate: "2023-11-01",
      image: "https://via.placeholder.com/60",
      category: "Electronics",
      brand: "Brand 1",
      productName: "Product 2",
      listedPrice: "$500",
      requests: 20,
      use: "used",
      requestList: [
        { id: "1", date: "2023-11-10", customer: "John Doe", location: "New York", listed_price: "$500", offer_price: "$450" },
        { id: "2", date: "2023-11-11", customer: "Jane Smith", location: "Los Angeles", listed_price: "$500", offer_price: "$400" }
      ]
    }, {
      modelNumber: "12345",
      use: "New",
      image: "https://via.placeholder.com/60",
      listedDate: "2023-11-01",
      category: "Electronics",
      brand: "Brand A",
      requests: 70,
      listedPrice: "$700",
      productName: "Product 2",
      requestList: [
        { id: "3", date: "2023-11-10", customer: "John Doe", location: "New York", listed_price: "$500", offer_price: "$450" },
        { id: "4", date: "2023-11-11", customer: "Jane Smith", location: "Los Angeles", listed_price: "$500", offer_price: "$400" }
      ]
    }, {
      image: "https://via.placeholder.com/60",
      modelNumber: "12335",
      listedDate: "2023-11-01",
      category: "Electronics",
      productName: "Product 3",
      brand: "Brand CC",
      listedPrice: "$500",
      use: "New",
      requests: 30,
      requestList: [
        { id: "6", date: "2023-11-11", customer: "Jane Smith", location: "Los Angeles", listed_price: "$500", offer_price: "$400" },
        { id: "5", date: "2023-11-10", customer: "John Doe", location: "New York", listed_price: "$500", offer_price: "$450" },
      ]
    }, {
      modelNumber: "21345",
      listedDate: "2023-11-01",
      category: "Electronics",
      brand: "Brand A",
      productName: "Product 4",
      listedPrice: "$500",
      use: "used",
      image: "https://via.placeholder.com/60",
      requests: 80,
      requestList: [
        { id: "8", date: "2023-11-11", customer: "Jane Smith", location: "Los Angeles", listed_price: "$500", offer_price: "$400" },
        { id: "7", date: "2023-11-10", customer: "John Doe", location: "New York", listed_price: "$500", offer_price: "$450" },
      ]
    },
  ],
  "Products": [
    {
      modelNumber: "12345",
      image: "https://via.placeholder.com/50",
      listedDate: "2023-11-01",
      category: "Electronics",
      brand: "Brand A",
      productName: "Product A",
      listedPrice: "$500",
      sold: 5,
      offers: 2,
      orders: 3,
      status: "Available",
    },
  ],
  "Orders": {
    "Pending Orders": [
      { orderId: "ORD1001", date: "2023-11-10", productImage: "https://via.placeholder.com/50", productName: "Product A", customer: "John Doe", location: "New York", listedPrice: "$500", actions: "View" },
      { orderId: "ORD1002", date: "2023-11-11", productImage: "https://via.placeholder.com/50", productName: "Product B", customer: "Jane Smith", location: "California", listedPrice: "$300", actions: "View" },
      { orderId: "ORD1003", date: "2023-11-12", productImage: "https://via.placeholder.com/50", productName: "Product C", customer: "Bob Johnson", location: "Texas", listedPrice: "$700", actions: "View" },
      { orderId: "ORD1004", date: "2023-11-13", productImage: "https://via.placeholder.com/50", productName: "Product D", customer: "Alice Brown", location: "Florida", listedPrice: "$100", actions: "View" },
      { orderId: "ORD1005", date: "2023-11-14", productImage: "https://via.placeholder.com/50", productName: "Product E", customer: "Michael Scott", location: "Pennsylvania", listedPrice: "$50", actions: "View" },
    ],
    "Accepted Orders": [
      { orderId: "ORD2001", date: "2023-11-15", productImage: "https://via.placeholder.com/50", productName: "Product F", customer: "Chris Evans", location: "New Jersey", listedPrice: "$400", actions: "Approved" },
      { orderId: "ORD2002", date: "2023-11-16", productImage: "https://via.placeholder.com/50", productName: "Product G", customer: "Emma Watson", location: "Chicago", listedPrice: "$350", actions: "Shipped" },
      { orderId: "ORD2003", date: "2023-11-17", productImage: "https://via.placeholder.com/50", productName: "Product H", customer: "Brad Pitt", location: "Seattle", listedPrice: "$600", actions: "Preparing" },
      { orderId: "ORD2004", date: "2023-11-18", productImage: "https://via.placeholder.com/50", productName: "Product I", customer: "Scarlett Johansson", location: "Houston", listedPrice: "$450", actions: "Shipped" },
      { orderId: "ORD2005", date: "2023-11-19", productImage: "https://via.placeholder.com/50", productName: "Product J", customer: "Leonardo DiCaprio", location: "Atlanta", listedPrice: "$250", actions: "Shipped" },
    ],
    "Rejected Orders": [
      { orderId: "ORD3001", date: "2023-11-20", productImage: "https://via.placeholder.com/50", productName: "Product K", customer: "Natalie Portman", location: "Phoenix", listedPrice: "$550", actions: "View" },
    ],
  },
  "Offers": {
    "Pending Offers": [
      { offerId: "OFF1001", date: "2023-11-21", productImage: "https://via.placeholder.com/50", productName: "Product L", customer: "Robert Downey Jr.", location: "Boston", listedPrice: "$500", offerPrice: "$450", actions: "View" },
      { offerId: "OFF1001", date: "2023-11-21", productImage: "https://via.placeholder.com/50", productName: "Product L", customer: "Robert Downey Jr.", location: "Boston", listedPrice: "$500", offerPrice: "$450", actions: "View" },
      { offerId: "OFF1001", date: "2023-11-21", productImage: "https://via.placeholder.com/50", productName: "Product L", customer: "Robert Downey Jr.", location: "Boston", listedPrice: "$500", offerPrice: "$450", actions: "View" },
      { offerId: "OFF1001", date: "2023-11-21", productImage: "https://via.placeholder.com/50", productName: "Product L", customer: "Robert Downey Jr.", location: "Boston", listedPrice: "$500", offerPrice: "$450", actions: "View" },
    
    ],
    "Accepted Offers": [
      
      { offerId: "OFF2001", date: "2023-11-22", productImage: "https://via.placeholder.com/50", productName: "Product M", customer: "Chris Hemsworth", location: "Denver", listedPrice: "$400", offerPrice: "$350", actions: "View" },
      { offerId: "OFF2001", date: "2023-11-22", productImage: "https://via.placeholder.com/50", productName: "Product M", customer: "Chris Hemsworth", location: "Denver", listedPrice: "$400", offerPrice: "$350", actions: "View" },
      { offerId: "OFF2001", date: "2023-11-22", productImage: "https://via.placeholder.com/50", productName: "Product M", customer: "Chris Hemsworth", location: "Denver", listedPrice: "$400", offerPrice: "$350", actions: "View" },
      { offerId: "OFF2001", date: "2023-11-22", productImage: "https://via.placeholder.com/50", productName: "Product M", customer: "Chris Hemsworth", location: "Denver", listedPrice: "$400", offerPrice: "$350", actions: "View" },
      { offerId: "OFF2001", date: "2023-11-22", productImage: "https://via.placeholder.com/50", productName: "Product M", customer: "Chris Hemsworth", location: "Denver", listedPrice: "$400", offerPrice: "$350", actions: "View" },
    ],
    "Rejected Offers": [
      { offerId: "OFF3001", date: "2023-11-23", productImage: "https://via.placeholder.com/50", productName: "Product N", customer: "Tom Holland", location: "Miami", listedPrice: "$600", offerPrice: "$550", actions: "View" },
      { offerId: "OFF3001", date: "2023-11-23", productImage: "https://via.placeholder.com/50", productName: "Product N", customer: "Tom Holland", location: "Miami", listedPrice: "$600", offerPrice: "$550", actions: "View" },
      { offerId: "OFF3001", date: "2023-11-23", productImage: "https://via.placeholder.com/50", productName: "Product N", customer: "Tom Holland", location: "Miami", listedPrice: "$600", offerPrice: "$550", actions: "View" },
      { offerId: "OFF3001", date: "2023-11-23", productImage: "https://via.placeholder.com/50", productName: "Product N", customer: "Tom Holland", location: "Miami", listedPrice: "$600", offerPrice: "$550", actions: "View" },
      { offerId: "OFF3001", date: "2023-11-23", productImage: "https://via.placeholder.com/50", productName: "Product N", customer: "Tom Holland", location: "Miami", listedPrice: "$600", offerPrice: "$550", actions: "View" },
      { offerId: "OFF3001", date: "2023-11-23", productImage: "https://via.placeholder.com/50", productName: "Product N", customer: "Tom Holland", location: "Miami", listedPrice: "$600", offerPrice: "$550", actions: "View" },
    ],
  },
  "Wish Requests": {
    "Pending Requests": [
      { requestId: "REQ1001", productImage: "https://via.placeholder.com/50", productName: "Product O", category: "Electronics", requestDate: "2023-11-24", assignDate: "2023-11-25", customer: "Zendaya", location: "Orlando", actions: "View" },
      { requestId: "REQ1001", productImage: "https://via.placeholder.com/50", productName: "Product O", category: "Electronics", requestDate: "2023-11-24", assignDate: "2023-11-25", customer: "Zendaya", location: "Orlando", actions: "View" },
      { requestId: "REQ1001", productImage: "https://via.placeholder.com/50", productName: "Product O", category: "Electronics", requestDate: "2023-11-24", assignDate: "2023-11-25", customer: "Zendaya", location: "Orlando", actions: "View" },
      { requestId: "REQ1001", productImage: "https://via.placeholder.com/50", productName: "Product O", category: "Electronics", requestDate: "2023-11-24", assignDate: "2023-11-25", customer: "Zendaya", location: "Orlando", actions: "View" },
      { requestId: "REQ1001", productImage: "https://via.placeholder.com/50", productName: "Product O", category: "Electronics", requestDate: "2023-11-24", assignDate: "2023-11-25", customer: "Zendaya", location: "Orlando", actions: "View" },
    ],
    "Accepted Requests": [
      { requestId: "REQ2001", productImage: "https://via.placeholder.com/50", productName: "Product P", category: "Appliances", requestDate: "2023-11-26", assignDate: "2023-11-27", customer: "Dwayne Johnson", location: "San Diego", actions: "View" },
      { requestId: "REQ2001", productImage: "https://via.placeholder.com/50", productName: "Product P", category: "Appliances", requestDate: "2023-11-26", assignDate: "2023-11-27", customer: "Dwayne Johnson", location: "San Diego", actions: "View" },
      { requestId: "REQ2001", productImage: "https://via.placeholder.com/50", productName: "Product P", category: "Appliances", requestDate: "2023-11-26", assignDate: "2023-11-27", customer: "Dwayne Johnson", location: "San Diego", actions: "View" },
      { requestId: "REQ2001", productImage: "https://via.placeholder.com/50", productName: "Product P", category: "Appliances", requestDate: "2023-11-26", assignDate: "2023-11-27", customer: "Dwayne Johnson", location: "San Diego", actions: "View" },
    ],
    "Rejected Requests": [
      { requestId: "REQ3001", productImage: "https://via.placeholder.com/50", productName: "Product Q", category: "Furniture", requestDate: "2023-11-28", assignDate: "2023-11-29", customer: "Gal Gadot", location: "Las Vegas", actions: "View" },
      { requestId: "REQ3001", productImage: "https://via.placeholder.com/50", productName: "Product Q", category: "Furniture", requestDate: "2023-11-28", assignDate: "2023-11-29", customer: "Gal Gadot", location: "Las Vegas", actions: "View" },
      { requestId: "REQ3001", productImage: "https://via.placeholder.com/50", productName: "Product Q", category: "Furniture", requestDate: "2023-11-28", assignDate: "2023-11-29", customer: "Gal Gadot", location: "Las Vegas", actions: "View" },
    ],
  },
};
exports.subtabsMapping = {
  "Buying Requests":[],
  Products:["All Products","Hidden Products"],
  Orders: ['Pending Orders', 'Accepted Orders', 'Rejected Orders'],
  Offers: ['Pending Offers', 'Accepted Offers', 'Rejected Offers'],
  'Wish Requests': ['Pending Requests', 'Accepted Requests', 'Rejected Requests'],
};
// Customizable Area End